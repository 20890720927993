import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../store';
import * as ResourcesListStore from '../../store/ResourcesListStore';
import * as CalendarStore from '../../store/CalendarStore';
import { IEntitiesScreenProps } from '../common/EntitiesScreen';
import { IEntitiesScreenView, IHeaderProps } from '../common/EntitiesScreenHeader';
import EntitiesScreenBuilder from '../common/EntitiesScreen';
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import * as Metadata from "../../entities/Metadata";
import { IDeletionResult } from '../../store/services/storeHelper';
import {
    PrimaryButton, DefaultButton, ContextualMenuItemType, IContextualMenuItem, IDialogContentProps, arraysEqual, MessageBar, MessageBarType, Selection, Link
} from 'office-ui-fabric-react';
import { Dictionary, EntityType, mapServerEntityType, Quantization } from "../../entities/common";
import { Reporting, ReportNav, ReportsNav } from "../utils/reporting";
import ListSubView, { getPngExportConfigSelectors } from "../views/list/ListSubView";
import RemoveDialog from '../common/RemoveDialog';
import EditListSubView from '../views/list/EditListSubView';
import ImportPanel, { supported } from "../import/resource/ImportPanel";
import MergePanel from "./MergePanel";
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import { toDictionaryById, notUndefined } from "../utils/common";
import Spinner from "../common/Spinner";
import ResourceCreation from './ResourceCreation';
import { ResourceFilterValue, FilterHelper } from '../../store/resource/filters';
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from "../../store/layouts";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from '../../store/views';
import { IDetailsProps, IListProps } from '../common/extensibleEntity/EntityDetailsList';
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import { Integrations, Subscription, PPMFeatures, RoutesAvailability, RouteAvailabilitySettings, ToRouteAvailabilitySettings } from '../../store/Tenant';
import { LayoutService } from '../utils/LayoutService';
import { ITimelineProps } from '../common/extensibleEntity/EntityTimelineList';
import { ScaleRenderMode } from '../common/timeline/TimelineBody';
import UtilizationTooltipContent from './usage/UtilizationTooltipContent';
import UtilizationList from './UtilizationList';
import { defaultTimeframe, SCALE_MULTIPLIER_PER_TIMETYPE_COLUMN, ViewType } from './usage/ResourceUsageGrid';
import { IRow } from '../common/timeline/TimelineList';
import { IScaleTimelineSegment } from '../common/timeline/TimelineSegment';
import MenuWithReports from '../reporting/MenuWithReports';
import PngExporter, { PngExportConfig } from '../common/PngExporter';
import { PngExportControlDetails } from '../../store/PngExporterStore';
import { TimeType, timeTypes, TimeTypeSelector, ResourceUsageCell } from './usage/ResourceUsageCell';
import { SortService } from '../../services/SortService';
import { Sorter } from '../utils/HierarchyManager';
import { buildExportToCsvMenuItem } from '../common/headerMenuItemBuilders';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import ReportedTimeList from './ReportedTimeList';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';

type ActionProps = {
    resourcesActions: typeof ResourcesListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
    calendarActions: typeof CalendarStore.actionCreators;
}

type StateProps = {
    views?: ViewsStore.IViewsState;
    resources: ResourcesListStore.Resource[];
    resourcesMap: Dictionary<ResourcesListStore.Resource>;
    layouts: LayoutsStore.LayoutsState;
    resourceFields: Metadata.Field[];
    resourceFakeFields: Metadata.Field[];
    resourceFilters: Metadata.IFilter<ResourceFilterValue>[];
    resourceReports: ReportNav[];
    resourcesReports: ReportsNav;
    activeFilter?: Metadata.IFilter<ResourceFilterValue>;
    autoFilterId: string;
    deletionResult: IDeletionResult[] | undefined;
    user: UserState;
    subscription: Subscription,
    isImportDialogOpen: boolean;
    isLoading: boolean;
    isListLoading: boolean;
    integrations: Integrations;
    pngExportDetails?: PngExportControlDetails;
    resourcePlanningLevel: EntityType;
    routeAvailabilitySettings: RouteAvailabilitySettings
}
export type ResourcesListProps = StateProps & ActionProps & RouteComponentProps<{ type?: string, subViewId?: string }>;

type State = {
    isCreate: boolean;
    isImport: boolean;
    isMerge: boolean;
    isListViewEdit: boolean;
    isTimelineViewEdit: boolean;
    resourcesToRemove: ResourcesListStore.Resource[];
    layoutToApply?: Metadata.Layout;
    canManageConfiguration: boolean;
    canManage: boolean;
    selectedItems: ResourcesListStore.Resource[];
    quantization: Quantization;
    entityFilterHelper: Metadata.IEntityFilterHelper<ResourcesListStore.Resource>;
    timelineViewType: ViewType;
    timeType: TimeType[];
};

const EntitiesFilter = GenericEntitiesFilter<ResourcesListStore.Resource>();
const EntitiesScreen = EntitiesScreenBuilder<ResourcesListStore.Resource>();
const minCountMerge = 2;

class ResourcesList extends React.Component<ResourcesListProps, State> {
    private _selection: Selection;

    constructor(props: ResourcesListProps) {
        super(props);

        this.state = {
            isCreate: false,
            isImport: false,
            isMerge: false,
            isListViewEdit: false,
            isTimelineViewEdit: false,
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage),
            canManage: contains(props.user.permissions.common, CommonOperations.ResourceManage),
            selectedItems: [],
            resourcesToRemove: [],
            quantization: Quantization.months,
            entityFilterHelper: this._buildEntityFilterHelper(props),
            timelineViewType: ViewType.Hours,
            timeType: ['plan']
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItems: this._selection.getSelection() as ResourcesListStore.Resource[] });
            }
        });
    }

    componentWillMount() {
        this.props.resourcesActions.loadResources(true);
        this.props.calendarActions.load();
    }

    componentWillReceiveProps(nextProps: ResourcesListProps) {
        if (!arraysEqual(this.props.resourceFields, nextProps.resourceFields) || !arraysEqual(this.props.resources, nextProps.resources)) {
            this.setState({ entityFilterHelper: this._buildEntityFilterHelper(nextProps) });
        }
    }

    private _buildEntityFilterHelper(props: ResourcesListProps) {
        return new FilterHelper({
            fields: props.resourceFields,
            resources: props.resources,
            layouts: props.layouts.allIds.map(_ => props.layouts.byId[_])
        });
    }

    private _viewChanged = (view: IEntitiesScreenView<ResourcesListStore.Resource>) => {
        this._clearSelection();
        this.props.viewsActions.setActiveView(view.url);
    }

    render() {
        const { deletionResult } = this.props;
        const notDeleted = deletionResult?.filter(_ => !_.isDeleted);
        if (!this.props.isImportDialogOpen && (this.props.isLoading || this.props.isListLoading)) {
            return <Spinner />;
        }
        const { resourcesToRemove } = this.state;
        const integrations = this.props.integrations.getAvailable(supported);

        const unassign = notDeleted && <>
            We recommend using the 'Merge Resources' option to replace {notDeleted.length > 1 ? "these resources" : "this resource"} and merge their existing data into another resource selected as a replacement.
            Alternatively, you can unassign or remove {notDeleted.length > 1 ? "these resources" : "this resource"} from the fields first.
            <Link href="https://help.ppm.express/111183-resource-management/resource-merging?from_search=149628615" target="_blank">
                Learn more
            </Link>
        </>

        return <>
            {this.props.resources.length === 0
                ? <EmptyEntitiesScreen
                    className="resource"
                    title="resources"
                    description="Add people to your organization team for effective status reporting and management accessible for all your team members">
                    <PrimaryButton disabled={!this.state.canManage} text="Create Resource" onClick={() => this.setState({ isCreate: true })} />
                    {!!integrations.length && <DefaultButton disabled={!this.state.canManage} text="Import Resources" onClick={() => this.setState({ isImport: true })} />}
                </EmptyEntitiesScreen>
                : this.props.match.params.type === 'plan'
                    ? <UtilizationList resourceIds={this.getBulkEditEntities().map(_ => _.id)} resourcePlanningLevel={this.props.resourcePlanningLevel} />
                    : this.props.match.params.type === 'reported-time'
                        ? <ReportedTimeList resourceIds={this.getBulkEditEntities().map(_ => _.id)} />
                        : <PngExporter details={this.props.pngExportDetails} getConfig={this._getPngExportConfig}>
                            <EntitiesScreen {...this._buildEntitiesScreenProps()} />
                        </PngExporter>}
            {this.state.isCreate && <ResourceCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete={true} />}
            {this.state.isImport && <ImportPanel integrations={integrations} onDismiss={this._onImportDismiss} />}
            {this.state.isMerge && <MergePanel
                selected={this.state.selectedItems}
                onDismiss={() => this.setState({ isMerge: false })}
                onComplete={() => this._clearSelection()}
            />}
            {!!this.state.resourcesToRemove.length && <RemoveDialog
                onClose={() => this.setState({ resourcesToRemove: [] })}
                onComplete={() => {
                    this._clearSelection();
                    this.props.resourcesActions.removeResources(resourcesToRemove.map(_ => _.id));
                }}
                dialogContentProps={this._getRemoveDialogContent(resourcesToRemove)}
                confirmButtonProps={{ text: "Delete" }} />}
            {deletionResult && notDeleted && <RemoveDialog
                onClose={() => { this.props.resourcesActions.dismissDeletionResult() }}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && notDeleted.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to delete the following {notDeleted.length > 1 ? "resources as they are" : "resource as the resource is"} in use:
                    <ul>
                        {notDeleted.map(_ => <li key={_.id}>{_.message}</li>)}
                    </ul>
                </MessageBar>}
                {notDeleted.length > 0 && <MessageBar messageBarType={MessageBarType.info} isMultiline>
                    {unassign}
                </MessageBar>}
            </RemoveDialog>}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.resourcesActions.applyLayoutMany(this.state.selectedItems.map(_ => _.id), this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Resource}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItems.length}
            />}
        </>;
    }

    private _buildEntitiesScreenProps(): IEntitiesScreenProps<ResourcesListStore.Resource> {
        return {
            title: "resources",
            canManageConfiguration: this.state.canManageConfiguration,
            activeViewType: this.props.views!.activeViewType,
            viewChanged: this._viewChanged,
            fields: this.props.resourceFields,
            fakeFields: this.props.resourceFakeFields,
            defaultBulkEditColumns: ResourcesListStore.DEFAULT_BULK_EDIT_COLUMNS,
            getBulkEditEntities: this.getBulkEditEntities,
            entities: this.applyFilter(this.props.resources),
            entitiesIsUpdating: this.props.layouts.isApplyingLayout,
            actions: {
                bulkComponent: {
                    bulkUpdate: this.gridBulkUpdate
                },
                importFromFile: this.props.resourcesActions.importEntitiesFromFile
            },
            views: this._getViews(),
            filter: {
                activeFilter: this.props.activeFilter,
                autoFilterId: this.props.autoFilterId,
                getAttributeValue: this.getAttributeValue,
                onFilterRender: this._renderFilter
            },
            headerProps: this.getHeaderProps(),
            router: {
                history: this.props.history,
                match: this.props.match,
                location: this.props.location
            },
            baseUrl: "/resources",
            canEdit: this.state.canManage,
            selectedItemIds: this.state.selectedItems.map(_ => _.id),
            getPngExportConfig: this._getPngExportConfig
        }
    }

    private _onImportDismiss = () => {
        this.setState({ isImport: false });
    }

    private _getRemoveDialogContent(toRemove: ResourcesListStore.Resource[]) {
        toRemove = toRemove.filter(_ => _.isEditable);
        if (toRemove.length === 1) {
            return {
                title: "Delete resource",
                subText: `Are you sure you want to delete resource "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete resources",
            subText: toRemove.length
                ? `Are you sure you want to delete selected resources (${toRemove.length} resources)?`
                : undefined
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1 && !deletionResult[0].isDeleted) {
            return {
                title: "Unable to delete resource",
                subText: deletionResult[0].message
            };
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return deleted.length === 1
            ? {
                title: "Resource deletion is complete",
                subText: `Resource "${deleted[0].name}" was deleted successfully.`
            }
            : deleted.length
                ? {
                    title: "Resources deletion is complete",
                    subText: `Selected resources (${deleted.length} resources) were deleted successfully.`
                }
                : {
                    title: "Resources deletion failed"
                };
    }

    private getBulkEditEntities = (): ResourcesListStore.Resource[] =>
        this.state.selectedItems.length
            ? this.state.selectedItems
            : this.applyFilter(this.props.resources);

    private getHeaderProps = (): IHeaderProps => {
        const { resourcesReports, history } = this.props;

        const reportItems = resourcesReports.packs.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => Reporting.openReport(history, _)
            }));

        const resourceReportItems = resourcesReports.subPacks.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => Reporting.openResourcesReport(history, _, this.getBulkEditEntities())
            }));

        return {
            entityType: EntityType.Resource,
            createEntityTypes: [EntityType.Portfolio, EntityType.Program, EntityType.Project, EntityType.Roadmap, EntityType.Resource, EntityType.PrivateProject],
            importEntityTypes: [EntityType.Project, EntityType.Resource],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(resourceReportItems, reportItems)
            },
            allowExportToFile: true,
            allowImportFromFile: Subscription.contains(this.props.subscription, PPMFeatures.ResourceManagement),
        };
    }

    private applyFilter = (_: ResourcesListStore.Resource[]) => {
        return _.filter(this._isItemVisible);
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => (
        <EntitiesFilter
            canManageConfiguration={this.state.canManageConfiguration}
            activeFilter={this.props.activeFilter}
            onActiveFilterChanged={this.props.filtersActions.setActiveFilter}
            onFilterChanged={this.props.filtersActions.updateFilter}
            isFilterPanelOpen={isFilterPanelOpen}
            toggleFilterPanel={toggleFilterPanel}
            entityType={EntityType.Resource}
            entityFilterHelper={this.state.entityFilterHelper}
        />
    );

    private _isItemVisible = (item: ResourcesListStore.Resource): boolean => {
        if (this.props.activeFilter) {
            const filterValue = this.props.activeFilter.value;

            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(this.props.resourceFields);

            for (const type in filterValue) {
                const validateItem = this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type));
                if (!validateItem) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof ResourceFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderMenu = (entity: ResourcesListStore.Resource) => {
        let commands: IContextualMenuItem[] = [];

        if (this.state.canManage) {
            commands = commands.concat([
                {
                    key: 'divider_1',
                    itemType: ContextualMenuItemType.Divider
                },
                {
                    key: 'pfedit',
                    name: 'Edit',
                    iconProps: { iconName: "Edit" },
                    onClick: () => this.props.history.push(this._getResourceLink(entity))
                },
                entity.attributes.Status === ResourcesListStore.ResourceStatus.Active
                    ? {
                        key: 'deactivate',
                        name: 'Deactivate',
                        iconProps: { iconName: 'Blocked2' },
                        onClick: () => { this.props.resourcesActions.updateStatuses([entity.id], ResourcesListStore.ResourceStatus.Inactive) }
                    }
                    : {
                        key: 'activate',
                        name: 'Activate',
                        iconProps: { iconName: 'AddTo' },
                        onClick: () => { this.props.resourcesActions.updateStatuses([entity.id], ResourcesListStore.ResourceStatus.Active) }
                    },
                {
                    key: 'pfDelete',
                    name: 'Delete',
                    iconProps: { iconName: "Delete", style: { color: 'red' } },
                    style: { color: 'red' },
                    onClick: () => this.setState({ resourcesToRemove: [entity] })
                }
            ]);
        }

        return <MenuWithReports
            commands={commands}
            item={entity}
            entityType={EntityType.Resource}
            reports={this.props.resourceReports}
            onClick={_ => Reporting.openResourceReport(this.props.history, _, entity)} />;
    }

    private _getResourceLink = (entity: ResourcesListStore.Resource) => {
        return `/resource/${entity.id}`;
    }

    private getListView = (): IEntitiesScreenView<ResourcesListStore.Resource> => {
        const { list } = this.props.views!;
        const fields = this.props.views!.list.fakeFields.concat(this.props.resourceFields);
        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: 'list',
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/resources/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            render: (key: string, activeSubView: Metadata.IListSubView, entities: ResourcesListStore.Resource[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress
                };
                return [
                    <ListSubView
                        key="list-view"
                        type="Details"
                        entities={entities}
                        selection={this._selection}
                        entityType={EntityType.Resource}
                        fields={fields}
                        isFieldFake={this._buildIsFieldFake()}
                        sort={list.sortBy}
                        sorter={this._sorter}
                        onSortChange={this.props.viewsActions.changeListViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                        onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                        scaleMultiplier={this._getScaleMultiplier()} />,
                    this.state.isListViewEdit ? <EditListSubView
                        key="create-list-view"
                        subView={activeSubView}
                        entityType={EntityType.Resource}
                        selectedByDefault={this.props.views!.list.selectedByDefault}
                        fields={fields}
                        onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                        onSave={() => {
                            this.props.viewsActions.saveListSubView(activeSubView, 'resources');
                            this.setState({ isListViewEdit: false });
                        }}
                        onCopy={() => this._onCopyListSubView(activeSubView)}
                        onDismiss={() => this.setState({ isListViewEdit: false })}
                    /> : <span key="no-edit"></span>
                ];
            },
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/resources/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/resources/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'resources', undefined, view.id);
    }

    private _clearSelection = () => {
        this._selection.setAllSelected(false);
    }

    private _buildSelectedModeMenuItems = (activeSubView: Metadata.ISubView): IContextualMenuItem[] | undefined => {
        const { resourcesActions, notificationsActions, subscription, user, routeAvailabilitySettings } = this.props;
        const { selectedItems, canManage } = this.state;
        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });
        const selectedCount = selectedItems.length;
        const selectedActiveCount = this.getSelected(ResourcesListStore.ResourceStatus.Active).length;
        const entitiesScreenProps = this._buildEntitiesScreenProps();

        return [
            {
                key: "bulk-edit",
                text: "Bulk edit",
                iconProps: { iconName: "TripleColumnEdit" },
                disabled: !canManage,
                onClick: () => this.props.history.push(`/resources/bulk`),
            },
            RoutesAvailability.ResourcePlan(subscription, user) ? {
                key: "planning-and-utilization",
                text: "Planning and Utilization",
                iconProps: { iconName: "PPMXSectionResourcePlan" },
                disabled: !canManage,
                onClick: () => this.props.history.push(`/resources/plan`),
            } : undefined,
            RoutesAvailability.ReportedTime(subscription, user, routeAvailabilitySettings) ? {
                key: "reported-time",
                text: "Reported Time",
                iconProps: { iconName: "Clock" },
                disabled: !canManage,
                onClick: () => this.props.history.push(`/resources/reported-time`),
            } : undefined,
            buildExportToCsvMenuItem({
                views: entitiesScreenProps.views,
                fields: entitiesScreenProps.fields,
                fakeFields: entitiesScreenProps.fakeFields,
                selectedItemIds: selectedItems.map(_ => _.id),
                entityType: EntityType.Resource,
                activeSubView: activeSubView,
                selection: this._selection,
                onExportEntitiesToFile: resourcesActions.exportEntitiesToFile,
            }),
            selectedActiveCount > 0 ? {
                key: "deactivate",
                text: "Deactivate",
                iconProps: { iconName: "Blocked2" },
                disabled: !canManage,
                onClick: () => resourcesActions.updateStatuses(
                    this.getSelected(ResourcesListStore.ResourceStatus.Active).map(_ => _.id),
                    ResourcesListStore.ResourceStatus.Inactive
                ),
            } : undefined,
            selectedCount !== selectedActiveCount ? {
                key: "activate",
                text: "Activate",
                iconProps: { iconName: "AddTo" },
                onClick: () => {
                    resourcesActions.updateStatuses(
                        this.getSelected(ResourcesListStore.ResourceStatus.Inactive).map(_ => _.id),
                        ResourcesListStore.ResourceStatus.Active
                    );
                },
            } : undefined,
            selectedItems.length >= minCountMerge && canManage ? {
                key: "merge",
                text: "Merge",
                iconProps: { iconName: "PeopleRepeat" },
                onClick: () => this.setState({ isMerge: true }),
            } : undefined,
            {
                ...layoutMenuItem,
                disabled: !canManage,
            },
            {
                key: "delete",
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Resource),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                disabled: !canManage,
                onClick: () => this.setState({ resourcesToRemove: selectedItems }),
            }
        ].filter(notUndefined);
    };

    private _setTimelineViewType = (timelineViewType: ViewType) => this.setState({ timelineViewType });

    private getTimelineView(): IEntitiesScreenView<ResourcesListStore.Resource> {
        const { timeline } = this.props.views!;
        const { isTimelineViewEdit } = this.state;
        const byId = timeline.subViews.byId;
        const fields = this.props.views!.list.fakeFields.concat(this.props.resourceFields);
        return {
            icon: 'PPMXTimelineView',
            url: 'timeline',
            subTypes: [{
                title: 'Hours View', iconName: 'HourGlass', onClick: () => this._setTimelineViewType(ViewType.Hours),
                isActive: () => this.state.timelineViewType === ViewType.Hours
            }, {
                title: 'Percent View', iconName: 'CalculatorPercentage', onClick: () => this._setTimelineViewType(ViewType.Percent),
                isActive: () => this.state.timelineViewType === ViewType.Percent
            }, {
                title: 'FTE View', iconName: 'UserGauge', onClick: () => this._setTimelineViewType(ViewType.FTE),
                isActive: () => this.state.timelineViewType === ViewType.FTE
            }, {
                title: 'Chart View', iconName: 'Diagnostic', onClick: () => this._setTimelineViewType(ViewType.Chart),
                isActive: () => this.state.timelineViewType === ViewType.Chart
            }],
            subViews: timeline.subViews.allIds.map(_ => byId[_]),
            activeSubViewId: timeline.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setTimelineActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addTimelineSubView(subView);
                this.props.history.push(`/resources/timeline/${subView.id}`);
                this.setState({ isTimelineViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (timeline.activeSubViewId !== id) {
                    this.props.history.push(`/resources/timeline/${id}`)
                }
                this.setState({ isTimelineViewEdit: true });
            },
            onCopySubViewClick: this._onCopyTimelineSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeTimelineSubView,
            render: (key: string, activeSubView: Metadata.ITimelineSubView, entities: ResourcesListStore.Resource[]) => {
                const listProps: Partial<IListProps> & ITimelineProps = {
                    buildRow: (_: ResourcesListStore.Resource) => ({
                        key: _.id,
                        entity: _,
                        segments: [],
                        markers: []
                    }),
                    renderSegmentContent: (row: IRow, segment: IScaleTimelineSegment) => {
                        return <ResourceUsageCell
                            viewType={this.state.timelineViewType}
                            timeType={this.state.timeType}
                            resource={row.entity as ResourcesListStore.Resource}
                            entityType={this.props.resourcePlanningLevel}
                            quantization={this.state.quantization}
                            prevStartDate={segment.prevStartDate}
                            startDate={segment.startDate}
                            finishDate={segment.finishDate} />;
                    },
                    renderSegmentTooltipContent: (row, segment) =>
                        <UtilizationTooltipContent resource={row.entity as ResourcesListStore.Resource}
                            showPerEntityWork
                            entityType={this.props.resourcePlanningLevel}
                            startDate={segment.startDate}
                            finishDate={segment.finishDate} />,
                    onItemMenuRender: this._renderMenu,
                    scaleRenderMode: ScaleRenderMode.Cell,
                    initialTimeframe: defaultTimeframe,
                    userQuantization: timeline.quantization,
                    userTimeframe: timeline.timeframe,
                    onScaleChange: (s, q, t, o) => {
                        o && this.props.viewsActions.setTimelineScale(o);
                        this.setState({ quantization: q });
                    },
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress
                };
                return <>
                    <ListSubView
                        key="timeline-view"
                        type="Timeline"
                        entities={entities}
                        entityType={EntityType.Resource}
                        selection={this._selection}
                        fields={fields}
                        isFieldFake={this._buildIsFieldFake()}
                        sort={timeline.sortBy}
                        sorter={this._sorter}
                        onSortChange={this.props.viewsActions.changeTimelineViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                        onColumnResized={(id, w) => this.props.viewsActions.onTimelineColumnResized(activeSubView.id, id, w)}
                        scaleMultiplier={this._getScaleMultiplier()}
                    />
                    {
                        isTimelineViewEdit && <EditListSubView
                            key="create-timeline-view"
                            subView={activeSubView}
                            entityType={EntityType.Resource}
                            selectedByDefault={timeline.selectedByDefault}
                            fields={fields}
                            onChange={changes => {
                                this.props.viewsActions.updateTimelineSubView(activeSubView.id, changes);
                            }}
                            onSave={() => {
                                this.props.viewsActions.saveTimelineSubView(activeSubView, 'resources');
                                this.setState({ isTimelineViewEdit: false })
                            }}
                            onCopy={() => this._onCopyTimelineSubView(activeSubView)}
                            onDismiss={() => this.setState({ isTimelineViewEdit: false })}
                        />
                    }
                </>
            },
            headerSideItems: [
                {
                    key: 'time-type-selector',
                    onRender: () => <TimeTypeSelector
                        timeType={this.state.timeType}
                        availableTimeTypes={['plan', 'actual', 'availability', 'capacity']}
                        onTimeTypeChange={(timeType, checked) => this.setState({
                            timeType: timeTypes
                                .map(_ => _.type)
                                .filter(_ => _ === timeType ? checked : this.state.timeType.includes(_))
                        })} />
                }
            ]
        }
    }

    private _onCopyTimelineSubView = (view: Metadata.ITimelineSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/resources/timeline/${subView.id}`);
        this.setState({ isTimelineViewEdit: true });
        this.props.viewsActions.saveTimelineSubView(subView, 'resources', undefined, view.id);
    }

    private gridBulkUpdate = (updates: Dictionary<any>) => {
        this.props.resourcesActions.bulkUpdate(updates);
    }

    private getSelected(status: ResourcesListStore.ResourceStatus) {
        return this.state.selectedItems.map(_ => this.props.resourcesMap[_.id]).filter(_ => _.attributes.Status === status);
    }

    private _sorter: Sorter<ResourcesListStore.Resource> = (orderBy) => {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.resourceFields);
        const isFieldFake = this._buildIsFieldFake();
        const extractor = (item: ResourcesListStore.Resource, field: Metadata.Field) => {
            if (field.name === LayoutsStore._layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, isFieldFake)
        }

        const fieldsMap = Metadata.toMap(fields, isFieldFake);
        return (a, b) => SortService.getComparer(fieldsMap, orderBy, isFieldFake, extractor)(a, b);
    };

    private _buildIsFieldFake = (): (_: Metadata.Field) => boolean => {
        const { list } = this.props.views!;
        const fakeMap = toDictionaryById(list.fakeFields);
        return (_: Metadata.Field) => !!fakeMap[_.id];
    }

    private _getViews = () =>
        [
            this.getListView(),
            Subscription.contains(this.props.subscription, PPMFeatures.ResourceManagement) ? this.getTimelineView() : undefined
        ].filter(notUndefined);

    private _getActiveViewType = () => {
        const views = this._getViews();
        const { activeViewType } = this.props.views!;
        return activeViewType && views.find(_ => _.url === activeViewType)
            ? activeViewType.toLowerCase()
            : views[0].url.toLowerCase();
    }

    private _getPngExportConfig = (): PngExportConfig => {
        const av = this._getActiveViewType();
        const activeView: Metadata.ViewTypes | undefined = av ? Metadata.ViewTypes[av] : undefined;
        return {
            ...getPngExportConfigSelectors(activeView),
            name: "Resources",
            controlId: ResourcesList.name,
            activeView,
            rowsCount: this.applyFilter(this.props.resources).length
        };
    }

    private _getScaleMultiplier = (): number => {
        return this.state.timeType.length + SCALE_MULTIPLIER_PER_TIMETYPE_COLUMN;
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps {
    const fields = state.fields[EntityType.Resource];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Resource);
    const autoFilterId = filters.all.find(_ => _.isBuiltIn && _.name.startsWith('All'))?.id ?? filters.all[0].id; //not getAutoFilterId, because it returns Active, not All
    return {
        resources: state.resources.allIds.map(_ => state.resources.byId[_]),
        resourcesMap: state.resources.byId,
        layouts: state.layouts[EntityType.Resource],
        resourceFields: fields.allIds.map(_ => fields.byId[_]),
        resourceFakeFields: state.views[EntityType.Resource].list.fakeFields,
        resourceFilters: filters.all,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        resourceReports: state.tenant.reporting.resourceReports.subPacks,
        resourcesReports: state.tenant.reporting.resourcesReports,
        views: state.views[EntityType.Resource],
        deletionResult: state.resources.deletionResult,
        user: state.user,
        isImportDialogOpen: state.import.resources.isDialogOpen,
        isLoading: state.resources.isLoading,
        isListLoading: state.resources.isListLoading,
        integrations: new Integrations(state.tenant.subscription.integrations),
        subscription: state.tenant.subscription,
        pngExportDetails: state.pngExporter.controls[ResourcesList.name],
        resourcePlanningLevel: mapServerEntityType[state.tenant.resourcePlanningSettings?.resourcePlanningLevel!]!,
        routeAvailabilitySettings: ToRouteAvailabilitySettings(state.tenant)
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        resourcesActions: bindActionCreators(ResourcesListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Resource), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Resource), dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ResourcesList);
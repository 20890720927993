import * as React from 'react';
import { IContextualMenuItem, Link } from 'office-ui-fabric-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import {
    KeyDate, IKeyDateAttrs, KeyDateTypeMap, KeyDateType, isLinkedSubentity,
    ISubentity, ISubentitySectionActions,
} from "../../../../entities/Subentities";
import { default as SubentitiesList, ISubentitiesListSettings, SubListControlSettings } from "../../../../components/common/SubentitiesList";
import { ITimelineProps } from '../../extensibleEntity/EntityTimelineList';
import { ProjectInfo } from '../../../../store/ProjectsListStore';
import { Portfolio } from '../../../../store/PortfoliosListStore';
import KeyDateTooltipContent from '../../timeline/KeyDateTooltipContent';
import { namesof, nameof } from '../../../../store/services/metadataService';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { EntityType, IPatch, TimelineControlSettings, ppmxTaskConnectionId } from '../../../../entities/common';
import { Field, KeyDatePrefilterKeys, PreFilterOption } from '../../../../entities/Metadata';
import { notUndefined, toDate } from '../../../../components/utils/common';
import { connect } from 'react-redux';
import { IHeader } from '../../ItemCreation';
import KeyDateSetBeselineConfirmation from '../../../keyDate/KeyDateSetBeselineConfirmation';
import { IStyleSettings } from '../../timeline/TimelineList';
import * as KeyDatesTimeline from '../../../keyDate/timeline';
import { BaselineFakeFields } from '../../../views/list/columns/keyDate/Baseline';
import KeyDateBaseline, { KeyDateVariance } from '../../../keyDate/KeyDateBaseline';
import { IWithStyleSettings, PreferenceUpdates, Views } from '../../../../store/services/viewSaver';
import { mergeDefault } from '../../../../store/utils';
import { ViewTypeViews, viewTypeSettingsBuilder } from '../../ViewTypeSelect';
import { ApplicationState } from '../../../../store';
import { ViewService } from '../../../../services/ViewService';
import { KeyDateVarianceCalculator } from '../../../keyDate/KeyDateVarianceCalculator';
import { CalendarDataSet } from '../../../../store/CalendarStore';

export interface IDataContext {
    readOnlyFields?: string[],
    editableNativeFieldsForLinkedEntities?: string[],
    fakeFields?: Field[],
    theOnlyEditableFieldsForLinkedEntities?: string[]
}
type ControlSettings = SubListControlSettings & {
    timeline: TimelineControlSettings & IWithStyleSettings
}

export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>
type OwnProps = ISectionUIControlProps<IActions, ISubentitiesListSettings, ProjectInfo | Portfolio, {}, ControlSettings, IDataContext>;
type StateProps = {
    fields: Field[];
    calendar: CalendarDataSet;
};

type Props = OwnProps & StateProps & RouteComponentProps<{}>;

export type IActions = ISubentitySectionActions<KeyDate> & {
    bulkUpdate: (items: IPatch<KeyDate>[]) => void;
    setBaseline: (entities: string[]) => void;
}

const SubList = SubentitiesList<KeyDate>();

const keyDateStyleSettings: IStyleSettings = {
    showBaseline: { label: 'Show Baseline' }
}

type State = {
    preFilterItems: PreFilterOption<KeyDate>[];
    setBaselineSelectedEntities?: KeyDate[];
}

function buildPrefilterItems(): PreFilterOption<KeyDate>[] {
    const today = {
        begin: new Date().getBeginOfDay(),
        end: new Date().getEndOfDay()
    };

    return [{
        key: KeyDatePrefilterKeys.late, name: 'Late Key Dates', predicate: _ =>
            !_.attributes.IsComplete
            && !!_.attributes.Date && toDate(_.attributes.Date)! < today.begin
    },
        {
            key: KeyDatePrefilterKeys.slipped, name: 'Slipped Key Dates', predicate: _ =>
                !_.attributes.IsComplete && !!_.baseline && !!_.attributes.Date && _.baseline.date !== _.attributes.Date            
    }];
}

class KeyDatesControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            preFilterItems: buildPrefilterItems()
        };
    }

    public render() {
        const { entity, sectionId, id, settings, actions, datacontext, fields, controlSettings, onSaveSettings, isFullScreen } = this.props;
        const timelineProps: ITimelineProps = {
            buildRow: (keyDate: KeyDate) => KeyDatesTimeline.buildTimelineItem(keyDate, fields),
            renderMarkerTooltipContent: (row, marker) =>
                <KeyDateTooltipContent
                    keyDate={row.entity as KeyDate}
                    createdFromType={EntityType.KeyDate}
                    onClick={() => this._openKeyDate(row.entity as KeyDate)} />,
            styling: {
                settings: keyDateStyleSettings,
                values: this.props.controlSettings.timeline.styleSettingValues,
                onChange: (key, value) => onSaveSettings?.(PreferenceUpdates.viewType(Views.Timeline, PreferenceUpdates.style(key, value)))
            },
        };

        const fakeFields = [...BaselineFakeFields, ...(datacontext.fakeFields ?? [])];

        return <>
            <SubList
                isFullScreen={isFullScreen}
                sectionId={sectionId}
                controlId={id}
                parentEntityId={entity.id}
                parentEntityType={this.props.entityType}
                timelineProps={timelineProps}
                readOnly={!entity.isEditable}
                canConfigure={this.props.entity.canConfigure}
                isArchived={this.props.entity.isArchived}
                isViewSelected={this.props.isViewSelected}
                entityPanelHeader={this._getTextForEditKeyDate}
                subentityInfo={{
                    subentityType: EntityType.KeyDate,
                    subentityTypeLabel: KeyDateTypeMap[KeyDateType.KeyDate],
                    subentityCollectionName: nameof("keyDates", this.props.entity)
                }}
                entities={entity ? entity.keyDates : []}
                settings={settings}
                actions={actions}
                fakeFields={fakeFields}
                mandatoryEditFields={namesof<IKeyDateAttrs>(['Type', 'Date', 'ShowOnTimeline', 'Status', 'IsComplete'])}
                readOnlyFields={this._getReadOnlyFields}
                editableNativeFieldsForLinkedEntities={this._getEditableNativeFieldsForLinkedEntities}
                theOnlyEditableFieldsForLinkedEntities={datacontext.theOnlyEditableFieldsForLinkedEntities}
                emptyScreen={{
                    iconName: 'KeyDateSectionIcon',
                    title: `${this.props.sectionLabel} section is empty`,
                    description: 'Key Dates have not been defined yet'
                }}
                externalCommands={{
                    selectionModeCommands: this._buildSelectionModeCommands
                }}
                renderImport={actions.renderImport}
                getDeletionMessage={(selectedItems: KeyDate[]) => {
                    if (selectedItems.length === 1) {
                        const keydate = selectedItems[0];
                        return `Are you sure you want to delete ${KeyDateTypeMap[keydate.attributes.Type]} "${keydate.attributes.Name}"?`;
                    }
                    return `Are you sure you want to delete ${selectedItems.length} ${KeyDateTypeMap[KeyDateType.KeyDate]}s?`;
                }}
                commandsConfig={{
                    aiGeneration: { hidden: true }
                }}
                allowImportedManage
                useFilters
                preFilterItems={this.state.preFilterItems}
                entityPanelProps={{
                    extraTabs:
                        [{
                            key: 'baseline',
                            label: 'Baseline',
                            renderHeaderSecondaryText: () => `View the key date baseline, its current state and variance value`,
                            renderBody: (updatedKeyDate: KeyDate) => <KeyDateBaseline keyDate={updatedKeyDate} variance={this._calculateVariance(updatedKeyDate)} />
                        }]
                }}
                controlSettings={controlSettings}
                onSaveSettings={onSaveSettings}
                menu={{
                    isEnabled: true,
                    mandatoryFields: ViewService.getViewMandatoryFields(EntityType.KeyDate),
                    getItemCommands: this._getItemCommands,
                }}
            />
            {
                this.state.setBaselineSelectedEntities &&
                <KeyDateSetBeselineConfirmation
                    selectedItems={this.state.setBaselineSelectedEntities}
                    onDismiss={() => this.setState({ setBaselineSelectedEntities: undefined })}
                    onConfirm={() => this._setBaseline(this.state.setBaselineSelectedEntities!)}
                />
            }
        </>;
    }

    private _calculateVariance = (updatedKeyDate: KeyDate): KeyDateVariance | undefined => {
        const keyDate = this.props.entity.keyDates.find(_ => _.id === updatedKeyDate.id)

        if (!keyDate || !keyDate.baseline) {
            return undefined;
        }

        const dateVariance = keyDate.attributes.Date === updatedKeyDate.attributes.Date
            ? keyDate.baseline.dateVariance
            : KeyDateVarianceCalculator.CalculateDateVariance(updatedKeyDate.attributes.Date, keyDate.baseline?.date, this.props.calendar);

        return { dateVariance }
    }

    private _buildSelectionModeCommands = (selectedItems: KeyDate[]): IContextualMenuItem[] => ([
        {
            key: "SetBaseline",
            name: `Set Baseline`,
            iconProps: { iconName: 'PPMXBaseline' },
            onClick: () => this._showSetBaselineConfirmation(selectedItems),
            disabled: !this._canSetBaseline(selectedItems)
        },
        this._buildSetShowOnTimelineCommand(selectedItems)
    ]);

    private _getItemCommands = (selectedItems: KeyDate[]) => {
        if (!this.props.entity.isEditable) {
            return [];
        }

        return [this._buildSetShowOnTimelineCommand(selectedItems)];
    }

    private _showSetBaselineConfirmation = (selectedItems: KeyDate[]) => {
        this.setState({ setBaselineSelectedEntities: selectedItems.filter(_ => !this.isKeyDateRolledUp(_)) })
    }

    private _setShowOnTimeline = (selectedItems: KeyDate[], value: boolean) => {
        if (selectedItems.length) {
            const updates: IPatch<KeyDate>[] = [];
            selectedItems.forEach(_ => {
                _.attributes.ShowOnTimeline = value;
                updates.push({ id: _.id, attributes: { ShowOnTimeline: _.attributes.ShowOnTimeline } });
            });
            this.props.actions.bulkUpdate(updates);
        }
    }

    private _openKeyDate = (keyDate: KeyDate) => {
        const query = new URLSearchParams(this.props.location.search);
        query.set(EntityType.KeyDate.toLowerCase(), keyDate.id);
        this.props.history.replace({ search: query.toString(), state: this.props.location.state });
    }

    private _setBaseline = (selectedItems: KeyDate[]) => {
        if (selectedItems.length) {
            this.props.actions.setBaseline(selectedItems.map(_ => _.id));
        }
    }

    private _canSetBaseline = (selectedItems: KeyDate[]) => {
        if (!this.props.entity.canCollaborate) {
            return false;
        }
        return selectedItems.some(_ => !this.isKeyDateRolledUp(_));
    }

    private _getTextForEditKeyDate = (entity: ISubentity | undefined): Partial<IHeader> | undefined => {
        if (!entity) {
            return undefined;
        }

        const isKeyDateFromTask = this.isKeyDateFromTask(entity)

        const link = entity.externalData["NameLink"] ?? entity.externalData["ExtraNameLink"];

        const getExternalTaskLink = (ent: ISubentity) =>
            ent.externalData["NameLink"]
                ? <Link href={ent.externalData["NameLink"]} target="_blank">task</Link>
                : "task";

        const getKeyDateSecondaryHeader = (ent: ISubentity) =>
            ent.attributes.Type === KeyDateType.Milestone
                ? <div>
                    This key date is promoted from {getExternalTaskLink(ent)}.
                    The details are updated automatically once the source task is updated.
                </div>
                : <div>
                    This key date is imported from {getExternalTaskLink(ent)}.
                    The details are updated automatically once the source task is updated.
                </div>;

        if (entity.sourceType !== SourceType.Ppmx) {
            return isLinkedSubentity(EntityType.KeyDate, entity)
                ? { secondaryText: getKeyDateSecondaryHeader(entity) }
                : undefined;
        }

        return {
            secondaryText:
                isKeyDateFromTask
                    ? getKeyDateSecondaryHeader(entity)
                    : <div>
                        This key date is rolled up from <Link href={link} target="_blank">key date</Link>.
                        The details are updated automatically once the original key date is updated.
                    </div>
        };
    }

    private _getReadOnlyFields = (keyDate: KeyDate): string[] => {
        const readOnlyFields = this?.props?.datacontext?.readOnlyFields;
        const extraReadOnlyFields = keyDate.sourceType !== null && keyDate.sourceType !== undefined
            ? [
                ![SourceType.Spo, SourceType.MPPFile].includes(keyDate.sourceType) ? nameof<IKeyDateAttrs>("Tags") : undefined,
                ![SourceType.O365Planner, SourceType.Spo, SourceType.MPPFile].includes(keyDate.sourceType) ? nameof<IKeyDateAttrs>("Stage") : undefined,
            ].filter(notUndefined)
            : [];

        return [
            ...readOnlyFields || [],
            ...extraReadOnlyFields
        ];
    }

    private _getEditableNativeFieldsForLinkedEntities = (keyDate: KeyDate): string[] => {
        return [
            ...this?.props?.datacontext?.editableNativeFieldsForLinkedEntities || [],
            keyDate.sourceType === null || [SourceType.O365Planner, SourceType.Spo, SourceType.MPPFile].includes(keyDate.sourceType)
                ? nameof<IKeyDateAttrs>("Description")
                : undefined,
            !keyDate.externalData["ImportedFromId"] ? nameof<IKeyDateAttrs>("Type") : undefined,
        ].filter(notUndefined);
    }

    private _buildSetShowOnTimelineCommand(selectedItems: KeyDate[]) {
        return {
            key: 'setShowOnTimeline',
            name: 'Set Show on Timeline',
            iconProps: { iconName: "PPMXTimelineView" },
            subMenuProps: {
                items: [true, false].map(_ => (
                    {
                        key: _.toString(),
                        name: _ ? "Yes" : "No",
                        onClick: () => this._setShowOnTimeline(selectedItems, _)
                    }))
            }
        };
    }

    private isKeyDateFromTask(entity: ISubentity) {
        return entity.connectionId === ppmxTaskConnectionId && entity.externalData["NameLink"] &&
            !entity.externalData["ExtraNameLink"] && !entity.externalData["ImportedFromId"];
    }

    private isKeyDateRolledUp(entity: ISubentity) {
        return entity.sourceType == SourceType.Ppmx && entity.connectionId === ppmxTaskConnectionId &&
            entity.externalData["NameLink"] && entity.externalData["ImportedFromId"];
    }

}

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    const fields = state.fields[EntityType.KeyDate];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        calendar: state.calendar
    };
}

export default withRouter(connect(mapStateToProps)(KeyDatesControl));

export const settingsBuilder = (settings: ControlSettings) => viewTypeSettingsBuilder(
    mergeDefault({ timeline: { styleSettingValues: { showBaseline: true } } }, settings),
    ViewTypeViews.TaskControl.default)
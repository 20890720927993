import * as React from 'react';
import { DatePicker, DayOfWeek, IDatePickerStrings, IDatePicker, ITextField } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps'
import InputErrorMessage from "./inputErrorMessage";
import { IFormInputComponent } from "../interfaces/IFormInputComponent";
import { Validator, ValidatorType } from "../../../validation";
import { MaybeDate } from '../../../entities/common';
import { FormatDate, MaxDateConst, MinDateConst, cultureInfo, toDate } from '../../utils/common';
import { DateTime } from 'luxon';
import ClearInput from './TextInput/ClearInput';

export const DayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],

    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ],

    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],

    shortDays: [
        'S',
        'M',
        'T',
        'W',
        'T',
        'F',
        'S'
    ],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',

    isRequiredErrorMessage: 'This field is required',
    isOutOfBoundsErrorMessage: ' ',
    invalidInputErrorMessage: 'Invalid date format'
};

export type DatePickerInputProps = {
    minDate?: MaybeDate;
    maxDate?: MaybeDate;
    onParseDateFromString?: (v: string) => void;
    disableAutoFocus?: boolean;
    isRequired?: boolean;
    isClearable?: boolean;
} & IFormInputProps<string>;

type DatePickerInputState = {
    firstDayOfWeek?: DayOfWeek;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
}

export default class DatePickerInput extends React.Component<DatePickerInputProps, DatePickerInputState> implements IFormInputComponent {
    private inputComponent = React.createRef<IDatePicker>();
    private textFieldComponent = React.createRef<ITextField>();

    componentWillMount() {
        this.setState({
            value: this.props.value ? toDate(this.props.value) : undefined,
            minDate: this._getMinDate(this.props.minDate),
            maxDate: this._getMaxDate(this.props.maxDate),
            firstDayOfWeek: DayOfWeek.Sunday,
        });
    }

    private _getMaxDate(newDate: MaybeDate) {
        let maxDate = newDate ? toDate(newDate) : undefined;
        if (!maxDate || maxDate > MaxDateConst) {
            maxDate = MaxDateConst;
        }
        return maxDate;
    }

    private _getMinDate(newDate: MaybeDate) {
        let minDate = newDate ? toDate(newDate) : undefined;
        if (!minDate || minDate < MinDateConst) {
            minDate = MinDateConst;
        }
        return minDate;
    }

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    componentWillReceiveProps(nextProps: DatePickerInputProps) {
        if (this.props.value !== nextProps.value) {
            const newValue = nextProps.value ? toDate(nextProps.value) : undefined;
            this.setState({ value: newValue });
        }
        const isDate = Validator.new().date().build();
        if (this.props.minDate !== nextProps.minDate && isDate.isValid(this.state.minDate)) {
            const newMinDateValue = this._getMinDate(nextProps.minDate);
            this.setState({ minDate: newMinDateValue });
        }
        if (this.props.maxDate !== nextProps.maxDate && isDate.isValid(this.state.maxDate)) {
            const newMaxDateValue = this._getMaxDate(nextProps.minDate);
            this.setState({ maxDate: newMaxDateValue });
        }
    }

    public render() {
        const { firstDayOfWeek, value, minDate, maxDate } = this.state;
        const { inputProps, disabled, readOnly, disableAutoFocus, isRequired } = this.props;
        const isReadOnly = readOnly || inputProps?.readOnly;
        const isDisabled = disabled || inputProps?.disabled;

        const isClearable = !isRequired && !isReadOnly && !isDisabled && !this.props.validator?.has(ValidatorType.required);

        return <>
            <DatePicker
                placeholder={inputProps?.placeholder}
                componentRef={this.inputComponent}
                firstDayOfWeek={firstDayOfWeek}
                strings={DayPickerStrings}
                onSelectDate={this._onSelectDate}
                formatDate={this._onFormatDate}
                parseDateFromString={this._onParseDateFromString}
                allowTextInput
                textField={{
                    readOnly: isReadOnly,
                    disabled: isDisabled
                        ? true
                        : isReadOnly
                            ? false
                            : undefined,
                    className: isClearable ? "clearable" : undefined,
                    onRenderSuffix: value && isClearable ? () => <ClearInput onClick={() => {
                        this._onSelectDate(undefined);
                        this.textFieldComponent.current?.blur();
                        this.inputComponent.current?.reset();
                    }} /> : undefined,
                    componentRef: this.textFieldComponent
                }}
                value={value}
                minDate={isReadOnly || isDisabled ? undefined : minDate}
                maxDate={isReadOnly || isDisabled ? undefined : maxDate}
                disabled={isReadOnly || isDisabled}
                disableAutoFocus={disableAutoFocus}
                className={this._validate(value) ? undefined : "invalid"}
                isRequired={isRequired} />
            <InputErrorMessage text={this._getErrorMessage(value)} />
        </>
    }

    private _onSelectDate = (date: Date | null | undefined): void => {
        if (date === null) {
            date = undefined;
        }
        if (this.state.value === date) {
            return;
        }

        this.setState({ value: date });

        const value = date?.toDateOnlyString() ?? null;
        this.props.onChanged?.(value);
        this._validate(date) && this.props.onEditComplete?.(value);

        this.inputComponent.current?.focus();
    }

    private _onFormatDate = (date?: Date): string => {
        if (date && date instanceof Date && !isNaN(date.getTime())) {
            return FormatDate(date, { year: 'numeric', month: 'numeric', day: 'numeric'})!;
        }
        return '';
    }

    private _onParseDateFromString = (value: string): Date | null => {
        this.props.onParseDateFromString?.(value);

        const format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, { locale: cultureInfo.name });
        const dateTime = DateTime.fromFormat(value, format!);

        return dateTime.isValid ? dateTime.toJSDate() : null;
    }

    private _validate = (value?: Date) => {
        return this.props.validator === undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value?: Date) => {
        return this.props.validator && this.props.validator.getErrorMessage(value);
    }
    
    focus(): void {
        (this.inputComponent.current as any)._onTextFieldClick();
    }

    focusDatepicker(): void {
        this.inputComponent.current?.focus();
    }
}
import { CheckboxVisibility, IContextualMenuItem, SelectionMode } from "office-ui-fabric-react";
import { ISyncVersion, SourceType, SourceType_ } from "../store/ExternalEpmConnectStore";
import { IOrderBy } from "../store/views";

export type MaybeDate = Date | null | undefined | string;

export class Dictionary<T> {
    [key: string]: T;
}

export interface IWarning {
    type: string;
    text: string;
}

export class UpdateContext {
    forceWriteHistory?: boolean;
    overrideDuration?: number;
}

export interface IBaseEntity {
    id: string;
}

export interface IWithStartFinishDates {
    StartDate?: string;
    FinishDate?: string;
}

export interface IWithDuration {
    StartDate?: MaybeDate;
    DueDate?: MaybeDate;
    Duration?: number | null;
}

export interface IWithTagsAttrs {
    Tags?: string[];
}

export interface IEntityInfo {
    id: string;
    name: string;
}

export interface IRefInfo extends IEntityInfo {
    entityType?: ServerEntityType;
    isDeleted?: boolean;
}

export interface IColorOption {
    name: string;
    color: string;
}

export type IWithCategory<T> = {
    category: T;
};

export type CategoryOption<T> = IWithCategory<T> & IColorOption;

export enum StatusCategory {
    NA = -1,
    Green = 0,
    Amber = 1,
    Red = 2,
    Done = 10
}

export enum StageCategory {
    Initiating = 0,
    Planning = 1,
    Executing = 2,
    MonitoringAndControlling = 3,
    Closing = 10,
    Suspended = 20,
    Canceled = 30
}

export enum Impact {
    NA = 0,
    Low = 1,
    Normal = 2,
    Moderate = 3,
    Strong = 4,
    Extreme = 5
}
export interface ImpactConfig { title: string }
export const ImpactConfigMap: { [i: number]: ImpactConfig } =
{
    [Impact.NA]: {
        title: "N/A"
    },
    [Impact.Low]: {
        title: "Low"
    },
    [Impact.Normal]: {
        title: "Normal"
    },
    [Impact.Moderate]: {
        title: "Moderate"
    },
    [Impact.Strong]: {
        title: "Strong"
    },
    [Impact.Extreme]: {
        title: "Extreme"
    }
}

export type CategoryConfig = {
    title: string;
    cssClassName: string;
    order: number;
    color: string;
    iconName: string;
}

export const statusCategoryMap: Record<StatusCategory, CategoryConfig> =
{
    [StatusCategory.NA]: {
        title: "N/A",
        cssClassName: "Gray",
        order: 0,
        color: '#788086',
        iconName: 'Unknown'
    },
    [StatusCategory.Green]: {
        title: "On Track",
        cssClassName: "Green",
        order: 1,
        color: '#008272',
        iconName: 'PPMXArrowHorizontalStatus'
    },
    [StatusCategory.Amber]: {
        title: "At Risk",
        cssClassName: "Amber",
        order: 2,
        color: '#ff8c00',
        iconName: 'PPMXArrowUpStatus',
    },
    [StatusCategory.Red]: {
        title: "Critical",
        cssClassName: "Red",
        order: 3,
        color: '#a80000',
        iconName: 'PPMXArrowDownStatus',
    },
    [StatusCategory.Done]: {
        title: "Done",
        cssClassName: "Blue",
        order: 4,
        color: '#0076d7',
        iconName: 'PPMXSelected',
    }
}

export const StatusCategoryConfigsList = Object.keys(statusCategoryMap).map(_ => {
    const key: number = parseInt(_);
    const item = statusCategoryMap[key];
    return { ...item, status: key }
}).sort((a, b) => a.order - b.order);

export const stageCategoryMap: Record<StageCategory, CategoryConfig> =
{
    [StageCategory.Initiating]: {
        title: "Initiating",
        cssClassName: "LightBlue",
        order: 0,
        color: '#00BBF5',
        iconName: 'CRMProcesses',
    },
    [StageCategory.Planning]: {
        title: "Planning",
        cssClassName: "Amber",
        order: 1,
        color: '#FF8C00',
        iconName: 'CRMProcesses',
    },
    [StageCategory.Executing]: {
        title: "Executing",
        cssClassName: "Green",
        order: 2,
        color: '#008272',
        iconName: 'CRMProcesses',
    },
    [StageCategory.MonitoringAndControlling]: {
        title: "Monitoring & Controlling",
        cssClassName: "Purple",
        order: 3,
        color: '#8271EB',
        iconName: 'CRMProcesses',
    },
    [StageCategory.Closing]: {
        title: "Closing",
        cssClassName: "Blue",
        order: 4,
        color: '#0076D7',
        iconName: 'CRMProcesses',
    },
    [StageCategory.Suspended]: {
        title: "Suspended",
        cssClassName: "Gray",
        order: 5,
        color: '#788086',
        iconName: 'CRMProcesses',
    },
    [StageCategory.Canceled]: {
        title: "Canceled",
        cssClassName: "Gray",
        order: 6,
        color: '#788086',
        iconName: 'CRMProcesses',
    }
}

export interface IExtensibleEntity extends IBaseEntity {
    attributes: Dictionary<any>;
}

export interface IPatch<T extends IExtensibleEntity> {
    id?: string;
    attributes: Partial<T['attributes']>;
}

export function buildDelta<T extends IExtensibleEntity>(item: IPatch<T>, prevState: T | undefined) {
    item = { id: item.id, attributes: { ...item.attributes } };

    if (prevState) {
        for (const key in item.attributes) {
            if (prevState.attributes[key] === item.attributes[key]) {
                delete (item.attributes as any)[key];
            }
        }
    }

    return item;
}

export interface IEditable {
    isEditable: boolean;
}

export interface IEditableExtensibleEntity extends IExtensibleEntity, IEditable { }

export interface IBlinePlanActual {
    baseline: number;
    plan: number;
    actual: number;
}

export interface IConnected<T> {
    connectionId: string;
    data: T;
}

export interface IUserInfo {
    id: string;
    fullName: string;
    imageId: string;
}

export interface ICalculation {
    costs: IBlinePlanActual;
    benefits: IBlinePlanActual;
}

export interface IWithName {
    Name: string;
}

export interface IWithManager {
    Manager: IUserInfo[];
}

export interface IWithLayout {
    layoutId?: string;
}

export interface IWithImage {
    imageId?: string;
}

export interface IWithWarnings {
    warnings: IWarning[];
}

export interface IWithChangeHistory {
    changeHistory: IHistoryRow[];
}

export interface IWithInsights {
    insights: IInsightsData;
}

export interface IWithSourceInfos {
    sourceInfos: ISourceInfo[];
}

export interface IWithKeyResultAssignments {
    keyResults: KeyResultAssignment[];
}

export interface IWithBenefits {
    BenefitsStartDate?: string;
    BenefitsFinishDate?: string;
}

export interface IWithResourcePlan {
    resourceIds: string[];
}

export interface IEditableExtensibleEntityWithResourcePlan extends IExtensibleEntity, IWithResourcePlan, IEditable { }

export interface KeyResultAssignment {
    id: string;

    keyResultId: string;
    objectiveId: string;

    projectStartValue: number;
    projectTargetValue: number;
    currentValue: number;
    progress?: number;

    status: StatusCategory;
    description: string;
}

export enum ProgressCalculationType {
    Effort = 0,
    StoryPoints = 1,
    AverageProgress = 2,
}

export interface IInsightsData {
    warningsCalculationDisabled: boolean;
    keyDateStatusCalculationDisabled: boolean;
    taskStatusCalculationDisabled: boolean;
    statusCalculationDisabled: boolean;
    statuses: Dictionary<IInsightsStatusData>;
}

export interface IInsightsStatusData {
    autoValue: StatusCategory;
    autoDescription: string;
    manualValueDueDate?: string;
    trend: StatusTrendType;
    autoCalcDate?: string;
}


export enum StatusTrendType {
    NA = 0,
    Stable = 1,
    Positive = 2,
    Negative = 3
}

export interface IWithAiInsights {
    aiInsights: AiInsightInfo;
}

export interface IWithAttributes {
    attributes: Dictionary<any>;
}

export interface AiInsightInfo {
    isAiInsightsLoading?: boolean;
    suggestedActions: AiSuggestedAction[];
    lastUpdatedDate?: Date;
    isOutdated: boolean;
    isSyncInProgress: boolean;
}

export interface AiSuggestedAction {
    id: string;
    actionName: string;
    actionDescription: string;
    actionType?: SuggestedActionType;
    attributes?: Dictionary<string>;
    score: number;
    connectionId: string;
    isLiked?: boolean;
    isDisliked?: boolean;
}

export enum SuggestedActionType {
    Statuses = 1,
    ThisWeekKeyDates = 2,
    OverdueKeyDates = 3,
    SlippedKeyDates = 4,
    ActiveRisks = 5,
    ActiveIssues = 6,
    PendingChangeRequests = 7,
    SlippedTasks = 8,
    ThisWeekTasks = 9,
    OverdueTasks = 10,
    Budget = 11,
    BudgetActive = 12,
    Effort = 13,
    StoryPoints = 14,
    OverallocatedResources = 16,
    SyncFailed = 17,
}

export const SuggestedActionAttributeNames = {
    STATUS_LABEL: "STATUS_LABEL",
    STATUS_NAME: "STATUS_NAME",
    STATUS_VALUE: "STATUS_VALUE"
}

export const trendIconMap: { [i: number]: string } = {
    [StatusTrendType.NA]: 'Unknown',
    [StatusTrendType.Stable]: 'PPMXArrowHorizontalStatus',
    [StatusTrendType.Positive]: 'PPMXArrowUpStatus',
    [StatusTrendType.Negative]: 'PPMXArrowDownStatus',
};

export const trendTitleMap: { [i: number]: string } = {
    [StatusTrendType.NA]: 'N/A',
    [StatusTrendType.Stable]: 'Stable',
    [StatusTrendType.Positive]: 'Positive',
    [StatusTrendType.Negative]: 'Negative',
};

export interface ISourceInfo<T = any> {
    connectionId: string;
    sourceId?: string;
    type: SourceType;
    syncStatus: {
        inProgress: boolean,
        lastException?: string,
        lastSyncDate: string | null;
    };
    syncVersion: ISyncVersion;
    sourceData: T;
}

export const TasksActualVersions: { plannerPlan: number; spoProject: number, vstsProject: number } = {
    plannerPlan: 1,
    spoProject: 1,
    vstsProject: 1
}

export const ppmxTaskConnectionId: string = "00000000-0000-0000-0000-000000000000";
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000"

export const PpmxConnection: ISourceInfo<undefined> = {
    type: SourceType.Ppmx,
    connectionId: ppmxTaskConnectionId,
    sourceData: undefined,
    syncStatus: { inProgress: false, lastSyncDate: null },
    syncVersion: { task: 0 }
}

export interface IHistoryRow {
    id: string;
    value: any;
    fieldName: string;
    date: string;
    setBy: IUserInfo | null;
    description: string;
}

export interface IStatusHistoryRow extends IHistoryRow {
    trend: StatusTrendType;
}

export namespace SourceInfo {
    export function getSyncableConnections(sources: ISourceInfo[]): ISourceInfo[] {
        return sources.filter(_ => isSyncable(_));
    }

    export function getDataConnections(sources: ISourceInfo[]): ISourceInfo[] {
        return sources.filter(_ => _.type !== SourceType.O365TeamsChannel);
    }

    export function getSources(sources: ISourceInfo[]): ISourceInfo[] {
        return sources.filter(_ => !isCollaborative(_));
    }

    export function getCollaborative(sources: ISourceInfo[]): ISourceInfo[] {
        return sources.filter(isCollaborative);
    }

    export function isCollaborative(source: ISourceInfo): boolean {
        return !!~SourceType_.collaborative.indexOf(source.type);
    }

    export function isSyncable(source: ISourceInfo): boolean {
        return source.type !== SourceType.File;
    }

    export function syncFailed(source: ISourceInfo[]): boolean {
        return source.some(_ => (!!_.syncStatus.lastException));
    }

    export function getSyncStatusConfig(isSyncable: boolean, sourceInfos: ISourceInfo[]): { order: number, label: string } | undefined {
        if (sourceInfos.length === 0) {
            return undefined;
        }
        return !isSyncable
            ? syncStatusMap.inProgress
            : (syncFailed(sourceInfos)
                ? syncStatusMap.fail
                : syncStatusMap.success);
    }

    export function getUrl(sourceInfo: ISourceInfo<any>, entityType: EntityType): string | undefined {
        switch (sourceInfo.type) {
            case SourceType.O365Group: return sourceInfo.sourceData.groupUrl;
            case SourceType.VSTS: return sourceInfo.sourceData.projectUrl;
            case SourceType.Jira: return sourceInfo.sourceData.projectUrl;
            case SourceType.File: return sourceInfo.sourceData.fileUrl;
            case SourceType.O365Planner: return sourceInfo.sourceData.planUrl;
            case SourceType.Spo: return sourceInfo.sourceData.projectUrl;
            case SourceType.O365TeamsChannel: return sourceInfo.sourceData.channelUrl;
            case SourceType.PpmxTime: return sourceInfo.sourceData.projectUrl;
            case SourceType.MondayCom: return sourceInfo.sourceData.boardUrl;
            case SourceType.Smartsheet:
                if (entityType === EntityType.Project) { return sourceInfo.sourceData.sheetUrl; }
                if (entityType === EntityType.Program) { return sourceInfo.sourceData.workspaceUrl; }
                return undefined;
            case SourceType.P4W: return sourceInfo.sourceData.projectUrl;
        }
        return '';
    }

    export function GetExternalHint(source: ISourceInfo<any>, entityType: EntityType) {
        const data = source.sourceData;
        switch (source.type) {
            case SourceType.O365Planner:
                return `Linked to plan "${data.title}"`;
            case SourceType.O365Group:
                return `Linked to group site "${data.groupName}"`;
            case SourceType.Spo:
                return `Linked to project "${data.projectName}"`;
            case SourceType.Jira:
                if (data.projects.length > 1) {
                    return "Linked to Jira projects";
                }
                if (data.issues?.length === 1) {
                    return `Linked to issue "${data.issues[0].name}" from project "${data.projects[0].name}"`;
                }
                return `Linked to project "${data.projects[0].name}"`;
            case SourceType.VSTS:
                if (data.workItems?.length === 1) {
                    return `Linked to work item "${data.workItems[0].name}" from project "${data.projectName}"`;
                }
                return `Linked to project "${data.projectName}"`;
            case SourceType.PpmxTime: {
                return `Linked to project "${data.projectName}"`
            }
            case SourceType.O365TeamsChannel: {
                if (data.channelName) {
                    return `Linked to channel "${data.channelName}"`
                }
                return undefined;
            }
            case SourceType.MondayCom: {
                return `Linked to board "${data.boardName}"`;
            }
            case SourceType.Smartsheet: {
                if (entityType === EntityType.Project) { return `Linked to sheet "${data.sheetName}"`; }
                if (entityType === EntityType.Program) { return `Linked to workspace "${data.workspaceName}"`; }
                return undefined;
            }
            case SourceType.MPPFile: {
                return `Linked to project "${data.fileName.split('.')[0]}"`;
            }
            case SourceType.P4W:
                return `Linked to project "${data.projectName}"`;
            default:
                return undefined;
        }
    }
}

type CheckboxOptions = {
    checkboxVisibility?: CheckboxVisibility;
    selectionMode?: SelectionMode;
};

export function getCheckboxOptionsBasedOnCommands(selectionModeCommands: IContextualMenuItem[] | undefined, showSelectedCount?: boolean): CheckboxOptions {
    return updateCheckboxOptions({ checkboxVisibility: undefined, selectionMode: undefined }, selectionModeCommands, showSelectedCount);
}

export function updateCheckboxOptions(props: CheckboxOptions, selectionModeCommands: IContextualMenuItem[] | undefined, showSelectedCount?: boolean): CheckboxOptions {
    return selectionModeCommands !== undefined && selectionModeCommands.length > 0 || showSelectedCount
        ? props
        : { checkboxVisibility: CheckboxVisibility.hidden, selectionMode: SelectionMode.none };
}

const syncStatusMap: Dictionary<{ order: number, label: string }> = {
    fail: { order: 1, label: "Failed" },
    inProgress: { order: 2, label: "In Progress" },
    success: { order: 3, label: "Success" },
}

export enum EntityType {
    Project = 'project',
    Portfolio = 'portfolio',
    Resource = 'resource',
    Challenge = 'challenge',
    Idea = 'idea',
    StrategicPriority = 'strategicpriority',
    Task = 'externaltask',
    Insights = 'insights',
    KeyDate = 'keydate',
    Iteration = 'iteration',
    Risk = 'risk',
    Issue = 'issue',
    LessonLearned = 'lessonlearned',
    ActionItem = 'actionitem',
    SteeringCommittee = 'steeringcommittee',
    KeyDecision = 'keydecision',
    ChangeRequest = 'changerequest',
    MyWork = "mywork",
    Objective = "objective",
    Program = "program",
    Dependency = "dependency",
    Roadmap = "roadmap",
    RoadmapItem = "roadmapitem",
    KeyResult = 'keyresult',
    ArchivedProject = 'archivedproject',
    PurchaseOrder = 'purchaseorder',
    Invoice = 'invoice',
    Deliverable = 'deliverable',
    TimeTrackingEntry = 'timetrackingentry',
    PrivateProject = 'privateproject'
}

export type EntityTypeLabels = {
    singular: string;
    plural: string;
};

export const entityTypeLabelMap: Record<EntityType, EntityTypeLabels> = {
    [EntityType.Project]: { singular: "Project", plural: "Projects" },
    [EntityType.Program]: { singular: "Program", plural: "Programs" },
    [EntityType.Portfolio]: { singular: "Portfolio", plural: "Portfolios" },
    [EntityType.Resource]: { singular: "Resource", plural: "Resources" },
    [EntityType.Challenge]: { singular: "Business Challenge", plural: "Business Challenges" },
    [EntityType.Idea]: { singular: "Idea", plural: "Ideas" },
    [EntityType.StrategicPriority]: { singular: "Strategic Priority", plural: "Strategic Priorities" },
    [EntityType.Task]: { singular: "Task", plural: "Tasks" },
    [EntityType.Insights]: { singular: "Insights", plural: "Insights" },
    [EntityType.KeyDate]: { singular: "Key Date", plural: "Key Dates" },
    [EntityType.Deliverable]: { singular: "Deliverable", plural: "Deliverables" },
    [EntityType.Iteration]: { singular: "Iteration", plural: "Iterations" },
    [EntityType.Risk]: { singular: "Risk", plural: "Risks" },
    [EntityType.Issue]: { singular: "Issue", plural: "Issues" },
    [EntityType.LessonLearned]: { singular: "Lesson", plural: "Lessons" },
    [EntityType.ActionItem]: { singular: "Action Item", plural: "Action Items" },
    [EntityType.SteeringCommittee]: { singular: "Person", plural: "People" },
    [EntityType.KeyDecision]: { singular: "Key Decision", plural: "Key Decisions" },
    [EntityType.ChangeRequest]: { singular: "Change Request", plural: "Change Requests" },
    [EntityType.MyWork]: { singular: "My Work", plural: "My Works" },
    [EntityType.Objective]: { singular: "Objective", plural: "Objectives" },
    [EntityType.Dependency]: { singular: "Dependency", plural: "Dependencies" },
    [EntityType.Roadmap]: { singular: "Roadmap", plural: "Roadmaps" },
    [EntityType.RoadmapItem]: { singular: "Roadmap Item", plural: "Roadmap Items" },
    [EntityType.KeyResult]: { singular: "Key Result", plural: "Key Results" },
    [EntityType.ArchivedProject]: { singular: "Archived Project", plural: "Archived Projects" },
    [EntityType.PurchaseOrder]: { singular: "Purchase Order", plural: "Purchase Orders" },
    [EntityType.Invoice]: { singular: "Invoice", plural: "Invoices" },
    [EntityType.TimeTrackingEntry]: { singular: "Time Tracking Entry", plural: "Time Tracking Entries" },
    [EntityType.PrivateProject]: { singular: "Private Project", plural: "Private Projects" },
};

export enum ServerEntityType {
    Project = 1,
    Portfolio = 2,
    Challenge = 3,
    Idea = 4,
    KeyDate = 5,
    Risk = 6,
    Issue = 7,
    Task = 8,
    Iteration = 9,
    ActionItem = 10,
    Objective = 11,
    Program = 12,
    Dependency = 13,
    Roadmap = 14,
    RoadmapItem = 15
}

export const mapServerEntityType: { [key in number]?: EntityType } = {
    [ServerEntityType.Portfolio]: EntityType.Portfolio,
    [ServerEntityType.Program]: EntityType.Program,
    [ServerEntityType.Project]: EntityType.Project,
    [ServerEntityType.KeyDate]: EntityType.KeyDate,
    [ServerEntityType.Challenge]: EntityType.Challenge,
    [ServerEntityType.Idea]: EntityType.Idea,
    [ServerEntityType.Objective]: EntityType.Objective,
    [ServerEntityType.Task]: EntityType.Task,
    [ServerEntityType.Risk]: EntityType.Risk,
    [ServerEntityType.Issue]: EntityType.Issue,
    [ServerEntityType.Iteration]: EntityType.Iteration,
    [ServerEntityType.ActionItem]: EntityType.ActionItem,
    [ServerEntityType.Dependency]: EntityType.Dependency,
    [ServerEntityType.Roadmap]: EntityType.Roadmap,
    [ServerEntityType.RoadmapItem]: EntityType.RoadmapItem
}

export interface LogoConfig { cssClass?: string, label: string, iconName?: string }

export const entityLogoConfig: { [key in EntityType]?: LogoConfig } = {
    [EntityType.Project]: { label: 'Project', cssClass: 'proj-logo', iconName: 'PPMXProject' },
    [EntityType.Program]: { label: 'Program', cssClass: 'prog-logo', iconName: 'PPMXProgram' },
    [EntityType.Portfolio]: { label: 'Portfolio', cssClass: 'pf-logo', iconName: 'PPMXPortfolio' },
    [EntityType.Idea]: { label: 'Idea', cssClass: 'idea-logo' },
    [EntityType.Objective]: { label: 'Objective' },
    [EntityType.Task]: { label: 'Task' },
    [EntityType.KeyDate]: { label: 'Key Date' },
    [EntityType.Challenge]: { label: 'Challenge', cssClass: 'challenge-logo' }
}

export enum Quantization {
    days = "d",
    weeks = "w",
    months = "m",
    quarters = "q",
    years = "y"
}

export interface ITimeframe {
    start: Date;
    end: Date;
}

export type UserPreferencesSettingsUpdate = {
    parentSettingsPathKeys: string[];
    valueBySettingNameMap: Dictionary<any>;
    immediate?: boolean;
};

export type OriginScale = {
    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
}

export enum SortDirection {
    ASC = 0,
    DESC = 1
}

export interface ISortState {
    fieldName: string;
    direction: SortDirection;
}

export type FieldSettings = {
    width: number;
};

export interface IWithActiveSubViewId {
    activeSubViewId?: string;
}

export interface IWithSubViews {
    // subViewId -> columnId
    subViews?: Dictionary<Dictionary<FieldSettings>>;
}

export interface IWithSortBy {
    sortBy?: ISortState;
}

export interface IWithScale {
    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
}

export interface DetailsControlSettings extends IWithSubViews, IWithSortBy { }

export interface TimelineControlSettings extends IWithSubViews, IWithSortBy {
    quantization?: Quantization;
    timeframe?: Partial<ITimeframe>;
}

export interface TimelineMapControlSettings extends TimelineControlSettings { }

export const MIN_COLUMN_WIDTH = 100;

export const PANEL_CUSTOM_WIDTH = "456px";
export const LARGE_PANEL_WIDTH = "700px";
export const EXTRA_LARGE_PANEL_WIDTH = "1000px";

export const PROGRESS_COMPLETED = 100;

export type ComparerBuilder<T = unknown, TOrderBy = IOrderBy> = (orderBy: TOrderBy) => (a: T, b: T) => number;

export interface ISortingProps<TSort = ISortState | ISortState[]> {
    disabled?: boolean;
    external?: boolean;
    orderBy?: TSort;
    onChange?: (sort: TSort | undefined) => void;
}

export interface IStyleSettingValues {
    largeBars?: boolean;
    showToday?: boolean;
    showRelations?: boolean;
    showBaseline: boolean;
}

export enum SectionNames {
    SUMMARY = "Summary",
    DETAILS = "Details",
    STATUSES = "Statuses",
    PROJECTS = "Projects",
    KEYDATES = "Key dates",
    BUDGET = "Budget",
    STRATEGIC_ALIGNMENT = "Strategic Alignment",
    DECISION_MATRIX = "Decision Matrix",
    TIME = "Time",
    ACTION_ITEMS = "Action items",
    KEY_DECISIONS = "Key Decisions",
    RESOURCE_PLAN = "Resource plan",
    RISKS = "Risks",
    KEYRESULTS = "OKR",
    PROGRAMS = "Programs & Projects",
    KPI = "KPI",
    ISSUES = "Issues",
    CHANGE_REQUESTS = "Change Requests"
}

export enum Sections {
    ProjectTasksSectionId = "996e861a-2bfb-448f-bcd1-1e51226c14ef",
    RoadMapSectionId = "e7b5eac6-edd0-49a7-95a5-507c58a6493d"
}

export enum AuthProvider {
    o365 = "o365",
    govus_o365 = "govus_o365",
    google = "google",
    email = "email"
}

export const AuthProvidersMap = {
    [AuthProvider.email]: {
        buttonClassName: "email",
        logoClassName: "emaillogo",
        friendlyName: "Email"
    },
    [AuthProvider.google]: {
        buttonClassName: "google",
        logoClassName: "googlelogo",
        friendlyName: "Google"
    },
    [AuthProvider.o365]: {
        buttonClassName: "office",
        logoClassName: "o365logo",
        friendlyName: "Office 365"
    },
    [AuthProvider.govus_o365]: {
        buttonClassName: "office",
        logoClassName: "o365logo",
        friendlyName: "Office 365"
    }
}

export enum CostType{
    Opex = "OPEX",
    Capex = "CAPEX"
}
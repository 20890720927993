import * as React from "react";
import { ITaskAttrs, ITask, buildPredecessorsMap, IPredecessorInfo, PredecessorType, PredecessorTypeMetadata } from "../../entities/Subentities";
import { Validator, IValidator } from "../../validation";
import { nameof } from "../../store/services/metadataService";
import * as Metadata from '../../entities/Metadata';
import DatePickerInput from '../common/inputs/DatePickerInput';
import SliderInput from '../common/inputs/SliderInput';
import GroupDropdown from "../common/inputs/GroupDropdown";
import OptionsPicker, { Option } from "../common/inputs/OptionsPicker";
import * as utils from '../common/timeline/utils';
import { Dictionary, MaybeDate, ProgressCalculationType } from "../../entities/common";
import { CalendarDataSet } from "../../store/CalendarStore";
import NumberInput from "../common/inputs/NumberInput";
import { applyPredecessors, handleDuration } from "../utils/duration";
import { HUNDRED_PCT, MaxDuration, adjustProgress, formatValue, toDate, toDictionaryById } from "../utils/common";
import { DirectionalHint } from "office-ui-fabric-react";
import { ProjectInfo } from "../../store/ProjectsListStore";
import { IInputProps } from "../common/interfaces/IInputProps";

export const validatorsBuilder = (calendar: CalendarDataSet, tasksLoader: () => LoadedTask[] | undefined) => ({
    [nameof<ITaskAttrs>("StartDate")]: (state: ITask, field: Metadata.Field): IValidator => {
        let validatorBuilder = Validator.new().date();
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
        if (!!dictatedDates?.startDate) {
            validatorBuilder = validatorBuilder.required("Required when ToStart predecessor set");
        } else if (field.settings?.required) {
            validatorBuilder = validatorBuilder.required();
        }
        return validatorBuilder.dateIsGreaterThenOrEqual(dictatedDates?.startDate).dateIsLessThenOrEqual(state.attributes.DueDate).build();
    },
    [nameof<ITaskAttrs>("DueDate")]: (state: ITask, field: Metadata.Field): IValidator => {
        let validatorBuilder = Validator.new().date();
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
        if (state.attributes.IsMilestone) {
            validatorBuilder = validatorBuilder.required("Required for Milestone");
        } else if (!!dictatedDates?.dueDate) {
            validatorBuilder = validatorBuilder.required("Required when ToFinish predecessor set");
        } else if (field.settings?.required) {
            validatorBuilder = validatorBuilder.required();
        }
        validatorBuilder = validatorBuilder.customDate((date) => {
            if (date && state.attributes.StartDate && state.attributes.Duration !== undefined
                && state.attributes.Duration !== utils.getWorkingDaysBetweenDates(toDate(state.attributes.StartDate)!, date, calendar)
                && !(state.attributes.Duration === 0 && toDate(state.attributes.StartDate)?.getDate() === date?.getDate())) {
                return false;
            }
            return true;
        },
            "Dates mismatch due to calendar changes");

        const minDueDate = utils.max([dictatedDates?.dueDate, state.attributes.StartDate]);
        return validatorBuilder.dateIsGreaterThenOrEqual(minDueDate).build();
    },
    [nameof<ITaskAttrs>("Duration")]: (state: ITask, field: Metadata.Field): IValidator => {
        let validatorBuilder = Validator.new().int32();
        if (field.settings?.required) {
            validatorBuilder = validatorBuilder.required();
        }
        return validatorBuilder.min(0).max(MaxDuration).build();
    },
    [nameof<ITaskAttrs>("Progress")]: (state: ITask, field: Metadata.Field): IValidator => {
        let validatorBuilder = Validator.new();
        if (field.type == Metadata.FieldType.Decimal) {
            validatorBuilder.decimal();
        }
        else if (field.type == Metadata.FieldType.Integer) {
            validatorBuilder.int32();
        }
        if (field.settings?.required) {
            validatorBuilder = validatorBuilder.required();
        }
        validatorBuilder.min(field.settings?.minValue).max(field.settings?.maxValue).step(field.settings?.minValue, field.settings?.step)
        return validatorBuilder.build();
    }
});

export type LoadedTask = { id: string, attributes: { Name: string, StartDate?: MaybeDate, DueDate?: MaybeDate, Duration?: number | null, Predecessor?: { id: string }[] } };
export const rendersBuilder = (
    project: ProjectInfo,
    groups: Metadata.Group[],
    calendar: CalendarDataSet,
    urlBuilder: (task: { id: string }) => string | undefined,
    tasksLoader: () => LoadedTask[] | undefined
) => ({
    [nameof<ITaskAttrs>("StartDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: Validator): JSX.Element | null => {
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
        return <DatePickerInput
            {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={dictatedDates?.startDate}
            onChanged={props.onChanged ? (value) => {
                const extra = handleDuration(state.attributes, { StartDate: value }, calendar, true, dictatedDates);
                props.onChanged?.(value, extra);
            } : undefined}
        />;
    },
    [nameof<ITaskAttrs>("DueDate")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
        const minDueDate = utils.max([dictatedDates?.dueDate, state.attributes.StartDate]);
        return <DatePickerInput {...props}
            inputProps={{ readOnly: field.isReadonly }}
            validator={validator}
            minDate={minDueDate}
            onChanged={props.onChanged ? (value) => {
                const extra = handleDuration(state.attributes, { DueDate: value }, calendar, false, dictatedDates);
                props.onChanged?.(value, extra);
            } : undefined}
        />;
    },
    [nameof<ITaskAttrs>("Duration")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const dictatedDates = TaskScheduleCalculator.GetDictatedDates(state.attributes.Predecessor, toDictionaryById(tasksLoader() ?? []), calendar);
                const extra = handleDuration(state.attributes, { Duration: value ?? undefined }, calendar, true, dictatedDates);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("Progress")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        const sliderProps = {
            min: field.settings?.minValue,
            max: field.settings?.maxValue,
            step: field.settings?.step,
            defaultValue: field.settings?.defaultValue,
            className: field.settings?.className,
            readOnly: field.isReadonly
        };
        return <SliderInput {...props} inputProps={sliderProps} validator={validator}
            onEditComplete={(value: number | null) => {
                const extra = TaskProgressCalculator.RecalculateByProgress(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }} 
        />;
    },
    [nameof<ITaskAttrs>("Effort")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByEffort(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("CompletedWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByCompletedWork(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("RemainingWork")]: (props: IInputProps, state: ITask, field: Metadata.Field, validator?: IValidator): JSX.Element | null => {
        return <NumberInput {...props} inputProps={toNumberInputProps(field)} validator={validator} format={field.settings?.format}
            onChanged={undefined} onEditComplete={(value) => {
                const extra = TaskProgressCalculator.RecalculateByRemainingWork(project, state, value);
                props.onChanged?.(value, extra);
                props.onEditComplete(value, extra);
            }}
        />;
    },
    [nameof<ITaskAttrs>("Group")]: (props: IInputProps, state: any, field: Metadata.Field): JSX.Element | null =>
        <GroupDropdown dropdownInputProps={props} value={props.value} groups={groups} />,
    [nameof<ITaskAttrs>("Predecessor")]: (props: IInputProps, state: ITask, field: Metadata.Field): JSX.Element | null => {
        const tasks = tasksLoader();
        const tasksMap = toDictionaryById(tasks ?? []);
        return <OptionsPicker
            disabled={props.readOnly || field.isReadonly}
            pickerCalloutProps={{ directionalHint: DirectionalHint.bottomRightEdge }}
            onResolveSuggestions={(filter, selectedItems) => {
                const predecessorsMap = buildPredecessorsMap(tasks);
                return tasks
                    ?.filter(_ => (!state.id || _.id != state.id && !predecessorsMap[_.id]?.[state.id])
                        && _.attributes.Name.toLowerCase().includes(filter.toLowerCase())
                        && !selectedItems?.find(i => _.id == i.key))
                    .map<Option>(_ => ({
                        key: _.id,
                        text: _.attributes.Name,
                        url: urlBuilder(_),
                        data: {
                            task: _,
                            lag: 0,
                            type: PredecessorType.FinishToStart
                        }
                    })) || [];
            }}
            onChange={
                props.onChanged
                    ? (v) => {
                        const predecessorsData = v?.length ? (v as any as { data: { task: ITask, lag: number, type: PredecessorType } }[]).map(_ => _.data) : [];
                        const predecessors = predecessorsData?.map(_ => ({ id: _.task.id, name: _.task.attributes.Name, lag: _.lag, type: _.type }));
                        const extra = applyPredecessors(state.attributes, predecessors, tasksMap, calendar);
                        props.onChanged!(predecessors, extra);
                    } : undefined
            }
            selectedItems={
                props.value ? props.value.map((_: any) => ({
                    key: _.id,
                    text: `${_.name} (${PredecessorTypeMetadata[_.type].abbreviation}${_.lag < 0 ? "" : "+"}${formatValue(_.lag, Metadata.FormatType.Days)})`,
                    url: urlBuilder(_),
                    data: {
                        task: tasksMap[_.id],
                        lag: _.lag,
                        type: _.type
                    }
                })) : []
            }
        />
    }
})

const toNumberInputProps = (field: Metadata.Field): Dictionary<any> => {
    return {
        readOnly: field.isReadonly,
        disabled: field.isReadonly,
        placeholder: field.settings?.placeholder
    };
}

export class DictatedDates {
    startDate?: Date;
    dueDate?: Date;
}

// this functionality realized on server side too (in TaskScheduleCalculator)
export class TaskScheduleCalculator {
    public static GetDatesDictatedByPredecessor(predecessor: LoadedTask, info: IPredecessorInfo, calendarSettings: CalendarDataSet): DictatedDates {
        if (info.type === PredecessorType.FinishToStart) {
            const date = toDate(predecessor.attributes.DueDate ?? predecessor.attributes.StartDate);
            return {
                startDate: date?.clone()
                    .addWorkingDays(predecessor.attributes.Duration === 0 && date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.StartToStart) {
            const date = toDate(predecessor.attributes.StartDate ?? predecessor.attributes.DueDate);
            return {
                startDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.FinishToFinish) {
            const date = toDate(predecessor.attributes.DueDate ?? predecessor.attributes.StartDate);
            return {
                dueDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        if (info.type === PredecessorType.StartToFinish) {
            const date = toDate(predecessor.attributes.StartDate ?? predecessor.attributes.DueDate);
            return {
                dueDate: date?.clone()
                    .addWorkingDays(date.isWorkingDay(calendarSettings) ? 0 : 1, calendarSettings)
                    .addWorkingDays(info.lag, calendarSettings)
            };
        }
        return {};
    }

    public static GetDictatedDates(predecessors: IPredecessorInfo[] | undefined, tasksMap: Dictionary<LoadedTask>, calendarSettings: CalendarDataSet): DictatedDates {
        if (!predecessors) {
            return {};
        }

        const dates = predecessors.filter(_ => tasksMap[_.id])
            .map(_ => TaskScheduleCalculator.GetDatesDictatedByPredecessor(tasksMap[_.id], _, calendarSettings));
        const dictatedDates = { startDate: utils.max(dates.map(_ => _.startDate)), dueDate: utils.max(dates.map(_ => _.dueDate)) };

        if (dictatedDates.startDate && dictatedDates.dueDate && dictatedDates.startDate > dictatedDates.dueDate) {
            dictatedDates.dueDate = dictatedDates.startDate;
        }

        return dictatedDates;
    }

    public static GetLag(expectedDate: Date, currentDate: Date, calendarSettings: CalendarDataSet) {
        if (expectedDate > currentDate) {
            return utils.getWorkingDaysBetweenDates(currentDate, expectedDate, calendarSettings) - 1;
        } else if (expectedDate < currentDate) {
            return -1 * (utils.getWorkingDaysBetweenDates(expectedDate, currentDate, calendarSettings) - 1);
        }
        return 0;
    }
}

const tenMultiplier: number = 10;

export class TaskProgressCalculator {

    public static RecalculateByProgress(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode 
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || !task.attributes.Effort
            || value === null) {
            return undefined;
        }
        let multiplayer = this.pointMultiplayer(task.attributes.Effort);
        const completedWork = this.Round(value * task.attributes.Effort * multiplayer) / (multiplayer * HUNDRED_PCT);
        multiplayer = this.pointMultiplayer(task.attributes.Effort, completedWork);
        return {
            CompletedWork: completedWork,
            RemainingWork: this.Round(task.attributes.Effort * multiplayer - completedWork * multiplayer) / multiplayer,
        }
    }

    public static RecalculateByCompletedWork(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || !task.attributes.Effort
            || value === null) {
            return undefined;
        }

        if (value > task.attributes.Effort) {
            return {
                Effort: value,
                Progress: HUNDRED_PCT,
                RemainingWork: 0,
            };
        }

        const progress = this.Round(value * HUNDRED_PCT / task.attributes.Effort);
        const multiplayer = this.pointMultiplayer(task.attributes.Effort, value);
        const remainingWork = multiplayer ? this.Round(task.attributes.Effort * multiplayer - value * multiplayer) / multiplayer : 0;
        return {
            Progress: adjustProgress(progress),
            RemainingWork: remainingWork
        };
    }
    
    public static RecalculateByEffort(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode 
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || task.attributes.CompletedWork === undefined
            || value === null) {
            return undefined;
        }

        if (value < task.attributes.CompletedWork) {
            return {
                CompletedWork: value,
                Progress: HUNDRED_PCT,
                RemainingWork: 0,
            };
        }

        const multiplayer = this.pointMultiplayer(task.attributes.CompletedWork, value);
        const remainingWork = this.Round(value * multiplayer - task.attributes.CompletedWork * multiplayer) / multiplayer;
        const progress = value ? this.Round(task.attributes.CompletedWork * HUNDRED_PCT / value) : 0;
        return {
            RemainingWork: remainingWork,
            Progress: adjustProgress(progress)
        }
    }

    public static RecalculateByRemainingWork(project: ProjectInfo, task: ITask, value: number | null): Partial<ITaskAttrs> | undefined {
        if (!task.isAutoMode
            || project.settings.progressCalculationType !== ProgressCalculationType.Effort
            || task.attributes.CompletedWork === undefined
            || value === null) {
            return undefined;
        }

        const multiplayer = this.pointMultiplayer(task.attributes.CompletedWork, value);
        const effort = this.Round(task.attributes.CompletedWork * multiplayer + value * multiplayer) / multiplayer;
        const progress = effort ? this.Round(task.attributes.CompletedWork * HUNDRED_PCT / effort) : 0;
        return {
            Effort : effort,
            Progress: adjustProgress(progress)
        }
    }

    private static Round(value: number): number {
        return Math.floor(value);
    }

    private static countDecimals = (values: number[]): number =>
        Math.max.apply(null, values.map(value => Math.floor(value) === value ? 0 : value?.toString().split(".")[1].length || 0));

    private static pointMultiplayer = (...values: number[]): number =>
        Math.pow(tenMultiplier, TaskProgressCalculator.countDecimals(values));
}
import * as React from 'react';
import { IControlConfiguration, ISectionUIControlProps, UIControlHeaderProps } from "../../interfaces/ISectionUIControlProps";
import { SectionControlPlaceholder } from "../SectionPlaceholder";
import { IContextualMenuItem, PrimaryButton } from 'office-ui-fabric-react';
import { UserState, isInReadonlyMode } from '../../../../store/User';
import { ApplicationState } from '../../../../store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RoadmapItemsList, { IDataContext as IRoadmapDataContext, TRoadmap, IRoadmItemsListSettings, defaultStyleSettingValues, ControlSettings } from '../../../roadmap/RoadmapItemsList';
import { RouteComponentProps } from 'react-router';
import { Dictionary, EMPTY_GUID, EntityType, Quantization, Sections, entityTypeLabelMap } from '../../../../entities/common';
import { actionCreators as RoadmapsActionCreators } from '../../../../store/RoadmapsListStore';
import { ControlSpiner, DetailsSpinner } from '../../Spinner';
import SelectEntityPanel from '../../SelectEntityPanel';
import { ViewTypeViews } from '../../../common/ViewTypeSelect';
import { actionCreators as SectionsActionCreators } from '../../../../store/Sections';
import { LayoutService } from '../../../utils/LayoutService';
import * as analytics from '../../../../analytics';
import { CommonOperations, canReadEntity, contains } from '../../../../store/permissions';
import { mergeDefault } from '../../../../store/utils';
import { DEFAULT_ID, IWithSections } from '../../../../entities/Metadata';

export interface IDataContext {
    entityType: EntityType;
}
export interface IActions {
    updateUiControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void;
}
type ISettings = {
    linkedRoadmapId?: string;
    linkedRoadmapTitle?: string
} & IRoadmItemsListSettings;

export type IConfiguration = IControlConfiguration<IActions, ControlSettings>;
type OwnProps = ISectionUIControlProps<IActions, ISettings, {}, {}, ControlSettings, IDataContext>;

type ActionProps = {
    roadmapsActions: typeof RoadmapsActionCreators;
    sectionsActions: typeof SectionsActionCreators;
};

type Props = OwnProps & StateProps & ActionProps & RouteComponentProps<{}>;

type StateProps = {
    canConfigureSection: boolean;
    isLoading: boolean;
    user: UserState;
    roadmapDataContext: IRoadmapDataContext;
    roadmap?: TRoadmap & IWithSections;
    roadmapSectionsSettings?: Dictionary<any>;
}

type ErrorInfo = {
    error: string;
    errorDescription: string;
}

const LinkedRoadmapControl = (props: Props) => {
    const { isConfigureMode, switchToConfigureMode, onConfigureModeComplete, canConfigureSection } = props;

    const saveSettings = (linkedRoadmapId?: string, linkedRoadmapTitle?: string) => {
        analytics.trackEvent("Create Embedded Roadmap Content", props.user);
        props.actions.updateUiControl(props.sectionId, props.id, { linkedRoadmapId, linkedRoadmapTitle });
    }
    const entityTypeTitle = entityTypeLabelMap[props.datacontext.entityType].singular.toLowerCase();
    const [error, setError] = React.useState<ErrorInfo | undefined>();

    React.useEffect(() => {
        if (!props.settings.linkedRoadmapId) 
            return;
        
        setError(undefined);
        props.roadmapsActions.loadRoadmap(props.settings.linkedRoadmapId,
            (err) => err?.response?.status == 403 ?
                setError({ error: "No access to Roadmap", errorDescription: "Unfortunately, you have no access to the selected roadmap. Please contact your PPM Express app administrator to request additional permissions" }) :
                setError({ error: "Error", errorDescription: err?.message ?? "Failed to load roadmap" })
        );
        props.sectionsActions.loadSettings(props.settings.linkedRoadmapId);
    }, [props.settings.linkedRoadmapId]);

const refreshEntity = () => {
    if (props.settings.linkedRoadmapId) {
        props.roadmapsActions.loadRoadmap(props.settings.linkedRoadmapId);
    }
}

const section = props.roadmap?.sections.find(_ => _.id == Sections.RoadMapSectionId);
const roadmapControl = section?.uiControls.find(_ => _.type == "RoadmapItemsControl");

return <>
    {!props.settings.linkedRoadmapId
        ? <SectionControlPlaceholder
            key="not-configured"
            iconName="PPMXRoadmap"
            title="Roadmap section is empty"
            description={`Roadmap has not been added to the ${entityTypeTitle} yet.`}>
            {canConfigureSection && <PrimaryButton text="Select Roadmap" onClick={switchToConfigureMode} />}
        </SectionControlPlaceholder>
        :
        (
            error
                ? <SectionControlPlaceholder
                    key="not-configured"
                    iconName="PPMXRoadmap"
                    title={error.error}
                    description={error.errorDescription}>
                </SectionControlPlaceholder>
                :
                !props.roadmap
                    ? <ControlSpiner isLoading={!props.roadmap} />
                    : <RoadmapItemsList
                        {...props}
                        actions={{
                            createLane: null,
                            updateLane: null,
                            removeLane: null,
                            reorderLanes: null,
                            createLabel: null,
                            updateLabel: null,
                            removeLabel: null,
                            updateBulk: null,
                            updateRoadmapItemsDependencies: null,
                            createFromRoadmapItem: null,
                            partialUpdateUiControl: null,
                            create: null,
                            update: null,
                            remove: null,
                            updateUIControl: null,
                            refreshEntity: () => refreshEntity
                        }}
                        isViewSelected={true}
                        controlSettings={{
                            timelineMap: mergeDefault({
                                styleSettingValues: defaultStyleSettingValues,
                                quantization: !props.roadmap?.roadmapItems.length ? undefined : Quantization.quarters
                            }, mergeDefault(props.roadmapSectionsSettings?.timelineMap ?? {}, props.controlSettings?.timelineMap ?? {})),
                            viewType: ViewTypeViews.LinkedRoadmapControl.default,
                        }}
                        settings={mergeDefault(props.settings, roadmapControl?.settings ?? {})}
                        entity={props.roadmap!}
                        isConfigureMode={false}
                        datacontext={props.roadmapDataContext} />
        )}

    {isConfigureMode && <SelectEntityPanel
        searchUrl="api/roadmap/find"
        entityType={EntityType.Roadmap}
        headerDescription={`Select a roadmap related to this ${entityTypeTitle} to be added as a read-only version in this section.`}
        entityId={props.settings.linkedRoadmapId}
        entityTitle={props.settings.linkedRoadmapTitle}
        readOnly={!canConfigureSection}
        onSave={saveSettings}
        onDismiss={onConfigureModeComplete}
    />}
</>;
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        sectionsActions: bindActionCreators(SectionsActionCreators, dispatch),
        roadmapsActions: bindActionCreators(RoadmapsActionCreators, dispatch),
    };
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const section = LayoutService.getDefault(state.layouts[EntityType.Roadmap])?.sections.find(_ => _.id == Sections.RoadMapSectionId);
    const roadmapControlId = section?.uiControls.find(_ => _.type == "RoadmapItemsControl")?.id;
    const isReadOnlyMode = isInReadonlyMode(state.user, state.tenant, ownProps.entity);

    return {
        user: state.user,
        isLoading: state.roadmaps.isLoading,
        roadmapSectionsSettings: (roadmapControlId && ownProps.settings.linkedRoadmapId) ?
            (state.sections.settings[ownProps.settings.linkedRoadmapId]?.[DEFAULT_ID]?.[Sections.RoadMapSectionId]?.[roadmapControlId] ??
                state.sections.settings[ownProps.settings.linkedRoadmapId]?.[EMPTY_GUID]?.[Sections.RoadMapSectionId]?.[roadmapControlId])
            : undefined,
        roadmap: state.roadmaps.activeEntity && state.roadmaps.activeEntity.id === ownProps.settings.linkedRoadmapId ? state.roadmaps.activeEntity : undefined,
        roadmapDataContext: {
            lanes: ownProps.settings.linkedRoadmapId ? state.roadmaps.lanes.byId[ownProps.settings.linkedRoadmapId]?.items : null,
            labels: ownProps.settings.linkedRoadmapId ? state.roadmaps.labels.byId[ownProps.settings.linkedRoadmapId]?.items : null,
            readOnlyMode: true
        },
        canConfigureSection: ownProps.isViewSelected
            ? !ownProps.entity.isArchived && contains(state.user.permissions.common, CommonOperations.ConfigurationManage)
            : isReadOnlyMode || !!ownProps.entity.canConfigure
    };
}

export default connect(mapStateToProps, mergeActionCreators)(LinkedRoadmapControl);

export const withLinkedRoadmapSettings = (entity: { canConfigure?: boolean } | undefined, entityType: EntityType, user: UserState, isReadOnlyMode: boolean,
    updateUiControl: (sectionId: string, uiControlId: string, settings: Dictionary<any>) => void): IConfiguration => {
    return {
        datacontext: {
            entityType: entityType
        },
        actions: {
            updateUiControl: updateUiControl,
        },
        hideReports: true,
        buildMenuItems: (headerProps, props: OwnProps): IContextualMenuItem[] => {
            const hasAccessTo = canReadEntity(user.permissions.roadmap, user.permissions.layoutIdByRoadmapIdMap, props.settings?.linkedRoadmapId);
            const canConfigureSection = props.isViewSelected
                ? !props.entity.isArchived && contains(user.permissions.common, CommonOperations.ConfigurationManage)
                : isReadOnlyMode || !!props.entity.canConfigure
            return [{
                key: 'openroadmap',
                name: 'Open Roadmap',
                title: 'Open the roadmap in a new tab',
                iconProps: { iconName: 'OpenInNewTab' },
                disabled: !props.settings?.linkedRoadmapId || !hasAccessTo,
                target: "_blank",
                href: `/roadmap/${props.settings?.linkedRoadmapId}`,
            },
            {
                key: 'clearroadmap',
                name: 'Clear',
                title: 'Clear the selected roadmap',
                iconProps: { iconName: 'Cancel' },
                disabled: !props.settings?.linkedRoadmapId || !canConfigureSection,
                onClick: (e: any, item?: IContextualMenuItem) => {
                    updateUiControl(props.sectionId, props.id, { linkedRoadmapId: undefined, linkedRoadmapTitle: undefined });
                }
            },
            ]
        },
    }
}

import * as React from 'react';
import DatePickerInput, { DatePickerInputProps } from '../inputs/DatePickerInput';
import { IRangeFilterProps, IRangeFilterValue } from '../interfaces/IRangeFilter';
import TextInput from '../inputs/TextInput';
import InputErrorMessage from '../inputs/inputErrorMessage';
import { toDate } from '../../utils/common';
import { useDidMountEffect } from '../../utils/effects';

export type DateFilterValue = IRangeFilterValue<string>;

export const DateFilter = (props: IRangeFilterProps<DateFilterValue> & { disableAutoFocus?: boolean }) => {

    const { value = {}, onEditComplete, validator, disableAutoFocus } = props;
    return <div className="date-filter">
        <DateFilterInput
            inputProps={{ placeholder: "From" }}
            value={value.from}
            disableAutoFocus={disableAutoFocus}
            onEditComplete={_ => {
                const from = _ || undefined;
                const filterToDate = value.to && !isNaN(Date.parse(value.to)) ? toDate(value.to) : undefined;
                const filterFromDate = !!from && !isNaN(Date.parse(from)) ? toDate(from) : undefined;
                onEditComplete({
                    from,
                    to: filterFromDate
                        ? filterToDate && filterToDate < filterFromDate ? from : value.to
                        : value.to
                });
            }}
            validator={validator}
        />
        <DateFilterInput
            inputProps={{ placeholder: "To" }}
            value={value.to}
            disableAutoFocus={disableAutoFocus}
            onEditComplete={_ => {
                const to = _ || undefined;
                const filterFromDate = value.from && !isNaN(Date.parse(value.from)) ? toDate(value.from) : undefined;
                const filterToDate = !!to && !isNaN(Date.parse(to)) ? toDate(to) : undefined;
                onEditComplete({
                    from: filterToDate
                        ? filterFromDate && filterToDate < filterFromDate ? to : value.from
                        : value.from,
                    to
                });
            }}
            validator={validator}
        />
    </div>;
}

const numberPattern = `((\\+|\\-)( +)?([1-9][0-9]{0,2}|1000)d)`;
const macrosPattern = `^@today( +)?${numberPattern}?$`;
export function convertToDate(value?: string): Date | undefined {
    if (!value) {
        return undefined;
    }

    if (!isNaN(Date.parse(value))) {
        return toDate(value);
    }

    value = value.toLowerCase();
    const isMacros = new RegExp(macrosPattern).test(value);
    if (!isMacros) {
        return undefined;
    }

    const today = new Date();
    if (value == "@today") {
        return today;
    }
    const daysShiftStr: string | undefined = new RegExp(numberPattern).exec(value)?.[0];
    if (daysShiftStr && daysShiftStr.endsWith("d")) {
        const daysShift: number = Number.parseInt(daysShiftStr.substring(0, daysShiftStr.length - 1))
        return Number.isNaN(daysShift) ? today : today.addDays(daysShift);
    }
    return today;
}

export const DateFilterInput = (props: DatePickerInputProps) => {

    const [isMacros, setIsMacros] = React.useState(!!props.value?.startsWith("@"));
    const [textValue, setTextValue] = React.useState<string | null>(props.value ?? null);
    const [error, setError] = React.useState<string | undefined>(undefined);

    let _datepicker: DatePickerInput | null;

    useDidMountEffect(() => {
        if (!isMacros) {
            _datepicker?.focusDatepicker();
        }
        if (!error) {
            props.onEditComplete?.(textValue);
        }
    }, [isMacros]);

    const _setState = (value: string | null) => {
        setTextValue(value);
        const macros = !!value?.startsWith("@");
        const eror = macros && value && !new RegExp(macrosPattern).test(value.toLowerCase()) ? "Invalid macros" : undefined;
        setError(eror);
        setIsMacros(macros);
    }

    const _onEditComplete = (value: string | null) => {
        setTextValue(value);
        props.onEditComplete?.(value);
    }

    return (
        isMacros
            ? <div>
                <TextInput
                    inputProps={props.inputProps}
                    value={textValue ?? undefined}
                    onChanged={(value: string) => _setState(value)}
                    onEditComplete={_onEditComplete} />
                <InputErrorMessage text={error} />
            </div>
            : <DatePickerInput {...props}
                ref={(ref) => _datepicker = ref}
                value={textValue ?? undefined}
                disableAutoFocus={props.disableAutoFocus ?? true}
                onParseDateFromString={(value: string) => _setState(value)}
                onEditComplete={_onEditComplete} />
    );
}
import { get, post, remove } from './../fetch-interceptor';
import { AppThunkAction } from './';
import { Action, Reducer } from 'redux';
import { MetadataService, UpdateUIControlInfo, ActionsBuilder } from './services/metadataService';
import * as Metadata from "../entities/Metadata";
import { IEntityStore, StoreHelper, IDeletionResult, partialUpdate, addOrUpdateOrRemove } from './services/storeHelper';
import {
    Dictionary, ICalculation, Impact, IBaseEntity, EntityType, IWithStartFinishDates, IWithLayout, IWithImage, ISourceInfo, IWithName, IWithBenefits, IWithResourcePlan
} from "../entities/common";
import { defaultCatch } from "./utils";
import { ISubentity, Risk } from '../entities/Subentities';
import { RouterAction, push } from 'react-router-redux';
import { IWithPrioritiesAlignment } from './StrategicPrioritiesListStore';
import { ImportExportFactory } from './importExport';
import { ApplyLayout, buildLayoutFakeField, LayoutApplied } from './layouts';
import { actionsForBuilder, BaseSubentitiesUpdateAction } from './Subentity';
import { SourceType } from './ExternalEpmConnectStore';
import { ResourcePlanOperationsFactory } from './ResourcePlanListStore';

const namespace = 'IDEA';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Idea, IdeasState>(namespace, EntityType.Idea);
const resourcePlanStore = ResourcePlanOperationsFactory<Idea, IdeasState>(EntityType.Idea);

export type IdeaAttrs = IWithStartFinishDates & IWithName & IWithBenefits & {
    DiscussionUrl: string | null;
    Stage: IdeaStage;
    Budget: number;
    PlannedWork: number;
    EstimatedCost: number;
    EstimatedCharge: number;
    PlannedResourcesCount: number;
    Challenge: { id: string; name: string; };
    ChildProject: { id: string; name: string; } | null;
    Description: string;
    Benefits: string;
    BusinessPriority: Impact;
    ExpectedEfforts?: number;
    ExpectedCost?: number;
    Tags?: string[];
}
type IdeaAttributesModel = IBaseEntity & {
    attributes: IdeaAttrs;
};

export enum IdeaStage {
    Draft = 0,
    Proposed = 1,
    Active = 2,
    Selected = 3,
    NotSelected = 4,
    Archived = 5
}

export interface IdeaStageConfig { title: string, cssClassName: string, iconName?: string }
export const ideaStagesMap: { [i: number]: IdeaStageConfig } =
{
    [IdeaStage.Draft]: {
        title: "Draft",
        cssClassName: "Draft",
    },
    [IdeaStage.Proposed]: {
        title: "Proposed",
        cssClassName: "Proposed",
    },
    [IdeaStage.Active]: {
        title: "Active",
        cssClassName: "Active"
    },
    [IdeaStage.Selected]: {
        title: "Selected",
        cssClassName: "Selected",
        iconName: "PPMXSelected"
    },
    [IdeaStage.NotSelected]: {
        title: "Not Selected",
        cssClassName: "NotSelected"
    },
    [IdeaStage.Archived]: {
        title: "Archived",
        cssClassName: "Archived"
    }
}

const VOTES_FAKE_FIELD_NAME: string = 'Votes';
export const fakeFields: Metadata.Field[] = [
    {
        id: '0c673b51-411e-445c-b36e-78e72ac726ec',
        type: Metadata.FieldType.Integer,
        name: VOTES_FAKE_FIELD_NAME,
        label: 'Votes',
        isNative: false,
        isCustom: false,
        isFake: true,
        isReadonly: true,
        isSystem: true,
        group: Metadata.FieldGroup.SystemFields,
        settings: {
            views: {
                list: {
                    componentPath: "idea/Votes",
                    maxWidth: 150,
                    minWidth: 150
                }
            }
        }
    },
    buildLayoutFakeField('3a79ca36-e940-4578-ab41-256c9a1d8ac7')
];

export interface Idea extends ISubentity, IWithLayout, IWithImage, IWithPrioritiesAlignment, IWithResourcePlan, Metadata.IWithSections, Metadata.IWithPinnedViews {
    attributes: IdeaAttrs & Dictionary<any>;
    risks: Risk[];
    calculation: ICalculation;
    resourceIds: string[];
    votes: number;
    isCurentUserVoted: boolean;
    isEditable: boolean;
    canConfigure: boolean;
    canCollaborate: boolean;
    canEditChallenge: boolean;

    isProcessing: boolean;
    sourceInfos: ISourceInfo[];
}

export interface IIdeaInfo {
    id: string;
    name: string;
}

export interface IdeasState extends IEntityStore<Idea> {
    isLoading: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];
}

const unloadedState: IdeasState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isUpdatingSections: false
}

interface LoadIdeasAction {
    type: 'LOAD_IDEAS';
}

interface IdeasLoadedAction {
    type: 'IDEAS_LOADED';
    ideas: Idea[];
}

export interface IdeasLoadedPartAction {
    type: 'RECEIVED_IDEAS_PART';
    ideas: Idea[];
}

export interface ReceivedDeleteIdeasResultAction {
    type: 'RECEIVED_REMOVE_IDEAS_RESULT';
    deletionResult?: IDeletionResult[];
}

export interface CreateIdeaSuccessAction {
    type: 'CREATE_IDEA_SUCCESS';
    idea: Idea;
}

interface LoadIdeaAction {
    type: 'LOAD_IDEA';
    id: string;
}

interface IdeaLoadedAction {
    type: 'IDEA_LOADED';
    idea: Idea;
}

interface ReceivedIdeaAttributesAction {
    type: "RECEIVED_IDEA_ATTRIBUTES";
    data: IdeaAttributesModel;
}

interface UpdateIdeaRisksAction extends BaseSubentitiesUpdateAction<Risk> {
    type: "UPDATE_IDEA_RISKS";
}

interface UpdatingSectionsAction {
    type: 'UPDATING_IDEA_SECTIONS';
    ideaId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_IDEA_SECTION_SUCCESS';
    ideaId: string;
    sections: Metadata.Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_IDEA_PINNED_VIEWS_SUCCESS';
    ideaId: string;
    pinnedViews: string[];
}

export interface CreateIdeaAction {
    type: 'CREATE_IDEA';
}

interface UpdateIdeaUIControlAction {
    type: 'UPDATE_IDEA_UICONTROL_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

interface UpdateImageAction {
    type: 'UPDATE_IDEA_IMAGE';
    ideaId: string;
    imageId?: string;
}

interface UpdateCalculationAction {
    type: 'UPDATE_IDEA_CALCULATION';
    ideaId: string;
    calculation: ICalculation;
    updates: Dictionary<any>;
}

interface UpdateVotesAction {
    type: 'UPDATE_IDEA_VOTES';
    ideaId: string;
    votes: number;
    isCurentUserVoted: boolean;
}

interface IdeaProcessingAction {
    type: 'IDEA_PROCESSING';
    ideaId: string;
}

type KnownAction = LoadIdeasAction
    | IdeasLoadedAction
    | IdeasLoadedPartAction
    | ReceivedDeleteIdeasResultAction
    | CreateIdeaSuccessAction
    | LoadIdeaAction
    | IdeaLoadedAction
    | ReceivedIdeaAttributesAction
    | UpdateIdeaRisksAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | CreateIdeaAction
    | UpdateIdeaUIControlAction
    | UpdateImageAction
    | UpdateCalculationAction
    | UpdateVotesAction
    | IdeaProcessingAction;

const actionsFor = actionsForBuilder<KnownAction>(EntityType.Idea);
export const defaultActionCreators = {
    loadIdeasByIds: (ideaIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Idea[]>(`api/idea/get`, { ids: ideaIds })
            .then(data => dispatch({ type: 'RECEIVED_IDEAS_PART', ideas: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'LOAD_IDEAS' }));
    },
    loadIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Idea>(`api/idea/${ideaId}`)
            .then(data => dispatch(({ type: 'IDEA_LOADED', idea: data }) as any))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'LOAD_IDEA', id: ideaId }));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_IDEAS_RESULT" });
    },
    updateIdeaAttributes: (ideaId: string, newAttributeValues: Dictionary<any>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<IdeaAttributesModel>(`api/idea/${ideaId}/attributes`, newAttributeValues)
                .then(data => dispatch({ type: 'RECEIVED_IDEA_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    updatePriorityAlignment: (ideaId: string, strategicPriorityId: string, impact: Impact):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Idea>(`api/idea/${ideaId}/priorityAlignments`, { strategicPriority: { id: strategicPriorityId }, impact: impact })
                .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
                .catch(defaultCatch(dispatch));
        },
    recalculateAlignmentScore: (ideaId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<Idea>(`api/idea/${ideaId}/recalculateAlignmentScore`, {})
                .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
                .catch(defaultCatch(dispatch));
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/idea`,
        (ideaId, sections, dispatch) => dispatch({
            type: 'UPDATE_IDEA_SECTION_SUCCESS',
            ideaId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((ideaId, sections, dispatch) => dispatch({
        type: 'UPDATE_IDEA_SECTION_SUCCESS',
        ideaId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/idea`,
        (ideaId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_IDEA_PINNED_VIEWS_SUCCESS',
            ideaId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/idea`,
        (uiControlInfo, dispatch) => dispatch(<UpdateIdeaUIControlAction>{
            type: 'UPDATE_IDEA_UICONTROL_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateIdeaUIControlAction>{
        type: 'UPDATE_IDEA_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Idea),
    applyLayout: (ideaId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Idea>(`api/idea/${ideaId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'IDEA_LOADED', idea: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Idea });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Idea });
    },
    bulkApplyLayout: (ideaIds: string[], layoutId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Idea[]>(`api/idea/applyLayout/${layoutId}`, { ids: ideaIds })
            .then(data => {
                dispatch({ type: 'RECEIVED_IDEAS_PART', ideas: data });
            })
            .catch(defaultCatch(dispatch));
    },
    updateCalculation: (ideaId: string, changes: Partial<ICalculation>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{calculation: ICalculation, updates: Dictionary<any>}>(`api/idea/${ideaId}/calculation`, changes)
            .then(_ => dispatch({ type: 'UPDATE_IDEA_CALCULATION', ideaId, ..._ }))
            .catch(defaultCatch(dispatch));
    },
    updateImage: (ideaId: string, image: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', image);
        post<{ imageId: string }>(`api/idea/${ideaId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_IDEA_IMAGE', imageId: _.imageId, ideaId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/idea/${ideaId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_IDEA_IMAGE', imageId: undefined, ideaId }))
            .catch(defaultCatch(dispatch));
    },
    changeVote: (ideaId: string, isVote: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Idea>(`api/idea/${ideaId}/vote/${isVote}`, {})
            .then(_ => dispatch({ type: 'UPDATE_IDEA_VOTES', ideaId, votes: _.votes, isCurentUserVoted: _.isCurentUserVoted }))
            .catch(defaultCatch(dispatch));
    },
    proposeIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/propose`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    activateIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/activate`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    selectIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/select`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    rejectIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/reject`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    reactivateIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/reactivate`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    deactivateIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/deactivate`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    markAsDraftIdea: (ideaId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Idea>(`api/idea/${ideaId}/markAsDraft`, {})
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    initiateProject: (ideaId: string): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        dispatch({ type: 'IDEA_PROCESSING', ideaId });
        post<Idea>(`api/idea/${ideaId}/initiateProject`, {})
            .then(data => {
                dispatch({ type: 'IDEA_LOADED', idea: data });
                dispatch({ type: 'IDEA_PROCESSING', ideaId });
                dispatch(push(`/project/${data.attributes.ChildProject!.id}`));
            })
            .catch(defaultCatch(dispatch));
    },
    linkToFile: (ideaId: string, fileUrl: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Idea>(`api/idea/${ideaId}/link/file`, { fileUrl: fileUrl })
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    deleteExternalSystemLink: (ideaId: string, connectionId: string, sourceType: SourceType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Idea>(`api/idea/${ideaId}/link/${sourceType}/${connectionId}`)
            .then(data => dispatch({ type: 'IDEA_LOADED', idea: data }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<Risk>().create('Risk', _ => ({ type: 'UPDATE_IDEA_RISKS', ..._ }))
};

export const defaultReducer: Reducer<IdeasState> = (state: IdeasState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    state = state || unloadedState;
    switch (action.type) {
        case 'LOAD_IDEAS':
        case 'CREATE_IDEA':
            return {
                ...state,
                isLoading: true
            };

        case 'IDEAS_LOADED':
            return {
                ...state,
                ...StoreHelper.create(action.ideas),
                isLoading: false
            };

        case 'RECEIVED_IDEAS_PART':
            return {
                ...state,
                ...StoreHelper.union(state, action.ideas),
                isLoading: false
            };

        case 'LOAD_IDEA':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id == action.id ? state.activeEntity : undefined,
                isLoading: true
            };

        case 'RECEIVED_REMOVE_IDEAS_RESULT':
            let newState = { ...state };
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };

        case 'CREATE_IDEA_SUCCESS':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.idea),
                activeEntityId: action.idea.id,
                activeEntity: action.idea,
                isLoading: false
            };

        case 'IDEA_LOADED':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.idea),
                activeEntity: state.activeEntityId === action.idea.id ? action.idea : state.activeEntity,
                isLoading: false
            };

        case 'RECEIVED_IDEA_ATTRIBUTES':
            return {
                ...state,
                activeEntity: state.activeEntityId === action.data.id && state.activeEntity
                    ? { ...state.activeEntity, attributes: action.data.attributes }
                    : state.activeEntity
            };

        case 'UPDATE_IDEA_RISKS':
            return StoreHelper.applyHandler(state, action.entityId,
                (idea: Idea) => partialUpdate(idea, { risks: addOrUpdateOrRemove(idea.risks, action.addOrUpdate, action.remove) }));

        case 'UPDATING_IDEA_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };

        case 'UPDATE_IDEA_SECTION_SUCCESS':
            return {
                ...StoreHelper.applyHandler(state,
                    action.ideaId,
                    (idea: Idea) => partialUpdate(idea, { sections: action.sections })),
                isUpdatingSections: false
            };
        case 'UPDATE_IDEA_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.ideaId,
                        (idea: Idea) => Object.assign({}, idea, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_IDEA_UICONTROL_SUCCESS':
            return StoreHelper.applyHandler(state, action.uiControlInfo.entityId, (idea: Idea) => MetadataService.UpdateUIControlSettings(idea, action.uiControlInfo));

        case 'UPDATE_IDEA_IMAGE':
            return StoreHelper.applyHandler(state, action.ideaId, (idea: Idea) => partialUpdate(idea, { imageId: action.imageId }));

        case 'UPDATE_IDEA_CALCULATION':
            return StoreHelper.applyHandler(state, action.ideaId, 
                (idea: Idea) => partialUpdate(idea, { calculation: action.calculation, attributes: { ...idea.attributes, ...action.updates } }));

        case 'UPDATE_IDEA_VOTES':
            return StoreHelper.applyHandler(state, action.ideaId, (idea: Idea) => partialUpdate(idea, { votes: action.votes, isCurentUserVoted: action.isCurentUserVoted }));

        case 'IDEA_PROCESSING':
            return StoreHelper.applyHandler(state, action.ideaId, (idea: Idea) => partialUpdate(idea, { isProcessing: true }));

        default: const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

export const reducer: Reducer<IdeasState> = (state: IdeasState, incomingAction: Action) => {
    state = state || unloadedState;
    return {
        ...defaultReducer(
            resourcePlanStore.reducer(
                importExportReducer(state, incomingAction),
                incomingAction),
            incomingAction)
    }
}

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators,
    ...resourcePlanStore.actionCreators
};
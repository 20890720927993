import React from 'react';
import { IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import { ExtensionInfo, ExtensionPageLocation, findExtension } from '../../store/ExtensionStore';
import { EntityType, entityTypeLabelMap, IBaseEntity } from '../../entities/common';
import ExtensionPanel from './ExtensionPanel';
import { buildExtensionsMenuItem } from './headerMenuItemBuilders';
import SharePanel from './SharePanel';
import { ProjectInfo } from '../../store/ProjectsListStore';
import { Portfolio } from '../../store/PortfoliosListStore';
import { Program } from '../../store/ProgramsListStore';
import { Roadmap } from '../../store/roadmap/common';
import { Challenge } from '../../store/ChallengesListStore';
import { LayoutsState } from '../../store/layouts';
import { notUndefined } from '../utils/common';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

type OwnProps = {
    entity: IBaseEntity & (ProjectInfo | Portfolio | Program | Roadmap | Challenge);
    entityType: EntityType;
    items: IContextualMenuItem[];
    disabled?: boolean;
}

type StateProps = {
    extensions: ExtensionInfo[];
    layouts: LayoutsState;
}

type Props = OwnProps & StateProps;

const EntityHeaderMoreMenu = (props: Props) => {
    const { entity, entityType, extensions, layouts, disabled } = props;
    const [isSharePanelOpen, setIsSharePanelOpen] = React.useState(false);
    const [isExtensionPanelOpen, setIsExtensionPanelOpen] = React.useState<ExtensionInfo>();
    const menuItems = [
        entityType !== EntityType.Idea && !(entity as ProjectInfo)?.isArchived ? {
            key: 'share',
            iconProps: { iconName: 'Share' },
            name: 'Share',
            disabled: !entity.isEditable,
            onClick: () => {
                setIsSharePanelOpen(true);
                analytics.track(`Share ${entityTypeLabelMap[entityType].singular}`);
            }
        } : undefined,
        entity.isEditable ? buildExtensionsMenuItem(extensions, setIsExtensionPanelOpen) : undefined,
        ...props.items
    ].filter(notUndefined);

    return <>
        <IconButton
            menuIconProps={{ iconName: 'More' }}
            className='menu'
            text='More'
            disabled={disabled}
            menuProps={{ items: menuItems, className: 'section-menu' }} />
        {isSharePanelOpen && <SharePanel
            key="share-panel"
            entity={entity}
            entityType={entityType}
            layouts={layouts}
            hideView={entityType === EntityType.Challenge}
            hideCollaborate={entityType === EntityType.Roadmap}
            onDismiss={() => setIsSharePanelOpen(false)} />
        }
        {isExtensionPanelOpen && <ExtensionPanel
            entityType={entityType}
            info={isExtensionPanelOpen}
            onDismiss={() => setIsExtensionPanelOpen(undefined)}
            context={{ entityId: entity.id, entityType }}
            urlParams={{ entityId: entity.id }}
        />}
    </>
}


function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        layouts: state.layouts[ownProps.entityType],
        extensions: findExtension(state.extensions, ownProps.entityType, ExtensionPageLocation.EntityAction)
    }
}

export default connect(mapStateToProps)(EntityHeaderMoreMenu);
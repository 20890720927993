import * as React from 'react';
import { CheckboxVisibility, CommandBar, DetailsListLayoutMode, IColumn, IContextualMenuItem, Selection } from 'office-ui-fabric-react';
import { ApplicationState } from '../../../../store';
import { ExtensionInfo, actionCreators } from '../../../../store/ExtensionStore';
import DetailsListWrapper from '../../../common/DetailsListWrapper';
import { ControlSpiner } from '../../../common/Spinner';
import RemoveDialog from '../../../common/RemoveDialog';
import { connect } from 'react-redux';
import EntityName from '../../../views/list/columns/EntityName';
import ExtensionEdit, { locationGroups, locationOptions, typeOptions } from './ExtensionEdit';
import { nameof } from '../../../../store/services/metadataService';
import { TextFormatter } from '../../../common/formatters/TextFormatter';

type StateProps = { extensions: ExtensionInfo[], isLoading?: boolean }
type Props = StateProps & typeof actionCreators;

const ExtensionsSettings = (props: Props) => {
    const canManage = true;
    const getCommands = (): IContextualMenuItem[] => [
            {
                key: 'addRow',
                name: `New Extension`,
                iconProps: { iconName: 'Add' },
                disabled: !canManage,
                onClick: () => { setShowPanel(true); setExtension(undefined); }
            },
            {
                key: 'updateRow',
                name: `Edit Extension`,
                iconProps: { iconName: 'Edit' },
                disabled: !canManage || selectedCount !== 1,
                onClick: () => { setShowPanel(true); setExtension(selection.getSelection()[0]); }
            },
            {
                key: 'deleteRow',
                name: `Delete Extension`,
                iconProps: { iconName: 'Delete' },
                disabled: !canManage || selection.getSelection().length === 0,
                onClick: () => { setShowRemoveDialog(true) }
            }
        ];
    const buildColumns = (): IColumn[] => {
        return [
            {
                key: nameof<ExtensionInfo>('name'),
                fieldName: nameof<ExtensionInfo>('name'),
                name: "Title",
                minWidth: 100,
                maxWidth: 300,
                isResizable: true,
                onRender: (entity: ExtensionInfo) => <EntityName
                    className='hover-underline'
                    iconName="Puzzle"
                    name={entity.name}
                    onClick={() => { setShowPanel(true); setExtension(entity); }} />
            },
            {
                key: nameof<ExtensionInfo>("type"),
                fieldName: nameof<ExtensionInfo>("type"),
                name: "Type",
                minWidth: 100,
                maxWidth: 400,
                isResizable: true,
                onRender: (entity: ExtensionInfo) => {
                    const selectedOption = typeOptions.find(opt => entity.type === opt.key)?.text;
                    return <span className="overflow-text">
                        <TextFormatter value={selectedOption} />
                    </span>
                }
            },
            {
                key: nameof<ExtensionInfo>("location"),
                fieldName: nameof<ExtensionInfo>("location"),
                name: "Location",
                minWidth: 100,
                maxWidth: 400,
                isResizable: true,
                onRender: (entity: ExtensionInfo) => {
                    const selectedOption = locationOptions.find(opt => entity.location === opt.key);
                    return <span className="overflow-text">
                        <TextFormatter value={selectedOption?.groupKey ? `${locationGroups[selectedOption.groupKey]} - ${selectedOption.text}` : selectedOption?.text} />
                    </span>
                }
            }
        ]
    }

    const [selectedCount, setSelectedCount] = React.useState(0);
    const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>();
    const [showPanel, setShowPanel] = React.useState<boolean>();
    const [extension, setExtension] = React.useState<ExtensionInfo>();

    const selection = React.useMemo(() => {
        const selection: Selection<ExtensionInfo> = new Selection<ExtensionInfo>({
            getKey: _ => _.id,
            onSelectionChanged: () => setSelectedCount(selection.getSelectedCount()),
        });
        return selection;
    }, []);

    return <ControlSpiner isLoading={!!props.isLoading} className="show-over">
        <div className="report-management">
            {showPanel && <ExtensionEdit
                extension={extension}
                onSave={props.saveExtension}
                onDismiss={() => setShowPanel(false)}
            />}
            <div className="entities-list">
                <CommandBar className="header-command-bar" items={getCommands()} />
                <DetailsListWrapper
                    layoutMode={DetailsListLayoutMode.justified}
                    items={props.extensions}
                    columns={buildColumns()}
                    selection={selection as Selection}
                    checkboxVisibility={canManage ? CheckboxVisibility.always : CheckboxVisibility.hidden} />
            </div>
            {showRemoveDialog && <RemoveDialog
                onClose={() => setShowRemoveDialog(false)}
                onComplete={() => {
                    props.removeExtension(selection.getSelection().map(_ => _.id));
                    setShowRemoveDialog(false);
                }}
                dialogContentProps={{
                    title: `Delete Extension${selectedCount > 1 ? "s" : ""}`,
                    subText: selectedCount === 1
                        ? `Are you sure you want to delete Extension "${selection.getSelection()[0].name}"?`
                        : `Are you sure you want to delete ${selectedCount} Extensions?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
            }
        </div>
    </ControlSpiner>;
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.user,
        extensions: state.extensions.entities,
        isLoading: state.extensions.isLoading
    };
}

export default connect(mapStateToProps, actionCreators)(ExtensionsSettings);
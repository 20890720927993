import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import TextInput from './TextInput';
import DropdownInput from "./DropdownInput";
import ToggleOptionInput from "./ToggleOptionInput";
import NumberInput from "./NumberInput";
import SliderInput from "./SliderInput";
import EnumSliderInput from "./EnumSliderInput";
import { isLockedField } from "../../utils/common";
import { UserState } from '../../../store/User';
import { TenantState } from '../../../store/Tenant';
import { FieldInputElementProps } from '../interfaces/IInputProps';
import { FieldsService } from '../FieldsService';

const IntegerFieldInputElement = (props: FieldInputElementProps & { user?: UserState, tenant?: TenantState }) => {
    const { field, entityType, validator, user, tenant } = props;

    if (field.settings) {
        if (field.settings.editControl === "EnumSlider") {
            const sliderProps = {
                options: field.settings?.options,
                readOnly: field.isReadonly,
                className: field.settings?.className
            };
            return <EnumSliderInput {...props} inputProps={sliderProps} />;
        }

        if (field.settings.editControl === "Slider") {
            const sliderProps = {
                min: field.settings?.minValue,
                max: field.settings?.maxValue,
                step: field.settings?.step,
                defaultValue: field.settings?.defaultValue,
                className: field.settings?.className
            };

            return <SliderInput {...props} inputProps={sliderProps} validator={validator} />;
        }

        if (FieldsService.isDropDown(field)) {
            const dropdownProps = { options: Metadata.getOptions(field), readOnly: field.isReadonly };
            return <DropdownInput {...props} inputProps={dropdownProps} />;
        }

        if (field.settings.editControl === "ToggleOption") {
            const toggleProps = {
                options: Metadata.getOptions(field)
            }
            return <ToggleOptionInput {...props} inputProps={toggleProps} />;
        }

        if (isLockedField(field.settings.format, field.name, user, tenant, entityType)) {
            return <TextInput value="N/A" disabled />;
        }
    }

    const inputProps = {
        readOnly: field.isReadonly,
        placeholder: field.settings?.placeholder
    };

    return <NumberInput {...props} inputProps={inputProps} validator={validator} format={field.settings?.format} />;
}

export default IntegerFieldInputElement;
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ControlSpiner } from "../common/Spinner";
import SelectionExt from "../common/SelectionExt";
import {
    Dictionary,
    EntityType,
    IExtensibleEntity,
    Quantization,
    ServerEntityType,
    UserPreferencesSettingsUpdate
} from "../../entities/common";
import { PersistTimelineList } from "../common/extensibleEntity/EntityTimelineList";
import { IScaleTimelineSegment } from "../common/timeline/TimelineSegment";
import { IRow } from "../common/timeline/TimelineList";
import { ScaleRenderMode } from "../common/timeline/TimelineBody";
import TotalTimeTrackingCell, {getTimeTrackinLines } from "./cells/TotalTimeTrackingCell";
import TimeTrackingCell from "./cells/TimeTrackingCell";
import { connect } from "react-redux";
import { ClearObject, distinct, formatValue, notUndefined } from "../utils/common";
import {
    CreateTimeTrackingEntryAttr, ShownInterval, TimeTrackingEntry, TimeTrackingEntryAttr,
    TimeTrackingLine, IsLockedPeriod, CopyBillableAndCostType, TimeTrackingEntryProject, TimeTrackingTaskDetails,
    actionCreators as TimeTrackingActionCreators,
    TimeTrackingProjectDetails,
    IsEnterpriseTimeTracker,
    GetCostType
} from "../../store/TimeTrackingStore";

import { ApplicationState } from "../../store";
import TimeTrackingMenu from "./TimeTrackingMenu";
import EditTimeTrackingEntry, { TimeType } from "./EditTimeTrackingEntry";
import { IScale, TimelineScale } from "../common/timeline/TimelineScale";
import { CheckboxVisibility, IObjectWithKey } from "office-ui-fabric-react";
import { getDaysBetweenDates, getWorkingHoursBetweenDates } from "../common/timeline/utils";
import { CalendarDataSet } from "../../store/CalendarStore";
import { Resource, ResourceCalendar, calculateResourceCalendar } from "../../store/ResourcesListStore";
import "./timeTrackingGrid.css";
import { Field, FormatType } from "../../entities/Metadata";
import { TimeTrackingAdministrativeCategory, TimeTrackingGlobalSettings } from "../../store/Tenant";
import { MyWork } from "../../store/MyWorkStore";
import { nameof } from "../../store/services/metadataService";
import TimeTrackingCellTooltip from "./tooltips/cell/TimeTrackingCellTooltip";
import TimeTrackingTimeFrameSelector from "./TimeTrackingTimeFrameSelector";
import { PreferenceUpdates } from "../../store/services/viewSaver";
import ImportTimeTrackingTasksPanel from "./ImportTimeTrackingTasksPanel";
import EditCustomTimeTrackingEntry from "./EditCustomTimeTrackingEntry";
import TimeTrackingLineEntityName from "./TimeTrackingLineEntityName";
import TimeTrackingItemMenu from "./TimeTrackingItemMenu";
import {
    BuildAssignmentSuggestionTimeTrackingLines,
    BuildEntriesTimeTrackingLines,
    BuildLineGroup, BuildProjectLines, BuildResourcePlanSuggestionTimeTrackingLines, ControlSettings, MergeTimeTrackingLines, NameField,
    TotalReportedField, GetNewEntryDate, useDebounceActions, usePeriodAndTimeFrame, useResourcePlanProjects, useSuggestedTasksDetails,
    ShowResourcePlanSmartSuggestion,
    useSuggestedProjectsDetails
} from "./common";
import { bindActionCreators } from "redux";
import { isInReadonlyMode } from "../../store/User";
import { addOrUpdateOrRemove } from "../../store/services/storeHelper";

type OwnProps = {
    resource: Resource | undefined;
    resourceCalendar?: ResourceCalendar;
    work: MyWork[];
    controlSettings: ControlSettings;
    onSaveSettings?: (update: UserPreferencesSettingsUpdate, sendToServer?: boolean) => void;
};

type StoreProps = {
    entries: TimeTrackingEntry[];
    isLoading: boolean;
    globalCalendar: CalendarDataSet;
    administrativeCategories: TimeTrackingAdministrativeCategory[];
    settings: TimeTrackingGlobalSettings,
    shownInterval?: ShownInterval;
    tasks: TimeTrackingTaskDetails[];
    projects: TimeTrackingProjectDetails[];
    isReadonlyMode: boolean;
    isEnterpriseTimeTracker: boolean;
    showResourcePlanSmartSuggestion: boolean;
};

type ActionProps = {
    timeTrackingActions: typeof TimeTrackingActionCreators;
};

type Props = StoreProps & OwnProps & ActionProps;

export interface IMaybeTotal {
    isTotal?: true;
}

export interface WithEntityType {
    entityType?: EntityType;
}

const _totalRow: IExtensibleEntity & IMaybeTotal = {
    id: "totalRow",
    attributes: {
        Name: "Total Reported"
    },
    isTotal: true
};

export * from './common';

const TimeTrackingGrid = (props: Props) => {

    const { entries, isLoading, globalCalendar, resourceCalendar, resource, administrativeCategories, work,
        timeTrackingActions, onSaveSettings, controlSettings, shownInterval, settings, isReadonlyMode, isEnterpriseTimeTracker, showResourcePlanSmartSuggestion } = props;

    const enabledAssignmentSmartSuggestions = settings.enabledAssignmentSmartSuggestions;
    const enablaResourcePlanSmartSuggestion = settings.enablaResourcePlanSmartSuggestion && showResourcePlanSmartSuggestion;

    
    const cellByIndexMap = useRef<Dictionary<any>>({}).current;
    const cellPositionToIndexMap = useRef<Dictionary<number>>({}).current;

    const cellSelection = useRef<SelectionExt>(new SelectionExt()).current;

    const [modifiedEntry, setModifiedEntry] = useState<{ entry?: TimeTrackingEntry, timeType?: TimeType, project?: TimeTrackingProjectDetails }>({});

    const [cellIndexMap, setCellIndexMap] = useState<CellIndexesByRowByDateMap>({});
    const [cellsInRow, setCellsInRow] = useState(0);
    const [totalCells, setTotalCells] = useState(0);
    const isCellEditingStarted = useRef(false);

    const { suggestedTasks, loadSuggestedTasks } = useSuggestedTasksDetails();
    const { suggestedProjects, loadSuggestedProjects } = useSuggestedProjectsDetails();
    
    const tasks = useMemo(() => addOrUpdateOrRemove(props.tasks, suggestedTasks), [props.tasks, suggestedTasks])
    const projects = useMemo(() => addOrUpdateOrRemove(props.projects, suggestedProjects), [props.projects, suggestedProjects]);

    const { period: gridPeriod, timeFrame } = usePeriodAndTimeFrame(props.controlSettings);

    const isSettingsLoaded = !!onSaveSettings;
    
    const mergeSuggestTimeEntries = useMemo(() => {
        return controlSettings.suggestTimeEntries ?? true;
    }, [controlSettings.suggestTimeEntries]);

    const resourcePlanProjects = useResourcePlanProjects(resource, timeFrame, mergeSuggestTimeEntries && enablaResourcePlanSmartSuggestion);

    const { debouncedCreate, debouncedUpdate } = useDebounceActions(resource, timeTrackingActions);

    const resourceCalendarDataSet = useMemo(() => {
        if (!globalCalendar) {
            return undefined;
        }

        return calculateResourceCalendar(resourceCalendar, globalCalendar);
    }, [resourceCalendar, globalCalendar]);
    

    const { lines, projectLines } = useMemo(
        () => {
            const entryLines = BuildEntriesTimeTrackingLines(entries, administrativeCategories, timeFrame, tasks, projects, gridPeriod);
            const assignmentSuggestions = mergeSuggestTimeEntries && enabledAssignmentSmartSuggestions && work.length
                ? BuildAssignmentSuggestionTimeTrackingLines(work, timeFrame, tasks, projects, gridPeriod)
                : [];
            
            const resourcePlanSuggestions = mergeSuggestTimeEntries && enablaResourcePlanSmartSuggestion
                ? BuildResourcePlanSuggestionTimeTrackingLines(resourcePlanProjects, timeFrame, gridPeriod)
                : [];
            
            const mergedLines = MergeTimeTrackingLines(entryLines, assignmentSuggestions, resourcePlanSuggestions);
            
            const projectLines = BuildProjectLines(mergedLines);

            return { lines: mergedLines, projectLines };
        },
        [resource, entries, work, mergeSuggestTimeEntries, administrativeCategories, tasks, projects, resourcePlanProjects]
    );

    const buildRow = useCallback((_: IExtensibleEntity) => BuildLineGroup(_, projectLines, lines), [lines, projectLines]);

    useEffect(() => {
        if (lines.length && !isLoading && isSettingsLoaded) {

            const suggestedLines = lines.filter(_ => _.attributes.IsSuggestion);
            const taskIds = _getTaskIds(suggestedLines).filter(_ => !tasks.some(t => t.id === _));

            loadSuggestedTasks(taskIds);

            const suggestedLineProjectIds = suggestedLines.map(_ => _.attributes.Project.id).filter(distinct);
            const projectIdsToLoad = suggestedLineProjectIds.filter(_ => !projects.some(p => p.id === _));
           
            loadSuggestedProjects(projectIdsToLoad);
        }
    }, [lines.filter(_ => _.attributes.IsSuggestion).map(_ => _.attributes.Task.id).join(), isLoading, isSettingsLoaded]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const entityIds = lines.map(_ => _.id);

        const scale = TimelineScale.build({ start: timeFrame.start, end: timeFrame.end.getEndOfDay() }, Quantization.days);
        const newCellIndexMap = _buildCellIndexMap(entityIds, scale.scale);

        cellSelection.setItems(newCellIndexMap.endToEndItems, false);

        const newCellsInRow = Object.keys(newCellIndexMap.map).length > 0 ? Object.keys(newCellIndexMap.map[Object.keys(newCellIndexMap.map)[0]]).length : 0;

        clearCellMaps();
        setCellIndexMap(newCellIndexMap.map);
        setCellsInRow(newCellsInRow);
        setTotalCells(newCellIndexMap.endToEndItems.length);
    }, [lines, isLoading]);

    const totalRow = useMemo(() => {
        const totalReported = lines.reduce((sum, current) => sum + (current.attributes.TotalReported), 0);
        return {
            ..._totalRow,
            attributes: {
                ..._totalRow.attributes,
                TotalReported: totalReported
            }
        }
    }, [lines]);

    useEffect(() => {
        if (shownInterval) {
            onSaveSettings?.(PreferenceUpdates.qunatization(shownInterval.period));
            onSaveSettings?.(PreferenceUpdates.timeframe(shownInterval.timeFrame));
        }
    }, [shownInterval])

    useEffect(() => {
        if (!isSettingsLoaded || !resource) {
            return;
        }

        timeTrackingActions.loadTimeEntries(resource.id, timeFrame.start, timeFrame.end);
        cellSelection.setAllSelected(false);

    }, [resource, timeFrame.start.toDateOnlyString(), timeFrame.end.toDateOnlyString(), isSettingsLoaded]);

    const clearCellMaps = () => {
        ClearObject(cellByIndexMap);
        ClearObject(cellPositionToIndexMap);
    };

    const selectCellByIndex = (idx: number, preserveSelection: boolean) => {
        const select = !cellSelection.isIndexSelected(idx);

        if (!preserveSelection) {
            cellSelection.getSelectedIndices().forEach(_ => {
                cellSelection.setIndexSelected(_, false, true);
            });
        }

        cellSelection.setIndexSelected(idx, select, true);
    };
    
    const navigateToCellByIndex = useCallback(
        (idx: number, preserveSelection: boolean) => {
            cellByIndexMap[idx]?.focus();
            selectCellByIndex(idx, preserveSelection);
        },
        [cellByIndexMap]
    );

    const isGridReady = isSettingsLoaded;
    const isFullyLocked = IsLockedPeriod(timeFrame.end, settings.timeReportingLock);

    let cellPosition = 0;

    return (
        <div className="time-tracking-grid">
            <ControlSpiner isLoading={isLoading} className="show-over">
                {
                    isGridReady &&
                    <>
                        <TimeTrackingMenu
                            isReadonly={isReadonlyMode || isFullyLocked || !resource}
                            onImportTasks={() => setModifiedEntry({ timeType: TimeType.ProjectTask })}
                            onAddAdministrative={() => setModifiedEntry({ timeType: TimeType.Administrative })}
                            onAddCustom={() => setModifiedEntry({ timeType: TimeType.CustomEvent })}
                            mergeSmartSuggestions={mergeSuggestTimeEntries}
                            setMergeSmartSuggestions={_ => onSaveSettings?.({
                                parentSettingsPathKeys: [],
                                valueBySettingNameMap: {
                                    [nameof<ControlSettings>("suggestTimeEntries")]: _
                                }
                            })}
                            onFieldsUpdate={() => {
                                resource && timeTrackingActions.loadTimeEntries(resource.id, timeFrame.start, timeFrame.end);
                            }}
                            showMergeSmartSuggestions={enabledAssignmentSmartSuggestions || enablaResourcePlanSmartSuggestion}
                        />

                
                        <PersistTimelineList
                            key="timeline"
                            fields={[NameField, TotalReportedField]}
                            displayFields={[NameField.name, TotalReportedField.name]}
                            buildTree
                            entityType={EntityType.TimeTrackingEntry}
                            scaleMultiplier={2.5} //cell width,
                            primaryOutlineLevel={1}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            controlSettings={{
                                ...controlSettings,
                                timeline: {
                                    ...controlSettings.timeline,
                                    quantization: Quantization.days
                                }
                            }}
                            onSaveSettings={onSaveSettings}
                            initialTimeframe={timeFrame}
                            userQuantization={gridPeriod}
                            scaleRenderMode={ScaleRenderMode.Cell}
                            expandedEntitiesIds={[_totalRow.id, ...projectLines.map(_ => _.attributes.Project.id)]}
                            buildRow={buildRow}
                            entities={[totalRow]}
                            renderTimelineHeaderFirstRow={() =>
                                <TimeTrackingTimeFrameSelector
                                    period={gridPeriod}
                                    timeFrame={timeFrame}
                                    onTimeFrameChange={(q, i) => {
                                        onSaveSettings?.(PreferenceUpdates.qunatization(q));
                                        onSaveSettings?.(PreferenceUpdates.timeframe(i));
                                    }} />
                            }
                            isDayOff={
                                resourceCalendarDataSet
                                    ? (date: Date) => getWorkingHoursBetweenDates(date, date, resourceCalendarDataSet) === 0
                                    : undefined
                            }
                            onItemRender={(timeTrackingLine: TimeTrackingLine & IMaybeTotal & WithEntityType, _: any, field: Field, defaultRender: any) => {
                                if (field.name === NameField.name) {
                    
                                    return (<div
                                        className={
                                            "item-name " +
                                            (timeTrackingLine.attributes.IsSuggestion ? "suggestion " : "") +
                                            timeTrackingLine.entityType?.toString().toLowerCase()
                                        }>
                                        <TimeTrackingLineEntityName
                                            timeTrackingLine={timeTrackingLine}
                                            defaultRender={defaultRender}
                                            field={field} />
                                    </div>);
                                }
                    
                                if (field.name === TotalReportedField.name) {
                    
                                    return (<div className="total-reported">{formatValue(timeTrackingLine.attributes.TotalReported, FormatType.Duration)}</div>);
                                }
                    
                                return defaultRender();
                            }}
                            onItemMenuRender={(item: IExtensibleEntity & WithEntityType & TimeTrackingLine) => {

                                return <TimeTrackingItemMenu
                                    entityType={item.entityType}
                                    timeTrackingLine={item}
                                    settings={settings}
                                    isReadonly={isReadonlyMode}
                                    onClearTimeEntries={(toClear) => {

                                        const updates = toClear.map(_ => ({
                                            id: _.id,
                                            attributes: {
                                                [nameof<TimeTrackingEntryAttr>("Duration")]: 0
                                            }
                                        }));

                                        timeTrackingActions.updateTimeEntries(updates);
                                    }}
                                    onDeleteTimeEntries={(entryIds) => {
                                        timeTrackingActions.deleteTimeEntries(entryIds);
                                    }}
                                    onImportTask={() => setModifiedEntry({ project: item.attributes.ProjectDetails, timeType: TimeType.ProjectTask })}
                                    onReportCustomTask={() => setModifiedEntry({ project: item.attributes.ProjectDetails, timeType: TimeType.CustomEvent })}
                                    onCopyTask={(toCopy) => timeTrackingActions.addTimeEntries(resource!.id, toCopy)}
                                />;
                            
                            }}
                    
                            renderSegmentContent={(row: IRow, segment: IScaleTimelineSegment) => {
                                const expected = resourceCalendarDataSet ?
                                    getWorkingHoursBetweenDates(
                                        segment.startDate,
                                        segment.finishDate,
                                        resourceCalendarDataSet
                                    )
                                    : 0;
                            
                                const isDayOff = expected === 0;
                            
                                if (row.subItemType === EntityType.Task) {
                                    // //fix timelist re-render
                                    if (cellPosition >= totalCells) {
                                        cellPosition = 0;
                                    }

                                    const isLocked = IsLockedPeriod(segment.finishDate, settings.timeReportingLock);
                                    const timeTrackingLine = row.entity as TimeTrackingLine;

                                    const idx = cellIndexMap[row.key]?.[segment.startDate.getTime()];
                                    const position = cellPosition;
                            
                                    if (!isLocked) {
                                        cellPositionToIndexMap[position] = idx;
                                    }

                                    cellPosition++;

                                    return (
                                        <TimeTrackingCell
                                            timeTrackingLine={timeTrackingLine}
                                            segment={segment}
                                            selection={cellSelection}
                                            selectionIndex={idx}
                                            isLocked={isLocked}
                                            isReadonly={isReadonlyMode}
                                            isDayOff={isDayOff}
                                            onEditStarted={() => {
                                                isCellEditingStarted.current = true;
                                            }}
                                            onEditCompleted={(updates) => {

                                                updates.forEach(_ => {
                                                    if (_.id) {
                                                        debouncedUpdate.callAction({
                                                            id: _.id, attributes: {
                                                                [nameof<TimeTrackingEntryAttr>("Duration")]: _.attributes.Duration
                                                            }
                                                        });
                                                    } else if (_.attributes.Duration) {

                                                        _fillupBillableAndCostType(_.attributes, timeTrackingLine, isEnterpriseTimeTracker, settings);
                                                        debouncedCreate.callAction(_.attributes);
                                                    }
                                                });

                                                isCellEditingStarted.current = false;
                                            }}
                                            componentRef={_ => {
                                                if (idx !== undefined && _) {
                                                    cellByIndexMap[idx] = _;
                                                }
                                            }}
                                            onClick={
                                                idx !== undefined && !isLocked
                                                    ? e => {
                                                        e.stopPropagation();
                                                        const preserveSelection = false;
                                                        navigateToCellByIndex(idx, preserveSelection);
                                                    }
                                                    : undefined
                                            }
                                            onKeyDown={e => {
                                                let nextPosition = undefined;
                                                if (e.key === "ArrowLeft") {
                                                    nextPosition = position - 1;
                                                } else if (e.key === "ArrowRight" || e.key === "Tab" || e.key === "Enter") {
                                                    nextPosition = position + 1;
                                                } else if (e.key === "ArrowUp") {
                                                    nextPosition = position - cellsInRow;
                                                } else if (e.key === "ArrowDown") {
                                                    nextPosition = position + cellsInRow;
                                                }

                                                if (
                                                    nextPosition !== undefined &&
                                                    nextPosition < totalCells &&
                                                    cellPositionToIndexMap[nextPosition] !== undefined
                                                ) {
                                                    navigateToCellByIndex(cellPositionToIndexMap[nextPosition]!, false);
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    );
                                } else {
                                
                                    return <TotalTimeTrackingCell
                                        row={row}
                                        segment={segment}
                                        isDayOff={isDayOff}
                                        expected={row.entity.id === totalRow.id ? expected : undefined} />;
                                }
                            }}
                            renderSegmentTooltipContent={(row, segment) => {
                                if (row.subItemType !== EntityType.Task || isCellEditingStarted.current) {
                                    return undefined;
                                }

                                const timeTrackingLine = getTimeTrackinLines(row)[0]!;

                                return (
                                    <TimeTrackingCellTooltip
                                        timeTrackingLine={timeTrackingLine}
                                        segment={segment}
                                        isReadonly={isReadonlyMode}
                                        resourceCalendarDataSet={resourceCalendarDataSet!}
                                        onClone={(toClone) => timeTrackingActions.addTimeEntries(resource!.id, toClone)}
                                        onStartEditing={_ => {

                                            const timeType = _.attributes.AdministrativeCategory
                                                ? TimeType.Administrative
                                                : _.attributes.CustomTimeEntryName
                                                    ? TimeType.CustomEvent
                                                    : TimeType.ProjectTask;
                                        
                                            setModifiedEntry({ entry: _, timeType: timeType });
                                        }}
                                        onCreate={() => {
                                        
                                            const timeType = timeTrackingLine.attributes.IsAdministrative
                                                ? TimeType.Administrative
                                                : timeTrackingLine.attributes.IsCustomEvent
                                                    ? TimeType.CustomEvent
                                                    : TimeType.ProjectTask;
                                        
                                        
                                            const newEditedEntry: TimeTrackingEntry = {
                                                id: "",
                                                attributes: {
                                                    Date: segment.startDate.toDateOnlyString(),
                                                    AdministrativeCategory: timeTrackingLine.attributes.IsAdministrative ? timeTrackingLine.attributes.Task.id : null,
                                                    CustomTimeEntryName: timeTrackingLine.attributes.IsCustomEvent ? timeTrackingLine.attributes.Task.name : null,
                                                    Task: timeTrackingLine.attributes.IsCustomEvent || timeTrackingLine.attributes.IsAdministrative
                                                        ? undefined
                                                        : {
                                                            id: timeTrackingLine.attributes.Task.id,
                                                            name: timeTrackingLine.attributes.Task.name,
                                                            uniqueId: timeTrackingLine.attributes.Task.uniqueId!,
                                                        },
                                                    Project: timeTrackingLine.attributes.IsAdministrative ? undefined : timeTrackingLine.attributes.Project,
                                                }
                                            };

                                            _fillupBillableAndCostType(newEditedEntry.attributes, timeTrackingLine, isEnterpriseTimeTracker, settings);
                                        
                                            setModifiedEntry({ entry: newEditedEntry, timeType: timeType });

                                        }}
                                        onEditCompleted={(id, attributes) => {
                                            timeTrackingActions.updateTimeEntries([{ id, attributes }]);
                                        }}
                                    />
                                );
                            }}
                        />
                        
                    </>
                }

                {!!modifiedEntry.timeType &&
                    <>
                        {
                            !!modifiedEntry.entry || modifiedEntry.timeType === TimeType.Administrative
                                ? <EditTimeTrackingEntry
                                    entry={modifiedEntry.entry ?? {
                                        attributes: {
                                            Date: GetNewEntryDate(timeFrame)
                                        }
                                    }}
                                    timeType={modifiedEntry.timeType!}
                                    onComplete={(id, entityAttributes) => {
                               
                                        setModifiedEntry({});
    
                                        if (id) {
                                            timeTrackingActions.updateTimeEntries([{ id: id, attributes: entityAttributes }]);
                                        } else {
                                            timeTrackingActions.addTimeEntries(resource!.id, [entityAttributes]);
                                        }
                                    }}
                                    onDismiss={() => {
                                        setModifiedEntry({});
                                    }}
                                />
                                : <>
                                    {modifiedEntry.timeType === TimeType.CustomEvent
                                        ? <EditCustomTimeTrackingEntry
                                            project={modifiedEntry.project}
                                            timeFrame={timeFrame}
                                            onComplete={(id, entityAttributes) => {
                                                setModifiedEntry({});
            
                                                if (id) {
                                                    timeTrackingActions.updateTimeEntries([{ id: id, attributes: entityAttributes }]);
                                                } else {
                                                    timeTrackingActions.addTimeEntries(resource!.id, [entityAttributes]);
                                                }
                                            }}
                                            onDismiss={() => {
                                                setModifiedEntry({});
                                            }}
                                        />
                                        :
                                        <ImportTimeTrackingTasksPanel
                                            project={modifiedEntry.project}
                                            onDismiss={() => {
                                                setModifiedEntry({});
                                            }}
                                            onImport={(descriptor, callback, entity) => {
                                                const entriesToCreate: CreateTimeTrackingEntryAttr[] = getDaysBetweenDates(timeFrame.start, timeFrame.end).map(date => {
                                                    return {
                                                        Date: date.toDateOnlyString(),
                                                        Duration: 0,
                                                        Task: {
                                                            id: descriptor.id
                                                        },
                                                        Project: {
                                                            id: descriptor.parentId!
                                                        }
                                                    }
                                                });

                                                timeTrackingActions.addTimeEntries(resource!.id, entriesToCreate, callback);

                                            }}
                                            onUndoImport={(type, id, callback) => {
                                                const entriesToRemove = entries.filter(_ => _.attributes.Task?.id === id);

                                                if (entriesToRemove.length) {
                                                    timeTrackingActions.deleteTimeEntries(entriesToRemove.map(_ => _.id), callback);
                                                } else {
                                                    callback();
                                                }
                                            }}
                                            isEntityImported={(entityExternalId: string) => lines.some(_ => _.attributes.Task?.id === entityExternalId)}
                                            resource={resource!}
                                            timeFrame={mergeSuggestTimeEntries ? undefined : timeFrame}
                                            exceptIds={_getTaskIds(lines)}
                                        />
                                    }
                                </>
                        }
                    </>
                }
            </ControlSpiner>
        </div>
    );
};


type CellIndexesByRowByDateMap = Dictionary<Dictionary<number>>;

interface ISelectableCellData extends IObjectWithKey {
    entityId: string;
    start: Date;
    finish: Date;
}

const _buildCellIndexMap = (entitiesIds: string[], scale: IScale): { map: CellIndexesByRowByDateMap; endToEndItems: ISelectableCellData[] } => {
    return entitiesIds
        .map((entityId, rowIndex) => ({
            entityId,
            datesWithIndex: scale.dates.map((_, index) => ({
                date: _,
                endToEndIndex: rowIndex * scale.dates.length + index
            }))
        }))
        .reduce<{ map: CellIndexesByRowByDateMap; endToEndItems: ISelectableCellData[] }>(
            (prev, cur) => ({
                map: {
                    ...prev.map,
                    [cur.entityId]: cur.datesWithIndex.reduce(
                        (_prev, _cur) => ({
                            ..._prev,
                            [_cur.date.start.getTime()]: _cur.endToEndIndex
                        }),
                        {}
                    )
                },
                endToEndItems: [
                    ...prev.endToEndItems,
                    ...cur.datesWithIndex.map<ISelectableCellData>(_ => ({
                        key: _.endToEndIndex,
                        entityId: cur.entityId,
                        start: _.date.start,
                        finish: _.date.finish
                    }))
                ]
            }),
            { map: {}, endToEndItems: [] }
        );
};

const _fillupBillableAndCostType = (
    entryAttributes: CreateTimeTrackingEntryAttr,
    timeTrackingLine: TimeTrackingLine,
    isEnterpriseTimeTracker: boolean,
    settings: TimeTrackingGlobalSettings): void => {

    if (entryAttributes.Project) {

        const billableEntity = isEnterpriseTimeTracker && settings.billableSource === ServerEntityType.Project
            ? timeTrackingLine.attributes.ProjectDetails
            : timeTrackingLine.attributes.TaskDetails;             
        
        CopyBillableAndCostType(entryAttributes, billableEntity, isEnterpriseTimeTracker);

        if (isEnterpriseTimeTracker && settings.billableSource !== settings.costTypeSource) {
            
            const costTypeEntity = settings.costTypeSource === ServerEntityType.Project
                ? timeTrackingLine.attributes.ProjectDetails
                : timeTrackingLine.attributes.TaskDetails;
            
            entryAttributes.CostType = GetCostType(costTypeEntity);
        }
        
        if (entryAttributes.Task && timeTrackingLine.attributes.TaskDetails) {
            entryAttributes.Task.uniqueId ??= timeTrackingLine.attributes.TaskDetails.uniqueId;
        }
    }
};

const _getTaskIds = (lines: TimeTrackingLine[]): string[] => {
    const taskIds = lines
        .map(_ => _.attributes.IsAdministrative || _.attributes.IsCustomEvent ? undefined : _.attributes.Task.id)
        .filter(notUndefined)
        .filter(distinct);
    
    return taskIds;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StoreProps {
    return {
        isLoading: state.timeTracking.isLoading,
        globalCalendar: state.calendar,

        entries: state.timeTracking.entries,
        tasks: state.timeTracking.tasks,
        projects: state.timeTracking.projects,

        administrativeCategories: state.tenant.timeTracking.administrativeCategories,
        settings: state.tenant.timeTracking.globalSettings,
        shownInterval: state.timeTracking.shownInterval,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant),
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription),
        showResourcePlanSmartSuggestion: ShowResourcePlanSmartSuggestion(state.tenant)
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        timeTrackingActions: bindActionCreators(TimeTrackingActionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(TimeTrackingGrid);

import { IDeletionResult, addOrUpdateOrRemove } from "./services/storeHelper";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { RouterAction } from "react-router-redux";
import { get, post, } from "../fetch-interceptor";
import { catchApiError, defaultCatch } from "./utils";
import { CostType, Dictionary, IExtensibleEntity, ITimeframe, IWithAttributes, MaybeDate, Quantization } from "../entities/common";
import { KnownAction as NotificationsKnownAction, actionCreators as NotificationsActionCreators, NotificationType } from "./NotificationsStore";
import { toDateTime } from "../components/utils/common";
import { nameof } from "./services/metadataService";
import { SourceType } from "./ExternalEpmConnectStore";
import { PPMFeatures, Subscription } from "./Tenant";

export interface TimeTrackingLine extends IExtensibleEntity {
    TimeEntries: TimeTrackingEntry[];
    attributes: TimeTrackingLineAttr;
}

export interface TimeTrackingLineAttr extends Dictionary<any> {
    Project: { name: string; id: string };
    Task: { name: string; id: string, uniqueId?: string };

    TaskDetails?: TimeTrackingTaskDetails;
    ProjectDetails?: TimeTrackingProjectDetails;

    IsAdministrative?: boolean;
    IsCustomEvent?: boolean;
    IsSuggestion?: boolean; //if merged from assignments
    HasSuggestedTasks?: boolean; //if has reported time but also exists in assignments
    TotalReported: number;

    TimeFrame: ITimeframe;
    Period: TimeTrackingGridPeriod;
}

export interface TimeTrackingEntry extends IExtensibleEntity {
    attributes: TimeTrackingEntryAttr;
}

export interface TimeTrackingEntryProject {
    name: string;
    id: string
}

export interface TimeTrackingEntryAttr extends Dictionary<any> {
    Project?: TimeTrackingEntryProject;
    Task?: { name: string; id: string, uniqueId: string };
    CustomTimeEntryName?: string | null;
    Duration?: number;
    Description?: string | null;
    Date: Date | string;
    IsBillable?: boolean;
    AdministrativeCategory?: string | null;
    CostType?: CostType;
}

export interface CreateTimeTrackingEntryAttr extends Dictionary<any> {
    Project?: { id: string };
    Task?: { id: string, uniqueId?: string; };
    CustomTimeEntryName?: string | null;
    Duration?: number;
    Description?: string | null;
    Date: Date | string;
    IsBillable?: boolean;
    AdministrativeCategory?: string | null;
    CostType?: CostType;
}

export interface TimeTrackingReportedSummary {
    thisWeek: ReportedForIntervalSummaryForInterval;
    thisMonth: ReportedForIntervalSummaryForInterval;
}

interface ReportedForIntervalSummaryForInterval {
    project: number;
    administrative: number;
}

export interface TimeTrackingState {
    activeResourceId?: string | null;
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    deletionResult?: IDeletionResult[];

    entries: TimeTrackingEntry[];

    tasks: TimeTrackingTaskDetails[];
    projects: TimeTrackingProjectDetails[];

    shownInterval?: ShownInterval;
    reportedSummary?: TimeTrackingReportedSummary;
}

export interface TimeTrackingTaskDetails extends IExtensibleEntity {
    sourceType: SourceType | null;
    externalData: Dictionary<any>;
    uniqueId?: string;
    isDeleted?: boolean;
    isArchived?: boolean;
}

export interface TimeTrackingProjectDetails extends IExtensibleEntity {
    imageId?: string;
    isArchived?: boolean;
    isDeleted?: boolean;
    name: string;
};

export type TimeTrackingGridPeriod  = Quantization.days | Quantization.weeks | Quantization.months;

export interface ShownInterval {
    period: TimeTrackingGridPeriod;
    timeFrame: ITimeframe;
}

interface TimeTrackingEntriesUpdateResult extends GetTimeTrackingEntriesResult {
    removedIds: string[];
    reportedSummary: TimeTrackingReportedSummary;
}

interface GetTimeTrackingEntriesResult {
    entries: TimeTrackingEntry[];
    tasks: TimeTrackingTaskDetails[];
    projects: TimeTrackingProjectDetails[];
}

const unloadedState: TimeTrackingState = {
    isLoading: false,
    isListLoading: false,
    isListUpdating: false,
    entries: [],
    tasks: [],
    projects: []
};

enum ActionTypes {

    LOAD_TIME_TRACKING_ENTRIES = "LOAD_TIME_TRACKING_ENTRIES",
    TIME_TRACKING_ENTRIES_LOADED = "TIME_TRACKING_ENTRIES_LOADED",

    CREATE_TIME_TRACKING_ENTRY = "CREATE_TIME_TRACKING_ENTRY",
    // TIME_TRACKING_ENTRY_CREATED = "TIME_TRACKING_ENTRY_CREATED",

    UPDATE_TIME_TRACKING_ENTRIES = "UPDATE_TIME_TRACKING_ENTRIES",
    TIME_TRACKING_ENTRIES_UPDATED = "TIME_TRACKING_ENTRIES_UPDATED",

    DELETE_TIME_TRACKING_ENTRY = "DELETE_TIME_TRACKING_ENTRY",
    TIME_TRACKING_ENTRY_DELETED = "TIME_TRACKING_ENTRY_DELETED",

    SET_TIME_TRACKING_INTERVAL = "SET_TIME_TRACKING_INTERVAL",
    
    LOAD_TRACKING_REPORTED_SUMMARY = "LOAD_TRACKING_REPORTED_SUMMARY",
    TIME_TRACKING_REPORTED_SUMMARY_LOADED = "TIME_TRACKING_REPORTED_SUMMARY_LOADED"
}

interface LoadTimeTrackingEntries {
    type: ActionTypes.LOAD_TIME_TRACKING_ENTRIES;
    resourceId: string | null;
}

interface TimeTrackingEntriesLoaded {
    type: ActionTypes.TIME_TRACKING_ENTRIES_LOADED;
    entries: TimeTrackingEntry[];
    tasks: TimeTrackingTaskDetails[];
    projects: TimeTrackingProjectDetails[];
}

interface CreateTimeTrackingEntries {
    type: ActionTypes.CREATE_TIME_TRACKING_ENTRY;
}

interface UpdateTimeTrackingEntries {
    type: ActionTypes.UPDATE_TIME_TRACKING_ENTRIES;
}

interface TimeTrackingEntriesUpdated {
    type: ActionTypes.TIME_TRACKING_ENTRIES_UPDATED;
    entries: TimeTrackingEntry[];
    removedIds: string[];
    tasks: TimeTrackingTaskDetails[];
    projects: TimeTrackingProjectDetails[];
}

interface SetTimeTrackingInterval {
    type: ActionTypes.SET_TIME_TRACKING_INTERVAL;
    interval: ShownInterval;
}

interface LoadTimeTrackingReportedSummary {
    type: ActionTypes.LOAD_TRACKING_REPORTED_SUMMARY;
    resourceId: string | null;
}

interface TimeTrackingReportedSummaryLoaded {
    type: ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED;
    summary: TimeTrackingReportedSummary;
}

type KnownAction =
    LoadTimeTrackingEntries
    | TimeTrackingEntriesLoaded
    | CreateTimeTrackingEntries
    | UpdateTimeTrackingEntries
    | TimeTrackingEntriesUpdated
    | SetTimeTrackingInterval
    | LoadTimeTrackingReportedSummary
    | TimeTrackingReportedSummaryLoaded;

export const actionCreators = {

    loadTimeEntries:
        (resourceId: string, start: Date, end: Date): AppThunkAction<KnownAction | RouterAction> =>
        (dispatch, getState) => {
            dispatch({ type: ActionTypes.LOAD_TIME_TRACKING_ENTRIES, resourceId: resourceId });

            get<GetTimeTrackingEntriesResult>(`/api/timetracking/resource/${resourceId}/entries`, {
                start: start.toDateOnlyString(),
                end: end.toDateOnlyString()
            })
                .then(data => dispatch({ type: ActionTypes.TIME_TRACKING_ENTRIES_LOADED, ...data }))
                .catch(defaultCatch(dispatch));
        },

    addTimeEntries:
        (
            resourceId: string,
            attributes: CreateTimeTrackingEntryAttr[],
            callback?: (entries: TimeTrackingEntry[]) => void
        ): AppThunkAction<KnownAction | RouterAction | NotificationsKnownAction> =>
            (dispatch, getState) => {
                dispatch({ type: ActionTypes.CREATE_TIME_TRACKING_ENTRY });

                attributes.forEach(_ => _fixEntryDate(_));

                const param = attributes.map(_ => {
                    return {
                        attributes: _
                    }
                });

                post<TimeTrackingEntriesUpdateResult>(`/api/timetracking/resource/${resourceId}/entry/bulk`, param )
                    .then(data => {
                        dispatch({ type: ActionTypes.TIME_TRACKING_ENTRIES_UPDATED, ...data });

                        if (data.reportedSummary) {
                            dispatch({ type: ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED, summary: data.reportedSummary });
                        }

                        callback?.(data.entries);
                    })
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch(emptyUpdateResult);
                    }));;
            },

   
    updateTimeEntries:
        (
            updates: { id: string, attributes: Partial<TimeTrackingEntryAttr> }[],
            callback?: (entries: TimeTrackingEntry[]) => void
        ): AppThunkAction<KnownAction | RouterAction | NotificationsKnownAction> =>
            (dispatch, getState) => {
                dispatch({ type: ActionTypes.UPDATE_TIME_TRACKING_ENTRIES });
    
                post<TimeTrackingEntriesUpdateResult>(`/api/timetracking/entry/bulk/update`, updates)
                    .then(data => {
                        dispatch({ type: ActionTypes.TIME_TRACKING_ENTRIES_UPDATED, ...data });
    
                        if (data.reportedSummary) {
                            dispatch({ type: ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED, summary: data.reportedSummary });
                        }
    
                        callback?.(data.entries);
                    })
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch(emptyUpdateResult);
                    }));
            },
    
    deleteTimeEntries:
        (ids: string[], callback?: () => void): AppThunkAction<KnownAction | RouterAction | NotificationsKnownAction> =>
            (dispatch, getState) => {
    
                post<TimeTrackingEntriesUpdateResult>(`/api/timetracking/entry/bulk/delete`, { ids })
                    .then(data => {
                        dispatch({ type: ActionTypes.TIME_TRACKING_ENTRIES_UPDATED, ...data});
                        
                        if (data.reportedSummary) {
                            dispatch({ type: ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED, summary: data.reportedSummary });
                        }
                        
                        callback?.();
                    })
                    .catch(catchApiError(_ => {
                        dispatch(NotificationsActionCreators.pushNotification({ message: _, type: NotificationType.Error }));
                        dispatch(emptyUpdateResult);
                    }));
            },
    
    loadReportedSummary:
        (resourceId: string): AppThunkAction<KnownAction | RouterAction> =>
            (dispatch, getState) => {

                dispatch({ type: ActionTypes.LOAD_TRACKING_REPORTED_SUMMARY, resourceId: resourceId });
    
                get<TimeTrackingReportedSummary>(`/api/timetracking/resource/${resourceId}/reported/summary`)
                    .then(_ => {
                        dispatch({ type: ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED, summary: _ });
                    })
                    .catch(defaultCatch(dispatch));
            },
    
    setShowInterval: (interval: ShownInterval): AppThunkAction<KnownAction | RouterAction> =>
        (dispatch, getState) => {
        
            dispatch({ type: ActionTypes.SET_TIME_TRACKING_INTERVAL, interval });
        }
};

const emptyUpdateResult: TimeTrackingEntriesUpdated = {
    type: ActionTypes.TIME_TRACKING_ENTRIES_UPDATED,
    entries: [],
    removedIds: [],
    tasks: [],
    projects: []
}

const _fixEntryDate = (attributes: CreateTimeTrackingEntryAttr) => {
    if (attributes.Date instanceof Date) {
        attributes.Date = attributes.Date.toDateOnlyString()
    };
}

const defaultReducer: Reducer<TimeTrackingState> = (state: TimeTrackingState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {

        case ActionTypes.LOAD_TIME_TRACKING_ENTRIES:
            {
                const summary = state.activeResourceId === action.resourceId
                    ? state.reportedSummary
                    : undefined;
            
                const entries = state.activeResourceId === action.resourceId ? state.entries : [];   
                const tasks = state.activeResourceId === action.resourceId ? state.tasks : []; 
                const projects = state.activeResourceId === action.resourceId ? state.projects : []; 

                return {
                    ...state,
                    entries: entries,
                    reportedSummary: summary,
                    activeResourceId: action.resourceId,
                    isLoading: true,
                    isListLoading: true,
                    tasks: tasks,
                    projects: projects
                };
            }
        case ActionTypes.TIME_TRACKING_ENTRIES_LOADED:
            action.entries.forEach(_ => _checkEntryDates(_));

            return {
                ...state,
                isLoading: false,
                isListLoading: false,
                entries: action.entries,
                tasks: action.tasks,
                projects: action.projects
            };

        case ActionTypes.TIME_TRACKING_ENTRIES_UPDATED:

            action.entries?.forEach(_ => _checkEntryDates(_));

            return {
                ...state,
                entries: addOrUpdateOrRemove(state.entries, action.entries, action.removedIds),
                tasks: addOrUpdateOrRemove(state.tasks, action.tasks, []),
                projects: addOrUpdateOrRemove(state.projects, action.projects, []),
                isLoading: false,
                isListLoading: false
            };
        
        case ActionTypes.SET_TIME_TRACKING_INTERVAL:

            return {
                ...state,
                shownInterval: action.interval
            };
        
        case ActionTypes.LOAD_TRACKING_REPORTED_SUMMARY:
            {
                const summary = state.activeResourceId === action.resourceId
                    ? state.reportedSummary
                    : undefined;
            
                const entries = state.activeResourceId === action.resourceId ? state.entries : [];           
                const tasks = state.activeResourceId === action.resourceId ? state.tasks : [];      
                const projects = state.activeResourceId === action.resourceId ? state.projects : [];           

                return {
                    ...state,
                    entries: entries,
                    activeResourceId: action.resourceId,
                    reportedSummary: summary,
                    tasks: tasks,
                    projects: projects
                }
            }
        
        case ActionTypes.TIME_TRACKING_REPORTED_SUMMARY_LOADED:

            return {
                ...state,
                reportedSummary: action.summary
            }

        default:
            return state;
    }
};

const _checkEntryDates = (entry: TimeTrackingEntry) => {
    entry.attributes.Date = new Date(entry.attributes.Date).getBeginOfDay();
};

export const reducer: Reducer<TimeTrackingState> = (state: TimeTrackingState = unloadedState, incomingAction: Action) => {
    return defaultReducer(state, incomingAction);
};

export const IsLockedPeriod = (periodDate: Date, timeReportingLock: MaybeDate): boolean => {
    return !!timeReportingLock && periodDate < toDateTime(timeReportingLock)!;
}

export const DefaultCostType = CostType.Opex;

export const GetCostType = (taskOrProject: IWithAttributes | undefined): CostType => {
    return taskOrProject?.attributes[nameof<TimeTrackingEntryAttr>("CostType")] ?? DefaultCostType;
}

const GetIsBillable = (taskOrProject: IWithAttributes | undefined): boolean => {
    return !!taskOrProject?.attributes[nameof<TimeTrackingEntryAttr>("IsBillable")];
}

export const CopyBillableAndCostType = (
    entryAttributes: { IsBillable?: boolean, CostType?: CostType },
    taskOrProject: IWithAttributes | undefined,
    isEnterpriseTimeTracker: boolean): void => {
    
    entryAttributes.IsBillable = GetIsBillable(taskOrProject);

    if (isEnterpriseTimeTracker) {
        entryAttributes.CostType = GetCostType(taskOrProject);
    }
};

export const TimelineToCreateTimeEntry = (timeTrackingLine: TimeTrackingLine, date: Date | string, duration: number): CreateTimeTrackingEntryAttr => {
    return {
        Date: date,
        Duration: duration,

        Project: timeTrackingLine.attributes.IsAdministrative ? undefined : timeTrackingLine.attributes.Project,
        Task:
            timeTrackingLine.attributes.IsCustomEvent || timeTrackingLine.attributes.IsAdministrative
                ? undefined
                : timeTrackingLine.attributes.Task,
        CustomTimeEntryName: timeTrackingLine.attributes.IsCustomEvent ? timeTrackingLine.attributes.Task.name : undefined,
        AdministrativeCategory: timeTrackingLine.attributes.IsAdministrative ? timeTrackingLine.attributes.Task.id : undefined
    };
}

export const IsEnterpriseTimeTracker = (subscription: Subscription) => {
    return Subscription.contains(subscription, PPMFeatures.TimeTrackingEnterprise);
}
import { getId, IconButton, IPickerItemProps, MessageBar, MessageBarType, arraysEqual, IBasePicker, Autofill } from 'office-ui-fabric-react';
import * as React from 'react'
import { Dictionary } from '../../../entities/common';
import * as Metadata from '../../../entities/Metadata';
import { post } from '../../../fetch-interceptor';
import * as ProjectsListStore from '../../../store/ProjectsListStore';
import { Validator } from '../../../validation';
import { EntityPicker, IFindResult } from './EntityPickerInput';
import InputErrorMessage from './inputErrorMessage';
import { IFormInputProps } from '../interfaces/IFormInputProps';
import Link from '../Link';
import RemoveDialog from '../RemoveDialog';
import { toDictionaryById } from '../../utils/common';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { searchPathMap } from '../../../entities/Metadata';
import { buildFieldInputFilter } from './EntityFieldInputElement';

const searchPath = `api/${searchPathMap[Metadata.FieldType.Portfolio]}/find`;
type PortfolioInfo = {
    id: string;
    name: string;
    url?: string;
    programId?: string;
}
type OwnProps = IFormInputProps<IFindResult[], Autofill> & { entity: ProjectsListStore.ProjectInfo };
type StateProps = {
    subscription: Subscription;
}
type Props = StateProps & OwnProps;
type State = {
    infos: PortfolioInfo[],
    showRemovalConfirmation?: boolean;
    infoToRemove?: PortfolioInfo;
}

class ProjectPortfoliosPicker extends React.Component<Props, State> {
    private _picker = React.createRef<IBasePicker<PortfolioInfo>>();

    constructor(props: Props) {
        super(props);
        const { entity } = props;
        this.state = {
            infos: this._buildState(entity.attributes.Portfolio, entity.portfolioViaProgram, entity.attributes.Program)
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        const { entity } = nextProps;
        const infos = this._buildState(entity.attributes.Portfolio, entity.portfolioViaProgram, entity.attributes.Program);
        if (!arraysEqual(this.state.infos, infos)) {
            this.setState({ infos });
        }
    }

    render() {
        const { readOnly, inputProps, subscription } = this.props;
        const { infos } = this.state;
        const available = Subscription.contains(subscription, PPMFeatures.PortfolioManagement);
        return <>
            <div className="entity-picker project-portfolios">
                <EntityPicker
                    componentRef={this._picker}
                    readOnly={readOnly || !available}
                    selectedItems={infos}
                    onResolveSuggestions={this._onResolveSuggestions}
                    inputProps={inputProps}
                    onRenderItem={selectedItemPortfolio}
                    onChange={this._onChange} />
            </div>
            <InputErrorMessage text={this._getErrorMessage(this.state.infos)} />
            {this.state.infoToRemove && this._renderRemovalConfirmation()}
        </>;
    }

    private _onChange = (infos: PortfolioInfo[]): void => {
        if (infos?.length < this.state.infos?.length) {
            const infoToRemove = this.state.infos.find(_ => !infos.some(__ => __.id === _.id));
            this.setState({ infoToRemove });
        } else {
            this._onSave(infos);
        }
    }

    private _onSave = (portfolios: PortfolioInfo[]): void => {
        const { entity, onChanged, onEditComplete } = this.props;
        const toSave = portfolios.filter(_ => !_.programId);
        const infos = this._buildState(toSave, entity.portfolioViaProgram, entity.attributes.Program);
        this.setState({ infos });

        onChanged && onChanged(toSave);
        onEditComplete && this._validate(toSave) && onEditComplete(toSave || null);
    }

    private _onResolveSuggestions = (filter: string, selectedItems?: PortfolioInfo[]) => {
        let array = post<IFindResult[]>(searchPath, {
            name: filter,
            ...buildFieldInputFilter(Metadata.FieldType.Portfolio)
        });
        if (this.props.entity.attributes.Portfolio.length) {
            array = array.then(_ => _.filter(__ => !this.props.entity.attributes.Portfolio.some(selected => selected.id === __.id)));
        }

        return array.then(findResults => findResults.map<PortfolioInfo>(_ => ({
            ..._,
            url: this._buildUrl(_.id)
        })));
    }

    private _buildUrl = (id: string): string | undefined => {
        const withNavigation = Subscription.contains(this.props.subscription, PPMFeatures.PortfolioManagement);
        return withNavigation
            ? `/${searchPathMap[Metadata.FieldType.Portfolio]}/${id}`
            : undefined;
    }

    private _renderRemovalConfirmation = () => {
        const { infoToRemove } = this.state;
        if (!infoToRemove) {
            return null;
        }
        if (infoToRemove.programId) {
            const program = this.props.entity.attributes.Program.find(_ => _.id === infoToRemove.programId);
            return <RemoveDialog
                onClose={this._onCloseDialog}
                onComplete={this._onCloseDialog}
                confirmButtonProps={{ text: "Got it" }}
                dialogContentProps={{
                    title: "Unable to delete portfolio",
                    subText: `This project cannot be removed from the portfolio as it was added via program "${program?.name}"`
                }} />;
        }
        return <RemoveDialog
            onComplete={() => {
                const entitiesToSave = this.state.infos.filter(_ => _.id !== infoToRemove.id);
                this._onSave(entitiesToSave);
                this._onCloseDialog();
            }}
            onClose={this._onCloseDialog}
            dialogContentProps={{
                title: `Removal Confirmation`,
                subText: `Are you sure you want to remove this project from the portfolio?`
            }}>
            <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                If the project is removed from the portfolio, items imported from this project will be removed as well.
            </MessageBar>
        </RemoveDialog>;
    }

    private _onCloseDialog = () => this.setState({ infoToRemove: undefined });

    private _buildState(
        portfolios: ProjectsListStore.IPortfolioInfo[],
        portfoliosViaPrograms: ProjectsListStore.IPortfolioViaProgram[],
        programs: ProjectsListStore.IProgramInfo[]
    ): PortfolioInfo[] {
        const programIds = toDictionaryById(programs);
        const portfoliosMap: Dictionary<PortfolioInfo> = {};
        portfoliosViaPrograms.filter(_ => !!programIds[_.programId]).forEach(_ => portfoliosMap[_.portfolio.id] = {
            id: _.portfolio.id,
            name: _.portfolio.name,
            programId: _.programId,
            url: this._buildUrl(_.portfolio.id)
        });
        portfolios.forEach(_ => portfoliosMap[_.id] = { ..._, url: this._buildUrl(_.id) });

        return Object.values(portfoliosMap);
    }

    componentDidMount() {
        this.props.inputRef && this.props.inputRef(this);
    }

    focus(): void {
        this._picker.current!.focusInput();
    }

    private _validate = (value?: IFindResult | IFindResult[] | null) => {
        return this.props.validator === undefined || this.props.validator.isValid(value);
    }

    private _getErrorMessage = (value?: IFindResult | IFindResult[] | null) =>
        this.props.inputProps?.required && Validator.new().required().build().getErrorMessage(value)
        || this.props.validator && this.props.validator.getErrorMessage(value);
}

const selectedItemPortfolio = (props: IPickerItemProps<PortfolioInfo>) => {
    const { item, disabled, readOnly, onRemoveItem } = props;
    const itemId = getId();

    return (
        <div key={item.id} className={`picker-item ${readOnly ? "readonly" : ""} ${disabled ? "disabled" : ""}`}
            data-is-focusable
            data-is-sub-focuszone
            role={'listitem'}
            aria-labelledby={'selectedItemPortfolio-' + itemId}
            style={{ cursor: "pointer" }}>
            {
                item.url
                    ? <Link href={item.url} className="label link" title="Navigate">{item.name}</Link>
                    : <span title={item.name}>{item.name}</span>
            }
            {!readOnly && !disabled &&
                <IconButton onClick={onRemoveItem} iconProps={{ iconName: 'Cancel', style: { fontSize: '12px' } }} />}
        </div>
    );
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription
    }
}

export default connect(mapStateToProps, {})(ProjectPortfoliosPicker);

import * as React from 'react';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as Notifications from "../../store/NotificationsStore";
import * as ViewsStore from '../../store/views';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import * as LayoutsStore from '../../store/layouts';
import * as Metadata from '../../entities/Metadata';
import {
    EntityType, Dictionary, ISourceInfo, SourceInfo, PpmxConnection, IInsightsData, ppmxTaskConnectionId,
    IWarning, IPatch, StatusCategory, Sections,
    ServerEntityType
} from '../../entities/common';
import { RouteComponentProps } from "react-router-dom";
import * as TasksSummaryControl from '../common/sectionsControl/uiControls/TasksSummaryControl';
import * as TasksControl from '../common/sectionsControl/uiControls/TasksControl';
import { DetailsSpinner } from '../common/Spinner';
import { connect } from 'react-redux';
import { warningService } from '../../store/services/warningService';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';
import ExternalEpmConnectControl from '../project/ExternalEpmConnectControl';
import { SectionsContainer } from '../common/sectionsControl/SectionsContainer';
import { isLiteModel, toDate } from '../utils/common';
import { ISubentitiesListSettings } from '../common/SubentitiesList';
import { ITask, ITaskAttrs, urlParamsBuilder } from '../../entities/Subentities';
import * as Groups from '../../store/groups';
import { nameof } from '../../store/services/metadataService';
import { Insights, Subscription, PPMFeatures, TimeTrackingGlobalSettings } from '../../store/Tenant';
import * as CalendarStore from '../../store/CalendarStore';
import { validatorsBuilder, rendersBuilder } from './Fields';
import { calculateDueDate } from '../utils/duration';
import { BaselineFakeFields } from '../views/list/columns/task/Baseline';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { ICreationData } from '../../store/Subentity';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { Views } from '../../store/services/viewSaver';
import { ViewTypeViews, buildUrlViewTypeHeaderRender, viewTypeSettingsBuilder } from '../common/ViewTypeSelect';
import { mergeDefault } from '../../store/utils';
import { CalculationModeFakeField } from '../views/list/columns/task/Mode';
import { getTaskReadonlyFields } from '../mywork/TaskEditPanel';
import { actionCreators as UserInterfaceActionCreators } from '../../store/UserInterfaceStore';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import * as MondayComStore from '../../store/integration/MondayComStore';
import { CreateTimeTrackingEntryAttr, CopyBillableAndCostType, actionCreators as TimeTrackingActionCreators, IsEnterpriseTimeTracker, GetCostType } from "../../store/TimeTrackingStore";
import ProjectHeader from '../project/ProjectHeader';
import { UserState } from '../../store/User';
import { IsSummaryFakeField } from '../views/list/columns/task/IsSummary';

export type StateProps = {
    projectFields: Metadata.Field[];
    taskFields: Metadata.Field[];
    entity?: ProjectsListStore.ProjectInfo;
    insights: Insights;
    warnings?: IWarning[];
    warningsTypeMap?: WarningsTypeMapsStore.EntityWarningsTypeMap;
    groups: Metadata.Group[] | undefined;
    isLoading: boolean;
    calendar: CalendarStore.CalendarDataSet;
    hasArchiveProjects: boolean;
    timeTrackingSettings: TimeTrackingGlobalSettings;
    isEnterpriseTimeTracker: boolean;
    user: UserState;
};
export type ActionProps = {
    taskViewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    warningsTypeMapsActions: typeof WarningsTypeMapsStore.actionCreators;
    groupsActions: ReturnType<typeof Groups.actionCreators.forEntity>;
    calendarActions: typeof CalendarStore.actionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    userInterfaceActions?: typeof UserInterfaceActionCreators;
    mondayComActions: typeof MondayComStore.actionCreators;
    timeTrackingActions?: typeof TimeTrackingActionCreators;
};
type Props = StateProps
    & ActionProps
    & RouteComponentProps<{ id: string }>;
type State = {
    isCofigurationPanelOpen?: boolean;
    activeSourceInfo: ISourceInfo;
}
const tasksSectionSettings: ISubentitiesListSettings = {
    displayFields: [],
    orderBy: [],
    timeline: {
        displayFields: []
    }
};

const tasksControlType: string = 'TasksControl';
const sections: Metadata.Section[] = [{
    id: '8ef51ed2-6b9a-4091-bed6-c7b075329df4',
    isOpen: true,
    isSelected: true,
    isAccessible: true,
    name: 'Summary',
    settings: { iconName: 'PPMXSectionSummary' },
    uiControls: [{
        id: '8d56abdb-2acd-4300-9b59-9f1296a3dd8a',
        type: 'TasksSummaryControl',
        settings: {}
    }]
}, {
    id: Sections.ProjectTasksSectionId,
    isOpen: true,
    isSelected: true,
    isAccessible: true,
    name: 'Tasks',
    settings: { iconName: 'TaskManager' },
    uiControls: [{
        id: 'f2ee84be-b931-43db-ab9e-6dbf7f0fc5b7',
        type: tasksControlType,
        settings: tasksSectionSettings
    }]
}];

const entityName = EntityType.Project;

export class ProjectTasksList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { activeSourceInfo: PpmxConnection };
    }

    componentWillMount() {
        this.props.calendarActions.load();
        this.props.warningsTypeMapsActions.loadWarningsTypeMap(entityName);
        if (!this.props.entity || this.props.entity.id !== this.props.match.params.id || isLiteModel(this.props.entity)) {
            this.props.projectsActions.loadProject(this.props.match.params.id);
        } else if (this.props.entity) {
            this._handleConnection(this.props);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.match.params.id != this.props.match.params.id) {
            this.props.projectsActions.loadProject(nextProps.match.params.id);
        }
        if (nextProps.entity && (this._isConnectionIdChanged(this.props, nextProps) || nextProps.entity?.id != this.props.entity?.id)
            || (this.props.isLoading && !nextProps.isLoading)) {
            this._handleConnection(nextProps);
        }
    }

    private _isConnectionIdChanged(props: Props, nextProps: Props): boolean {
        if (!props.entity || !nextProps.entity) {
            return false;
        }
        const prevConnectionId = this._getUrlConnectionId(props);
        const nextConnectionId = this._getUrlConnectionId(nextProps);
        return prevConnectionId != nextConnectionId;
    }

    private _getSourceInfo(entity: ProjectsListStore.ProjectInfo, connectionId: string | null): ISourceInfo<any> {
        const sourceInfo = SourceInfo.getSyncableConnections(entity.sourceInfos).find(_ => !SourceInfo.isCollaborative(_) && _.connectionId == connectionId);
        return connectionId && connectionId != ppmxTaskConnectionId && sourceInfo
            ? sourceInfo
            : PpmxConnection
    }

    private _getUrlConnectionId(props: Props): string {
        const query = new URLSearchParams(props.location.search);
        const connectionId = query.get(urlParamsBuilder.connectionId);
        return this._getSourceInfo(props.entity!, connectionId).connectionId;
    }

    private _handleConnection = (props: Props) => {
        if (!props.entity) {
            return;
        }
        const connectionId = this._getUrlConnectionId(props);
        const activeSourceInfo = this._getSourceInfo(props.entity, connectionId);

        if (activeSourceInfo) {
            this.setState({ activeSourceInfo }, () => {
                props.projectsActions.loadTasks(props.entity!.id, activeSourceInfo.type, connectionId);
                props.groupsActions.load(this.props.match.params.id, connectionId);
                this.loadConnectionConfiguration(activeSourceInfo);
            })
        }
    }

    private loadConnectionConfiguration = (activeSourceInfo?: ISourceInfo<any>) => {
        if (activeSourceInfo?.type === SourceType.MondayCom){
                this.props.mondayComActions.loadConnectionConfiguration(activeSourceInfo.connectionId, activeSourceInfo.sourceData.boardId, activeSourceInfo.sourceData.subItemBoardId);
        }
    }

    public render() {
        const { isLoading, entity, warnings } = this.props;

        return <DetailsSpinner isLoading={isLoading}>
            {entity && <ProjectHeader
                entity={entity}
                navigateToDetails
                hideWarnings
                disableChangeView
                actions={{
                    openConfigurationPanel: this.openConfigurationPanel,
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    setFavorite: this.setFavorite,
                    cloneProject: this.cloneProject,
                    removeProject: this.removeProject,
                    archiveProject: this.props.hasArchiveProjects ? this.archiveProject : undefined,
                    updateSettings: this.updateSettings,
                    setProjectBaseline: this.setProjectBaseline,
                    layoutActions: {
                        viewLayout: this.viewLayout
                    },
                }}
            ></ProjectHeader>
            }
            {entity && <SectionsContainer
                key="sections"
                entity={entity}
                entityType={EntityType.Task}
                hideSideBar
                getSections={() => sections}
                warnings={warnings}
                controlsConfig={this._buildControlsConfigurations()}
            />}
            {
                entity && this.state.isCofigurationPanelOpen &&
                <ExternalEpmConnectControl
                    entity={entity}
                    onDismiss={() => this.setState({ isCofigurationPanelOpen: false })}
                />
            }
        </DetailsSpinner>;
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { groups, calendar } = this.props;
        const { activeSourceInfo } = this.state;
        return {
            ['TasksSummaryControl']: {
                datacontext: {
                    activeSourceInfo: activeSourceInfo
                },
            },
            ['TasksControl']: {
                settingsBuilder: settings => viewTypeSettingsBuilder(
                    mergeDefault({ timeline: { styleSettingValues: { showBaseline: true } } }, settings),
                    ViewTypeViews.TaskControl.default),
                headerRender: buildUrlViewTypeHeaderRender(ViewTypeViews.TaskControl),
                elementCustomRender: rendersBuilder(this.props.entity!, groups || [], calendar, this._buildTaskUrl, () => this.props.entity?.tasks),
                customFieldValidator: validatorsBuilder(calendar, () => this.props.entity?.tasks),
                datacontext: {
                    project: this.props.entity!,
                    readOnlyFields: (task: ITask) => getTaskReadonlyFields(task, this.props.insights, this.props.entity?.insights.taskStatusCalculationDisabled),
                    groups,
                    activeSourceInfo: activeSourceInfo,
                    fakeFields: activeSourceInfo.type === SourceType.Ppmx ? [...BaselineFakeFields, CalculationModeFakeField, IsSummaryFakeField] : [IsSummaryFakeField]
                },
                actions: {
                    buildNew: this.buildNewExternalTask,
                    create: this.createTask,
                    update: this.updateTask,
                    bulkUpdate: this.updateTasks,
                    bulkCreate: this.createTasks,
                    dragEntities: this.dragTasks,
                    setBaseline: this.setBaseline,
                    remove: this.removeExternalTasks,
                    updateUIControl: this.updateUIControl,
                    refreshEntity: this.refreshEntity,
                    exportToFile: this.exportSubEntitiesToFile,
                    importFromFile: this.importSubEntitiesFromFile,
                    createGroup: this.createGroup,
                    updateGroup: this.updateGroup,
                    removeGroup: this.removeGroup,
                    reorderGroups: this.reorderGroups,
                    reportTime: this.reportTime,
                    indent: this.indentTasks,
                    outdent: this.outdentTasks,
                    rollupTasks: this.rollupTasks,
                    updateCalculationMode: this.changeCalculationMode,
                }
            }
        }
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private openConfigurationPanel = () => {
        this.setState({ isCofigurationPanelOpen: true });
    }

    private cloneProject = () => {
        this.props.projectsActions.cloneProject(this.props.entity!.id);
        this.props.notificationsActions.pushNotification({ message: 'Project is cloned', type: Notifications.NotificationType.Info });
    }

    private removeProject = () => {
        this.props.projectsActions.removeProjects([this.props.entity!.id], true);
    }

    private archiveProject = () => {
        this.props.projectsActions.archiveProjects([this.props.entity!.id], true);
    }

    private setProjectBaseline = (setTasksBaseline: boolean, setKeyDatesBaseline: boolean) => {
        this.props.projectsActions.setProjectBaseline(this.props.entity!.id, setTasksBaseline, setKeyDatesBaseline);
    }

    private updateSettings = (data: Partial<IInsightsData & ProjectsListStore.ProjectSettings>): void => {
        this.props.projectsActions.updateSettings(this.props.entity!.id, data, () => {
            const connectionId = this._getUrlConnectionId(this.props);
            if (connectionId == ppmxTaskConnectionId) {
                this.props.projectsActions.loadTasks(this.props.entity!.id, this.state.activeSourceInfo.type, connectionId, true);
            }

            if (data.calculateCompletedWorkBasedOnReportedTime) {
                this.props.projectsActions.runTaskCompletedWorkRecalculation(this.props.entity!.id);
            }
        });
        this.props.notificationsActions.pushNotification({ message: `Project settings have been changed.` });
    }

    private updateImage = (logo: File) => {
        this.props.projectsActions.updateImage(this.props.entity!.id, logo);
    }

    private removeImage = () => {
        this.props.projectsActions.removeImage(this.props.entity!.id);
    }

    private setFavorite = (isFavorite: boolean) => {
        this.props.projectsActions.setFavorite(this.props.entity!.id, isFavorite);
    }
    private buildNewExternalTask = (): ITask => {
        const group = this.props.groups?.find(_ => _.isDefault) || this.props.groups?.[0];
        let startDate = toDate(this.props.entity?.attributes.StartDate)?.getBeginOfDay();
        const today = new Date().getBeginOfDay();
        if (!startDate || startDate < today) {
            startDate = today;
        }
        const durationDays = 1;
        const dueDate = calculateDueDate(startDate, durationDays, this.props.calendar)
        const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, this.props.taskFields)!;
        return {
            sourceType: SourceType.Ppmx,
            isAutoMode: this.props.entity?.settings.isTaskAutoCalculationMode,
            attributes: {
                Group: group && { id: group.id, name: group.name, color: group.color },
                StartDate: startDate.toDateOnlyString(),
                DueDate: dueDate.toDateOnlyString(),
                Duration: durationDays,
                Progress: 0,
                Status: statusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA)
            },
            externalData: {},
            hierarchy: {}
        } as ITask;
    }
    private createTask = (data: ICreationData) => {
        this.props.projectsActions.createTask(this.props.entity!.id, this.state.activeSourceInfo.connectionId, data);
    }
    private updateTask = (id: string, changes: Partial<ITaskAttrs>) => {
        this.updateTasks([{ id, attributes: changes }]);
    }
    private updateTasks = (items: IPatch<ITask>[]) => {
        this.props.projectsActions.updateTasks(this.props.entity!.id, this.state.activeSourceInfo.connectionId, items);
    }
    private createTasks = (items: ICreationData[]) => {
        this.props.projectsActions.createTasks(this.props.entity!.id, this.state.activeSourceInfo.connectionId, items);
    }
    private dragTasks = (ids: string[], groupId?: string, insertBeforeId?: string) => {
        if (!groupId) {
            return;
        }
        this.props.projectsActions.dragTasksToGroup(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids, groupId, insertBeforeId);
    }
    private setBaseline = (ids: string[]) => {
        this.props.projectsActions.setBaseline(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids);
    }
    private removeExternalTasks = (ids: string[]) => {
        this.props.projectsActions.removeTasks(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids);
    }
    private updateUIControl = (): void => {
        //this.props.projectsActions.updateUIControl(this.props.entity!.id, sectionId, uiControlId, settings);
    }
    private refreshEntity = () => {
        this._handleConnection(this.props);
    }
    private exportSubEntitiesToFile = (subentityType: EntityType, pluralSubentityTypeLabel: string, fields: string[], ids: string[], viewType: Views) => {
        const exportColumns = [...fields];
        if (!exportColumns.find(_ => _ === nameof<ITaskAttrs>("Group"))) {
            exportColumns.push(nameof<ITaskAttrs>("Group"));
        }
        if (viewType === "Timeline") {
            if (!exportColumns.includes(nameof<ITaskAttrs>("StartDate"))) {
                exportColumns.splice(exportColumns.indexOf(nameof<ITaskAttrs>("Name")) + 1, 0, nameof<ITaskAttrs>("StartDate"));
            }
            if (!exportColumns.includes(nameof<ITaskAttrs>("DueDate"))) {
                exportColumns.splice(exportColumns.indexOf(nameof<ITaskAttrs>("StartDate")) + 1, 0, nameof<ITaskAttrs>("DueDate"));
            }
            if (!exportColumns.includes(nameof<ITaskAttrs>("Progress"))) {
                exportColumns.push(nameof<ITaskAttrs>("Progress"));
            }
            if (!exportColumns.includes(nameof<ITaskAttrs>("Predecessor"))) {
                exportColumns.push(nameof<ITaskAttrs>("Predecessor"));
            }
        }
        this.props.projectsActions.exportSubEntitiesToFile(
            this.props.entity!.id,
            subentityType,
            pluralSubentityTypeLabel,
            exportColumns,
            ids);
    }
    private importSubEntitiesFromFile = (subentityType: EntityType, subentityCollectionName: string, file: File) => {
        this.props.projectsActions.importSubEntitiesFromFile(this.props.entity!.id, subentityType, subentityCollectionName, file);
    }

    private reportTime = (task: ITask, seconds: number, date: Date) => {

        if (this.props.timeTrackingSettings.newTimeTrackingEnabled) {

            const entry: CreateTimeTrackingEntryAttr = {
                Project: {
                    id: this.props.entity!.id
                },
                Task: {
                    id: task.id
                },
                Date: date.toDateOnlyString(),
                Duration: seconds / 60 / 60,
            };

            const billableEntity = this.props.isEnterpriseTimeTracker && this.props.timeTrackingSettings.billableSource === ServerEntityType.Project
                ? this.props.entity
                : task;

            CopyBillableAndCostType(entry, billableEntity, this.props.isEnterpriseTimeTracker);
           
            if (this.props.isEnterpriseTimeTracker &&
                this.props.timeTrackingSettings.billableSource !== this.props.timeTrackingSettings.costTypeSource) {
                
                const costTypeEntity = this.props.timeTrackingSettings.costTypeSource === ServerEntityType.Project
                    ? this.props.entity
                    : task;
                
                entry.CostType = GetCostType(costTypeEntity);
            }

            this.props.timeTrackingActions!.addTimeEntries(this.props.user.resource!.id, [entry], () => {

                if (this.props.timeTrackingSettings.calculateCompletedWorkBasedOnReportedTime && task.connectionId === ppmxTaskConnectionId) {
                    this.props.projectsActions.refreshTask(this.props.entity!.id, task.id, ppmxTaskConnectionId);
                }
            });
        }
        else {
            this.props.projectsActions.reportTime(this.props.entity!.id, task.id, seconds, date, ppmxTaskConnectionId);
        }
    }

    private createGroup = (group: Metadata.IBaseGroupInfo) => {
        this.props.groupsActions.create(this.props.entity!.id, group);
    }
    private updateGroup = (group: Metadata.Group) => {
        this.props.groupsActions.update(this.props.entity!.id, group);
    }
    private removeGroup = (groupId: string) => {
        this.props.groupsActions.remove(this.props.entity!.id, groupId);
    }
    private reorderGroups = (groupIds: string[]) => {
        this.props.groupsActions.reorder(this.props.entity!.id, groupIds);
    }

    private _buildTaskUrl = (task: { id: string }): string => {
        const query = new URLSearchParams(this.props.location.search);
        query.set(EntityType.Task.toLowerCase(), task.id);

        return `${this.props.location.pathname}?${query.toString()}`;
    }

    private indentTasks = (ids: string[]) => {
        this.props.projectsActions.indentTasks(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids);
    }

    private outdentTasks = (ids: string[]) => {
        this.props.projectsActions.outdentTask(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids);
    }

    private rollupTasks = (ids: string[]) => {
        this.props.projectsActions.rollupTasks(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids);
    }

    private changeCalculationMode = (isAutoMode: boolean, ids: string[]) => {
        this.props.projectsActions.changeCalculationMode(this.props.entity!.id, this.state.activeSourceInfo.connectionId, ids, isAutoMode);
    }
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    TasksSummaryControl: TasksSummaryControl.IConfiguration;
    TasksControl: TasksControl.IConfiguration;
};

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        taskViewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Task), dispatch),
        groupsActions: bindActionCreators(ProjectsListStore.groupsCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        userInterfaceActions: bindActionCreators(UserInterfaceActionCreators, dispatch),
        mondayComActions: bindActionCreators(MondayComStore.actionCreators, dispatch),
        timeTrackingActions: bindActionCreators(TimeTrackingActionCreators, dispatch)
    }
}

export default connect(
    (state: ApplicationState, ownProp: RouteComponentProps<{ id: string }>): StateProps => {
        const projectFields = state.fields[entityName];
        const taskFields = state.fields[EntityType.Task];
        const warningsTypeMap = state.warningsTypeMaps.maps[entityName] || { common: [], targeted: {} }
        const entity = state.projectsList.activeEntity && state.projectsList.activeEntity.id === ownProp.match.params.id
            ? state.projectsList.activeEntity
            : undefined;
        let warnings: IWarning[] = [];
        if (entity?.sections) {
            const tuple = warningService.filterByEntityConfiguration([...entity.warnings], warningsTypeMap, entity.sections);
            warnings = tuple.warnings;
        }

        return {
            entity,
            insights: state.tenant.insights,
            calendar: state.calendar,
            warnings,
            projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
            taskFields: taskFields.allIds.map(_ => taskFields.byId[_]),
            warningsTypeMap,
            groups: state.projectsList.groups.byId[ownProp.match.params.id]?.items,
            isLoading: state.projectsList.isLoading || state.warningsTypeMaps.isLoading,
            hasArchiveProjects: Subscription.contains(state.tenant.subscription, PPMFeatures.ArchiveProjects),
            timeTrackingSettings: state.tenant.timeTracking.globalSettings,
            isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription),
            user: state.user
        };
    },
    mergeActionCreators
)(ProjectTasksList);
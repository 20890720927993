import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IEntityStore } from './integration/common'
import { defaultCatch } from './utils';
import { addOrUpdateOrRemove } from './services/storeHelper';
import { post, remove } from '../fetch-interceptor';
import { EntityType } from '../entities/common';

export type ExtensionsState = IEntityStore<ExtensionInfo[]>;

type UpdatedExtensionAction = { type: 'UPDATE_EXTENSION', addOrUpdate?: ExtensionInfo[], remove?: string[];}
type DeletedExtensionAction = { type: 'DELETED_EXTENSION', extensions: ExtensionInfo[] }
type ProcessingExtensionAction = { type: 'PROCESSING_EXTENSIONS' }

type KnownAction = UpdatedExtensionAction | DeletedExtensionAction | ProcessingExtensionAction;

const initialState: ExtensionsState = {
    isLoading: false,
    isProcessing: false,
    entities: [],
    error: null
};

type BaseExtensionInfo = { name: string, description?: string, url: string, type: ExtensionType, location: ExtensionLocation };
export type ExtensionInfo = BaseExtensionInfo & { id: string};
export type SaveExtensionInfo = BaseExtensionInfo & { id?: string};
export enum ExtensionLocation {
    QuickStart = 1,
    PortfoliosPageActions = 2,
    PortfoliosPageViews = 3,
    PortfolioDashboardHeaderAction = 4,
    PortfolioDashboardActionItems = 5,
    PortfolioDashboardKeyDates = 6,
    PortfolioDashboardKeyDecisions = 7,
    PortfolioDashboardProgramsProjects = 8,
    PortfolioDashboardResourcePlan = 9,
    PortfolioDashboardRisks = 10,
    ProgramsPageActions = 11,
    ProgramsPageViews = 12,
    ProgramDashboardHeaderAction = 13,
    ProgramDashboardActionItems = 14,
    ProgramDashboardKeyDates = 15,
    ProgramDashboardKeyDecisions = 16,
    ProgramDashboardProjects = 17,
    ProgramDashboardResourcePlan = 18,
    ProgramDashboardRisks = 19,
    ProjectsPageActions = 20,
    ProjectsPageViews = 21,
    ProjectDashboardHeaderAction = 22,
    ProjectDashboardActionItems = 23,
    ProjectDashboardChangeRequests = 24,
    ProjectDashboardDependencies = 25,
    ProjectDashboardIssues = 26,
    ProjectDashboardInvoices = 27,
    ProjectDashboardIterations = 28,
    ProjectDashboardKeyDates = 29,
    ProjectDashboardKeyDecisions = 30,
    ProjectDashboardLessonsLearned = 31,
    ProjectDashboardPurchaseOrders = 32,
    ProjectDashboardResourcePlan = 33,
    ProjectDashboardRisks = 34,
    ProjectDashboardSteeringCommittee = 35,
    TasksPageTasks = 36,
    BusinessChallengesPageActions = 37,
    BusinessChallengesPageViews = 38,
    BusinessChallengeDashboardHeaderAction = 39,
    BusinessChallengeDashboardIdeasSection = 40,
    IdeaDashboardHeaderAction = 41,
    IdeaDashboardResourcePlan = 42,
    IdeaDashboardRisks = 43
}

export const findExtension = (extensions: ExtensionsState, entityType: EntityType, location: ExtensionPageLocation | EntityType): ExtensionInfo[] =>
    extensions.entities.filter(_ => _.location === EntityTypeToLocationMap[entityType]?.[location]);

export enum ExtensionPageLocation {
    Actions,
    Views,
    EntityAction
}

export const EntityTypeToLocationMap = {
    [EntityType.Portfolio]: {
        [ExtensionPageLocation.Actions]: ExtensionLocation.PortfoliosPageActions,
        [ExtensionPageLocation.Views]: ExtensionLocation.PortfoliosPageViews,
        [ExtensionPageLocation.EntityAction]: ExtensionLocation.PortfolioDashboardHeaderAction,
        [EntityType.ActionItem]: ExtensionLocation.PortfolioDashboardActionItems,
        [EntityType.KeyDate]: ExtensionLocation.PortfolioDashboardKeyDates,
        [EntityType.KeyDecision]: ExtensionLocation.PortfolioDashboardKeyDecisions,
        [EntityType.Program]: ExtensionLocation.PortfolioDashboardProgramsProjects,
        [EntityType.Resource]: ExtensionLocation.PortfolioDashboardResourcePlan,
        [EntityType.Risk]: ExtensionLocation.PortfolioDashboardRisks
    },
    [EntityType.Program]: {
        [ExtensionPageLocation.Actions]: ExtensionLocation.ProgramsPageActions,
        [ExtensionPageLocation.Views]: ExtensionLocation.ProgramsPageViews,
        [ExtensionPageLocation.EntityAction]: ExtensionLocation.ProgramDashboardHeaderAction,
        [EntityType.ActionItem]: ExtensionLocation.ProgramDashboardActionItems,
        [EntityType.KeyDate]: ExtensionLocation.ProgramDashboardKeyDates,
        [EntityType.KeyDecision]: ExtensionLocation.ProgramDashboardKeyDecisions,
        [EntityType.Project]: ExtensionLocation.ProgramDashboardProjects,
        [EntityType.Resource]: ExtensionLocation.ProgramDashboardResourcePlan,
        [EntityType.Risk]: ExtensionLocation.ProgramDashboardRisks
    },
    [EntityType.Project]: {
        [ExtensionPageLocation.Actions]: ExtensionLocation.ProjectsPageActions,
        [ExtensionPageLocation.Views]: ExtensionLocation.ProjectsPageViews,
        [ExtensionPageLocation.EntityAction]: ExtensionLocation.ProjectDashboardHeaderAction,
        [EntityType.ActionItem]: ExtensionLocation.ProjectDashboardActionItems,
        [EntityType.ChangeRequest]: ExtensionLocation.ProjectDashboardChangeRequests,
        [EntityType.Dependency]: ExtensionLocation.ProjectDashboardDependencies,
        [EntityType.Issue]: ExtensionLocation.ProjectDashboardIssues,
        [EntityType.Invoice]: ExtensionLocation.ProjectDashboardInvoices,
        [EntityType.Iteration]: ExtensionLocation.ProjectDashboardIterations,
        [EntityType.KeyDate]: ExtensionLocation.ProjectDashboardKeyDates,
        [EntityType.KeyDecision]: ExtensionLocation.ProjectDashboardKeyDecisions,
        [EntityType.LessonLearned]: ExtensionLocation.ProjectDashboardLessonsLearned,
        [EntityType.PurchaseOrder]: ExtensionLocation.ProjectDashboardPurchaseOrders,
        [EntityType.Resource]: ExtensionLocation.ProjectDashboardResourcePlan,
        [EntityType.Risk]: ExtensionLocation.ProjectDashboardRisks,
        [EntityType.SteeringCommittee]: ExtensionLocation.ProjectDashboardSteeringCommittee
    },
    [EntityType.Task]: {
        [EntityType.Task]: ExtensionLocation.TasksPageTasks,
    },
    [EntityType.Challenge]: {
        [ExtensionPageLocation.Actions]: ExtensionLocation.BusinessChallengesPageActions,
        [ExtensionPageLocation.Views]: ExtensionLocation.BusinessChallengesPageViews,
        [ExtensionPageLocation.EntityAction]: ExtensionLocation.BusinessChallengeDashboardHeaderAction,
        [EntityType.Idea]: ExtensionLocation.BusinessChallengeDashboardIdeasSection
    },
    [EntityType.Idea]: {
        [ExtensionPageLocation.EntityAction]: ExtensionLocation.IdeaDashboardHeaderAction,
        [EntityType.Resource]: ExtensionLocation.IdeaDashboardResourcePlan,
        [EntityType.Risk]: ExtensionLocation.IdeaDashboardRisks
    }
};

export enum ExtensionType {
    EmbeddedContent = 0,
    TriggerAction = 1,
    OpenNewTab = 2
}

export const actionCreators = {
    saveExtension: (extension: SaveExtensionInfo): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        post<ExtensionInfo[]>(`api/metadata/extensions`, extension)
            .then(data => dispatch({ type: 'UPDATE_EXTENSION', addOrUpdate: data }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'PROCESSING_EXTENSIONS'}));
    },
    removeExtension: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        remove<never>(`api/metadata/extensions`, { ids })
            .then(_ => dispatch({ type: 'UPDATE_EXTENSION', remove: ids }))
            .catch(defaultCatch(dispatch));
        dispatch(({ type: 'PROCESSING_EXTENSIONS'}));
    }
};

export const reducer: Reducer<ExtensionsState> = (state = initialState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "UPDATE_EXTENSION":
            return {
                ...state,
                ...initialState,
                entities: addOrUpdateOrRemove(state.entities, action.addOrUpdate, action.remove)
            };         
        case "PROCESSING_EXTENSIONS":
            return {
                ...state,
                isProcessing: true
            };
        default:
            return state;
    }
};
import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as IdeasListStore from '../../store/IdeasListStore';
import * as ChallengesListStore from '../../store/ChallengesListStore';
import { LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import { Dictionary, EntityType, Impact } from "../../entities/common";
import { buildActions, buildRiskActions } from "../../entities/Subentities";
import * as Notifications from "../../store/NotificationsStore";
import { DetailsSpinner } from "../common/Spinner";
import * as LayoutsStore from '../../store/layouts';
import * as ResourcePlanControl from '../common/sectionsControl/uiControls/ResourcePlanControl';
import * as StrategicAlignmentControl from '../common/sectionsControl/uiControls/StrategicAlignmentControl';
import * as RisksControl from '../common/sectionsControl/uiControls/RisksControl';
import * as LinkedRoadmapControl from '../common/sectionsControl/uiControls/LinkedRoadmapControl';
import { ProjectCalculation } from '../../store/ProjectsListStore';
import { bindActionCreators } from 'redux';
import BackButton from '../navigation/BackButton';
import { buildIWithBenefitsCustomRenders, buildIWithBenefitsValidators, rendersBuilder, validators } from '../field/Fields';
import { ViewTypeViews, buildViewTypeSelect, withViewType } from '../common/ViewTypeSelect';
import { inIframe } from '../utils/common';
import { UserState, isInReadonlyMode } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import * as CalendarStore from '../../store/CalendarStore';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { Views } from '../../store/services/viewSaver';
import ExternalEpmConnectControl from '../idea/ExternalEpmConnectControl';
import { SourceType } from '../../store/ExternalEpmConnectStore';
import { IFinancialsConfiguration } from '../common/FinancialsContent';
import * as EmbeddedContentControl from '../common/sectionsControl/uiControls/EmbeddedContentControl';
import { withLinkedRoadmapSettings } from '../common/sectionsControl/uiControls/LinkedRoadmapControl';
import IdeaHeader from './IdeaHeader';
import { IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';

type StateProps = {
    user: UserState;
    entity?: IdeasListStore.Idea;
    isLoading: boolean;
    isUpdatingSections: boolean;
    layouts: LayoutsStore.LayoutsState;
    isReadonlyMode: boolean;
}

type ActionProps = {
    ideasActions: typeof IdeasListStore.actionCreators;
    challengeActions: typeof ChallengesListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    calendarActions: typeof CalendarStore.actionCreators;
};

type IdeaDetailsProps = RouteComponentProps<{ id: string }>
    & StateProps
    & ActionProps;

type IdeaDetailsState = {
    isCofigurationPanelOpen: boolean;
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    StrategicAlignmentControl: StrategicAlignmentControl.IConfiguration;
    RisksControl: RisksControl.IConfiguration;
    BudgetControl: IFinancialsConfiguration;
    BenefitsControl: IFinancialsConfiguration;
    ResourcePlanControl: ResourcePlanControl.IConfiguration;
    EmbeddedContentControl: EmbeddedContentControl.IConfiguration;
    LinkedRoadmapControl: LinkedRoadmapControl.IConfiguration;
}

const entityName = EntityType.Idea;
class IdeaDetails extends React.Component<IdeaDetailsProps, IdeaDetailsState> {

    constructor(props: IdeaDetailsProps) {
        super(props);
        this.state = {
            isCofigurationPanelOpen: false
        };
    }

    componentWillMount() {
        this.props.ideasActions.loadIdea(this.props.match.params.id);
        this.props.calendarActions.load();
    }

    componentWillReceiveProps(nextProps: IdeaDetailsProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.props.ideasActions.loadIdea(nextProps.match.params.id);
        }
    }

    public render() {
        const { entity, isLoading, layouts, isUpdatingSections } = this.props;
        return <DetailsSpinner isLoading={isLoading}>
            {entity && <IdeaHeader
                entity={entity}
                actions={{
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    layoutActions: {
                        applyLayout: this.applyLayout,
                        viewLayout: this.viewLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    removeIdea: this.removeIdea,
                    changeVote: this.changeVote,
                    proposeIdea: this.proposeIdea,
                    activateIdea: this.activateIdea,
                    selectIdea: this.selectIdea,
                    rejectIdea: this.rejectIdea,
                    initiateProject: this.initiateProject,
                    deactivateIdea: this.deactivateIdea,
                    reactivateIdea: this.reactivateIdea,
                    markAsDraftIdea: this.markAsDraftIdea,
                    openConfigurationPanel: this.openConfigurationPanel,
                }}
            />}
            {inIframe() && <BackButton />}
            {entity?.sections &&
                <LayoutableSectionsContainer
                    key="sections"
                    entity={entity}
                    entityType={EntityType.Idea}
                    layouts={layouts}
                    isUpdatingSections={isUpdatingSections}
                    controlsConfig={this._buildControlsConfigurations()}
                />}
            {entity && this.state.isCofigurationPanelOpen &&
                <ExternalEpmConnectControl
                    entity={entity}
                    onDismiss={() => { this.setState({ isCofigurationPanelOpen: false }); }}
                    visibleTypes={[SourceType.File]}
                />}
        </DetailsSpinner>
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { entity, ideasActions, layouts, user, isReadonlyMode } = this.props;
        const analyticsProps = { user: user, parentType: EntityType.Idea };
        const { entityId, entityActions, actions } = buildActions(entity!.id, isReadonlyMode, analyticsProps, layouts, ideasActions, ideasActions.loadIdea);
        return {
            ['ResourcePlanControl']: {
                ...(contains(user.permissions.common, CommonOperations.ResourceView)
                    ? buildViewTypeSelect(ViewTypeViews.ResourcePlanControl)
                    : undefined),
                datacontext: { entityType: EntityType.Idea },
                actions: {
                    addResources: (resourceIds) =>
                        this.props.ideasActions.addResources(this.props.entity!.id, resourceIds),
                    createResource: (name, layoutId, auto, startDate, finishDate) =>
                        this.props.ideasActions.createResourceAndAdd(this.props.entity!.id, name, layoutId, auto, startDate, finishDate),
                    replaceResources: (prev, next, keepRate, sumUpPlanHours) =>
                        this.props.ideasActions.replaceResource(this.props.entity!.id, prev, next, keepRate, sumUpPlanHours),
                    removeResources: ids => this.props.ideasActions.removeResources(this.props.entity!.id, ids),
                    updateUIControl: entityActions.updateUIControl
                }
            },
            ['FieldsArea']: {
                datacontext: {
                    entityId,
                    entityType: EntityType.Idea
                },
                customFieldValidator: {...validators, ...buildIWithBenefitsValidators()},
                elementCustomRender: {...rendersBuilder(), ...buildIWithBenefitsCustomRenders()},
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditComplete
                }
            },
            ["StrategicAlignmentControl"]: {
                actions: {
                    updatePriorityAlignment: this.updatePriorityAlignment,
                    recalculateAlignmentScore: this.recalculateAlignmentScore,
                }
            },
            ["RisksControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildRiskActions(entityId, analyticsProps, actions, ideasActions)
            },
            ["BudgetControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ["BenefitsControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ['EmbeddedContentControl']: {
                datacontext: { entityType: EntityType.Idea },
                helpUrl: "https://help.ppm.express/89502-ppm-express-how-to-articles/2416583",
                actions: {
                    updateUiControl: entityActions.updateUIControl
                }
            },
            ['LinkedRoadmapControl']:
                withLinkedRoadmapSettings(entity, EntityType.Idea, user, this.props.isReadonlyMode, entityActions.updateUIControl)
        }
    }

    private updatePriorityAlignment = (strategicPriorityId: string, impact: Impact) => {
        this.props.ideasActions.updatePriorityAlignment(this.props.entity!.id, strategicPriorityId, impact);
    }

    private recalculateAlignmentScore = () => {
        this.props.ideasActions.recalculateAlignmentScore(this.props.entity!.id);
    }

    private onEditComplete = (fieldName: string, fieldValue: any): void => {
        if (!fieldName) {
            return;
        }

        this.props.ideasActions.updateIdeaAttributes(this.props.entity!.id, { [fieldName]: fieldValue });
    }

    private applyLayout = (layout: Metadata.Layout) => {
        this.props.ideasActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.ideasActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.ideasActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.ideasActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Metadata.Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: Notifications.NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Metadata.Section[], update: Metadata.IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: Notifications.NotificationType.Info
        });
    }

    private removeIdea = () => {
        this.props.challengeActions.removeIdea(this.props.entity!.attributes.Challenge.id, [this.props.entity!.id], true);
    }

    private updateImage = (logo: File) => {
        this.props.ideasActions.updateImage(this.props.entity!.id, logo);
    }

    private removeImage = () => {
        this.props.ideasActions.removeImage(this.props.entity!.id);
    }

    private changeVote = (isVote: boolean) => {
        this.props.ideasActions.changeVote(this.props.entity!.id, isVote);
    }

    private updateCalculation = (changes: Partial<ProjectCalculation>) => {
        this.props.ideasActions.updateCalculation(this.props.entity!.id, changes);
    }

    private proposeIdea = () => {
        this.props.ideasActions.proposeIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Proposed });
    }

    private activateIdea = () => {
        this.props.ideasActions.activateIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Active });
    }

    private selectIdea = () => {
        this.props.ideasActions.selectIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Selected });
    }

    private rejectIdea = () => {
        this.props.ideasActions.rejectIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.NotSelected });
    }

    private initiateProject = () => {
        this.props.ideasActions.initiateProject(this.props.entity!.id);
    }

    private deactivateIdea = () => {
        this.props.ideasActions.deactivateIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Archived });
    }

    private reactivateIdea = () => {
        this.props.ideasActions.reactivateIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Active });
    }

    private markAsDraftIdea = () => {
        this.props.ideasActions.markAsDraftIdea(this.props.entity!.id);
        analytics.trackEvent("Updated Idea Stage", this.props.user, { stageValue: IdeasListStore.IdeaStage.Draft });
    }

    private openConfigurationPanel = () => {
        this.setState({ isCofigurationPanelOpen: true });
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    return {
        user: state.user,
        entity: state.ideas.activeEntity && state.ideas.activeEntity.id === ownProps.match.params.id ? state.ideas.activeEntity : undefined,
        isLoading: state.ideas.isLoading || !!state.layouts[entityName].isApplyingLayout,
        isUpdatingSections: state.ideas.isUpdatingSections,
        layouts: state.layouts[entityName],
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(
    mapStateToProps, (dispatch): ActionProps =>
({
    ideasActions: bindActionCreators(IdeasListStore.actionCreators, dispatch),
    challengeActions: bindActionCreators(ChallengesListStore.actionCreators, dispatch),
    notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
    layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
    calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch)
}))(IdeaDetails);
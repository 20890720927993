import React from 'react';
import anchorme from "anchorme";

type Props = {
    isMultiline?: boolean;
    isMultichoice?: boolean;
    withNavigation?: boolean;
    value?: string[] | string | null;
    urlTextMaxLen?: number;
    hideTitle?: boolean;
};

export const TextFormatter = (props: Props) => {
    const value = !props.value
        ? undefined
        : props.isMultichoice && Array.isArray(props.value)
            ? props.value.join(', ')
            : Array.isArray(props.value)
                ? props.value.join()
                : props.value;
    
    const className = props.isMultiline ? 'multiline' : '';

    const html = value && props.withNavigation && anchorme({
        input: value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;"),
        options: {
            truncate: props.urlTextMaxLen,
            exclude: (arg) => {
                if (anchorme.validate.ip(arg) || isPseudoIp(arg)) {
                    return true;
                }
                return false;
            },
            attributes: {
                target: "_blank",
                class: "text-link"
            },
        }});

    return (
        html
            ? <span
                className={className}
                title={props.hideTitle ? undefined : value}
                dangerouslySetInnerHTML={{ __html: html }}
            />
            : <span className={className} title={props.hideTitle ? undefined : value}>{value}</span>
    );
}

// checks if arg contains digits and dots only one after the other (i.e. d.d.d.d.d)
const isPseudoIp = (arg: string): boolean => /^\d+(\.\d+)*$/gm.test(arg);
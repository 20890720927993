import * as React from "react";
import { TextField, Link, MessageBar, MessageBarType, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { ExtensionInfo, ExtensionLocation, ExtensionType, SaveExtensionInfo } from "../../../../store/ExtensionStore";
import { UserState } from "../../../../store/User";
import { Subscription } from "../../../../store/Tenant";
import { Validator } from "../../../../validation";
import { ItemCreation } from "../../../common/ItemCreation";
import LabellableComponent from "../../../common/LabellableComponent";
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import { distinct } from "../../../utils/common";
import { GroupedDropdown, GroupedDropdownOptions } from "../../../common/GroupedDropdown";

type OwnProps = {
    extension?: ExtensionInfo;
    onSave: (entity: SaveExtensionInfo) => void;
    onDismiss: () => void;
}
type StateProps = {
    extensions: ExtensionInfo[];
    user: UserState;
    subscription: Subscription;
    extensionLocations: ExtensionLocation[];
};
type Props = OwnProps & StateProps;

const validator = Validator.new().required().build();
const urlValidator = Validator.new().required().url().build();
const typeValidator = Validator.new().custom(_ => _.type !== undefined, "This field is required").custom(
    ext => ext.location !== ExtensionLocation.QuickStart || ext.type === ExtensionType.EmbeddedContent,
    "Quick Start extension should be of type 'Embedded Content'"
).build();

const ExtensionEdit = (props: Props) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [extension, setExtension] = React.useState<SaveExtensionInfo>(props.extension
        ? { ...props.extension }
        : {
            name: "",
            url: "",
            location: undefined as any as ExtensionLocation,
            type: ExtensionType.EmbeddedContent
        });
    const setExtensionAndDirty = React.useCallback((newExtension: SaveExtensionInfo) => {
        setIsDirty(true);
        setExtension(newExtension);
    }, []);
    const canManage = true;

    const groupedLocationOptions: GroupedDropdownOptions = React.useMemo(() => {
        const groupedLocationOptionsMap = locationOptions.reduce((groups, option) =>
            ({ ...groups, [option.groupKey ?? option.key]: [...(groups[option.groupKey ?? option.key] ?? []), option] }), {});
        return Object.keys(groupedLocationOptionsMap).map(key =>
        (locationGroups[key]
            ? {
                text: locationGroups[key],
                items: groupedLocationOptionsMap[key]
            }
            : groupedLocationOptionsMap[key][0]));
    }, [locationOptions]);

    const locationValidator = React.useMemo(() => Validator.new()
        .custom(_ => _.location !== undefined, "This field is required")
        .custom(ext => ext.location !== ExtensionLocation.QuickStart
            || !props.extensions.find(_ => _.location === ExtensionLocation.QuickStart && _.id !== ext.id),
            "Quick Start extension already exists").build(), [props.extensions]);

    const isValid = React.useMemo(() => {
        return validator.isValid(extension.name)
            && urlValidator.isValid(extension.url)
            && locationValidator.isValid(extension)
            && typeValidator.isValid(extension);
    }, [props.extensions, extension]);

    return (
        <ItemCreation
            onDismiss={props.onDismiss}
            header={{
                text: extension.id ? "Edit Extension" : "New Extension",
                secondaryText: <div>
                    Manage the settings below to configure an extension.
                    Please refer to <Link href='https://help.ppm.express/89495-ppm-express-settings/522610' target='_blank'>this article</Link> for more details.
                </div>,
                nameEditorLabel: "Extension Name",
                value: extension.name,
                onChanged: (value) => setExtensionAndDirty({ ...extension, name: value }),
                validator: validator
            }}
            isDirty={isDirty}
            commands={[
                {
                    primary: true,
                    disabled:
                        !canManage ||
                        !isValid,
                    text: extension.id ? "Save Extension" : "Create Extension",
                    onClick: () => {
                        props.onSave(extension);
                        props.onDismiss();
                    },
                },
                {
                    text: "Cancel",
                    onClick: props.onDismiss,
                },
            ]}
        >
            <div className="panel-area">
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="Extension Type"
                        description={`Select the type of extension - extension functionality will depend on its type.

Embed Content - for such extension embedded content (web page, third party app,e.g.) will be displayed in the panel within PPM Express.

Trigger Action - for such extension type an external action or flow will be triggered.

Open Action in the New Tab - for such extension provided URL will be opened in a new tab. `}
                    >
                        <Dropdown
                            selectedKey={extension.type}
                            options={typeOptions}
                            errorMessage={typeValidator.getErrorMessage(extension)}
                            disabled={!canManage}
                            onChange={(e, option) => {
                                if (option) {
                                    setExtensionAndDirty({ ...extension, type: option.key as ExtensionType });
                                }
                            }}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="URL"
                        required
                        description="Enter the URL of the page that contains the content to be displayed on the PPM Express page or section specified in the 'Extension Location' field."
                    >
                        <TextField
                            value={extension.url}
                            onGetErrorMessage={urlValidator.getErrorMessage}
                            onChange={(e, value) => {
                                setExtensionAndDirty({ ...extension, url: value || "" });
                            }}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="Extension Location"
                    >
                        <GroupedDropdown
                            selectedKey={extension.location}
                            disabled={!canManage}
                            onGetErrorMessage={() => locationValidator.getErrorMessage(extension)}
                            groupedOptions={groupedLocationOptions}
                            onChange={(e, option) => {
                                if (option) {
                                    setExtensionAndDirty({ ...extension, location: option.key as ExtensionLocation });
                                }
                            }}
                        />
                    </LabellableComponent>
                </div>
                <div className="grid-item">
                    <LabellableComponent
                        className="field-container"
                        label="Description"
                        description="The text added to this field will be displayed as a hint when hovering over the extension title on the page where it is added."
                    >
                        <TextField
                            value={extension.description}
                            onChange={(e, value) => {
                                setExtensionAndDirty({ ...extension, description: value || "" });
                            }}
                            disabled={!canManage}
                        />
                    </LabellableComponent>
                </div>
            </div>
        </ItemCreation>
    );
};

function mapStateToProps(state: ApplicationState, ownProps?: OwnProps): StateProps {
    return {
        extensions: state.extensions.entities,
        subscription: state.tenant.subscription,
        user: state.user,
        extensionLocations: state.extensions.entities.map((extension) => extension.location).filter(distinct),
    };
}

export default connect(mapStateToProps)(ExtensionEdit);

export const locationGroups: Record<string, string> = {
    ["portfoliosPage"]: "Portfolios Page",
    ["portfolioDashboard"]: "Portfolio Dashboard",
    ["programsPage"]: "Programs Page",
    ["programDashboard"]: "Program Dashboard",
    ["projectsPage"]: "Projects Page",
    ["projectDashboard"]: "Project Dashboard",
    ["tasksPage"]: "Tasks Page",
    ["businessChallengesPage"]: "Business Challenges Page",
    ["businessChallengeDashboard"]: "Business Challenge Dashboard",
    ["ideaDashboard"]: "Idea Dashboard",
};

export const locationOptions: { key: ExtensionLocation, text: string, groupKey?: string }[] = [
    {
        key: ExtensionLocation.QuickStart,
        text: "Quick Start",
    },
    {
        key: ExtensionLocation.PortfoliosPageActions,
        text: "Actions",
        groupKey: "portfoliosPage",
    },
    {
        key: ExtensionLocation.PortfoliosPageViews,
        text: "Views",
        groupKey: "portfoliosPage",
    },
    {
        key: ExtensionLocation.PortfolioDashboardHeaderAction,
        text: "Actions",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardActionItems,
        text: "Action Items",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardKeyDates,
        text: "Key Dates",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardKeyDecisions,
        text: "Key Decisions",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardProgramsProjects,
        text: "Programs & Projects",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardResourcePlan,
        text: "Resource Plan",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.PortfolioDashboardRisks,
        text: "Risks",
        groupKey: "portfolioDashboard",
    },
    {
        key: ExtensionLocation.ProgramsPageActions,
        text: "Actions",
        groupKey: "programsPage",
    },
    {
        key: ExtensionLocation.ProgramsPageViews,
        text: "Views",
        groupKey: "programsPage",
    },
    {
        key: ExtensionLocation.ProgramDashboardHeaderAction,
        text: "Actions",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardActionItems,
        text: "Action Items",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardKeyDates,
        text: "Key Dates",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardKeyDecisions,
        text: "Key Decisions",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardProjects,
        text: "Projects",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardResourcePlan,
        text: "Resource Plan",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProgramDashboardRisks,
        text: "Risks",
        groupKey: "programDashboard",
    },
    {
        key: ExtensionLocation.ProjectsPageActions,
        text: "Actions",
        groupKey: "projectsPage",
    },
    {
        key: ExtensionLocation.ProjectsPageViews,
        text: "Views",
        groupKey: "projectsPage",
    },
    {
        key: ExtensionLocation.ProjectDashboardHeaderAction,
        text: "Actions",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardActionItems,
        text: "Action Items",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardChangeRequests,
        text: "Change Requests",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardDependencies,
        text: "Dependencies",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardIssues,
        text: "Issues",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardInvoices,
        text: "Invoices",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardIterations,
        text: "Iterations",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardKeyDates,
        text: "Key Dates",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardKeyDecisions,
        text: "Key Decisions",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardLessonsLearned,
        text: "Lessons Learned",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardPurchaseOrders,
        text: "Purchase Orders",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardResourcePlan,
        text: "Resource Plan",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardRisks,
        text: "Risks",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.ProjectDashboardSteeringCommittee,
        text: "Steering committee",
        groupKey: "projectDashboard",
    },
    {
        key: ExtensionLocation.TasksPageTasks,
        text: "Tasks",
        groupKey: "tasksPage",
    },
    {
        key: ExtensionLocation.BusinessChallengesPageActions,
        text: "Actions",
        groupKey: "businessChallengesPage",
    },
    {
        key: ExtensionLocation.BusinessChallengesPageViews,
        text: "Views",
        groupKey: "businessChallengesPage",
    },
    {
        key: ExtensionLocation.BusinessChallengeDashboardHeaderAction,
        text: "Actions",
        groupKey: "businessChallengeDashboard",
    },
    {
        key: ExtensionLocation.BusinessChallengeDashboardIdeasSection,
        text: "Ideas Section",
        groupKey: "businessChallengeDashboard",
    },
    {
        key: ExtensionLocation.IdeaDashboardHeaderAction,
        text: "Actions",
        groupKey: "ideaDashboard",
    },
    {
        key: ExtensionLocation.IdeaDashboardResourcePlan,
        text: "Resource Plan",
        groupKey: "ideaDashboard",
    },
    {
        key: ExtensionLocation.IdeaDashboardRisks,
        text: "Risks",
        groupKey: "ideaDashboard",
    },
];

export const typeOptions: IDropdownOption[] = [
    {
        key: ExtensionType.EmbeddedContent,
        text: "Embedded Content"
    },
    {
        key: ExtensionType.TriggerAction,
        text: "Action"
    },
    {
        key: ExtensionType.OpenNewTab,
        text: "Open in New Tab"
    }
];
import React, { useMemo, useState } from "react";
import ListMenu from "../../components/common/extensibleEntity/ListMenu";
import { EntityType } from "../../entities/common";
import FlagInput from "../common/inputs/FlagInput";
import { ApplicationState } from "../../store";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { UserState } from "../../store/User";
import { CommonOperations, contains } from "../../store/permissions";
import { IContextualMenuItem, Icon, } from "office-ui-fabric-react";
import LabellableFieldComponent from "../common/sectionsControl/uiControls/fieldsArea/LabellableFieldComponent";
import { notUndefined } from "../utils/common";
import { IsEnterpriseTimeTracker } from "../../store/TimeTrackingStore";
import ConfigureCustomFieldsPanel from "./ConfigureCustomFieldsPanel";

interface OwnProps {
    onImportTasks: () => void;
    onAddCustom: () => void;

    onAddAdministrative: () => void;

    mergeSmartSuggestions: boolean;
    setMergeSmartSuggestions: (newValue: boolean) => void;

    onFieldsUpdate: () => void;

    isReadonly: boolean;
    showMergeSmartSuggestions: boolean;
}

interface StateProps {
    user: UserState,
    isEnterpriseTimeTracker: boolean;
}

type Props = OwnProps & StateProps & RouteComponentProps<{}>;

function TimeTrackingMenu(props: Props) {
    const { user, isEnterpriseTimeTracker, onImportTasks, onAddCustom, onAddAdministrative, mergeSmartSuggestions, setMergeSmartSuggestions, isReadonly, onFieldsUpdate, showMergeSmartSuggestions } = props;

    const [isConfigureMode, setIsConfigureMode] = useState(false);

    const canViewCustomFields = contains(user.permissions.common, CommonOperations.TimeTrackingView)
        && contains(user.permissions.common, CommonOperations.ConfigurationView)
        && isEnterpriseTimeTracker;

    const farCommands: IContextualMenuItem[] = useMemo(() => {
        return [
            showMergeSmartSuggestions ?
                {
                    key: "mergeSmartSuggestions",
                    disabled: false,
                    onRender: () => (
                        <div className="smart-suggestion align-center">
                            <Icon iconName="PPMXSmartSuggestion" />
                  
                            <LabellableFieldComponent
                                label="Smart Suggestions"
                                description={"Use 'Smart Suggestions' to automatically add tasks assigned to you directly to your Timesheet, streamlining your workflow."} >
                        
                                <FlagInput value={mergeSmartSuggestions} onEditComplete={_ => setMergeSmartSuggestions(_!)} />
                            </LabellableFieldComponent>
                        </div>
                    )
                }
                : undefined,
            canViewCustomFields
                ?
                {
                    key: "manageFields",
                    name: "Configure Custom Fields",
                    iconProps: { iconName: "Settings" },
                    onClick: () => setIsConfigureMode(true)
                }
                : undefined
        ].filter(notUndefined)
    }, [canViewCustomFields, mergeSmartSuggestions, showMergeSmartSuggestions]);
    
    return (
        <>
            <ListMenu
                entityType={EntityType.TimeTrackingEntry}
                commands={[
                    {
                        key: "addTask",
                        name: `Import Task`,
                        disabled: isReadonly,
                        iconProps: { iconName: "PPMXImport" },
                        onClick: onImportTasks
                    },
                
                    {
                        key: "addNonWorking",
                        name: "Add Administrative Time Entry",
                        disabled: isReadonly,
                        iconProps: { iconName: "Add" },
                        onClick: onAddAdministrative
                    },

                    {
                        key: "addCustomEvent",
                        name: "Add Custom Time Entry",
                        disabled: isReadonly,
                        iconProps: { iconName: "Add" },
                        onClick: onAddCustom
                    }
                ]}
                farCommands={farCommands}
            />
            {
                isConfigureMode && <ConfigureCustomFieldsPanel
                    onFieldsUpdate={onFieldsUpdate}
                    onDismiss={() => setIsConfigureMode(false)} 
                />
            }
        </>
    );
}
function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps { 
    return {
        user: state.user,
        isEnterpriseTimeTracker: IsEnterpriseTimeTracker(state.tenant.subscription)
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps)(TimeTrackingMenu));

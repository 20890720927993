import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators } from 'redux';
import { LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import { Field, Group, IBaseGroupInfo, IUpdateLayoutInfo, IUpdateSectionInfo, Layout, Section } from '../../entities/Metadata';
import { StatusCategory, Dictionary, EntityType, IPatch, mapServerEntityType, Quantization, ServerEntityType } from "../../entities/common";
import { DetailsSpinner } from "../common/Spinner";
import * as KPIControl from '../common/sectionsControl/uiControls/KPIControl';
import * as RoadmapItemsControl from '../common/sectionsControl/uiControls/RoadmapItemsControl';
import RoadmapHeader from './RoadmapHeader';
import { rendersBuilder as commonRendersBuilder, validators } from '../field/Fields';
import { rendersBuilder } from '../objective/Fields';
import { LayoutService } from '../utils/LayoutService';
import { UserState, isInReadonlyMode } from '../../store/User';
import {
    actionCreators as RoadmapsActionCreators, EntityDescriptor, EntityLinkDescriptor, isPlanned, labelsCreators, lanesCreators,
    MilestonesLaneId
} from '../../store/RoadmapsListStore';
import { actionCreators as NotificationsActionCreators, NotificationType } from "../../store/NotificationsStore";
import { IRoadmapAttrs, Roadmap, RoadmapStage } from '../../store/roadmap/common';
import { nameof } from '../../store/services/metadataService';
import * as LayoutsStore from '../../store/layouts';
import * as CalendarStore from '../../store/CalendarStore';
import * as ViewsStore from '../../store/views';
import { buildUpdateUIControl, IRoadmapItem, IRoadmapItemAttrs, IRoadmapItemDependency } from '../../entities/Subentities';
import RoadmapItemsImportPanel from './RoadmapItemsImportPanel';
import { ICreationData } from '../../store/Subentity';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { TenantState } from '../../store/Tenant';
import { ViewTypeViews, buildUrlViewTypeHeaderRender, viewTypeSettingsBuilder } from '../common/ViewTypeSelect';
import { mergeDefault } from '../../store/utils';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { defaultStyleSettingValues } from './RoadmapItemsList';
import { IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';

type StateProps = {
    tenant: TenantState;
    user: UserState;
    entity?: Roadmap;
    isLoading: boolean;
    roadmapFields: Field[];
    roadmapItemFields: Field[];
    layouts: LayoutsStore.LayoutsState;
    lanes: Group[] | null;
    labels: Group[] | null;
    isUpdatingSections: boolean;
    isReadonlyMode: boolean;
};

type State = {
    sectionsState: Dictionary<Dictionary<any>>;
    roadmapItemsByExternalId: Dictionary<IRoadmapItem>;
};

type ActionProps = {
    roadmapsActions: typeof RoadmapsActionCreators;
    notificationsActions: typeof NotificationsActionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    lanesActions: typeof lanesCreators;
    labelsActions: typeof labelsCreators;
    calendarActions: typeof CalendarStore.actionCreators;
    roadmapItemsViewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};

type Props = RouteComponentProps<{ id: string }>
    & StateProps
    & ActionProps;

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    KPIControl: KPIControl.IConfiguration;
    RoadmapItemsControl: RoadmapItemsControl.IConfiguration
}

const entityName = EntityType.Roadmap;
class RoadmapDetails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sectionsState: {},
            roadmapItemsByExternalId: {}
        };
    }

    componentWillMount() {
        this.props.calendarActions.load();

        const roadmapId = this.props.match.params.id;
        this.props.roadmapsActions.loadRoadmap(roadmapId);
    }

    componentWillReceiveProps(nextProps: Props) {
        const nextRoadmapId = nextProps.match.params.id;
        if (nextRoadmapId !== this.props.match.params.id) {
            this.props.roadmapsActions.loadRoadmap(nextRoadmapId);
        }

        if (this.props.entity !== nextProps.entity) {
            this.setState({ roadmapItemsByExternalId: this._buildRoadmapItemsByExternalId(nextProps.entity?.roadmapItems) });
        }

        const layoutId = this.getLayoutId(this.props);
        const newLayoutId = this.getLayoutId(nextProps);
        if (this.props.entity?.canConfigure !== nextProps.entity?.canConfigure || layoutId !== newLayoutId) {
            if (!nextProps.entity?.canConfigure && newLayoutId) {
                this.props.layoutsActions.viewLayout(newLayoutId);
            }
            else {
                this.props.layoutsActions.viewLayout();
            }
        }
    }

    public render() {
        const { entity, isLoading, layouts, isUpdatingSections } = this.props;
        return <DetailsSpinner isLoading={isLoading}>
            {entity && <RoadmapHeader
                entity={entity}
                actions={{
                    layoutActions: {
                        viewLayout: this.viewLayout,
                        applyLayout: this.applyLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    cloneRoadmap: this.cloneRoadmap,
                    removeRoadmap: this.removeRoadmap,
                    updateRoadmapStage: this.updateRoadmapStage,
                }}
            />}
            {entity && entity.sections &&
                <LayoutableSectionsContainer
                    key="sections"
                    entity={entity}
                    entityType={EntityType.Roadmap}
                    layouts={layouts}
                    isUpdatingSections={isUpdatingSections}
                    controlsConfig={this._buildControlsConfigurations()}
                />}
        </DetailsSpinner>
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { entity, roadmapsActions, layouts, isReadonlyMode } = this.props;
        const updateUIControl = buildUpdateUIControl(entity!.id, isReadonlyMode, layouts, roadmapsActions);
        const partialUpdateUIControl = buildUpdateUIControl(entity!.id, isReadonlyMode, layouts, {
            updateLayoutUIControl: roadmapsActions.partialUpdateLayoutUIControl,
            updateUIControl: roadmapsActions.partialUpdateUIControl,
            updateUIControlOnClient: roadmapsActions.partialUpdateUIControlOnClient
        });
        return {
            ['RoadmapItemsControl']: {
                settingsBuilder: settings => viewTypeSettingsBuilder(
                    mergeDefault({
                        timelineMap: {
                            styleSettingValues: defaultStyleSettingValues,
                            quantization: !entity?.roadmapItems.length ? undefined : Quantization.quarters
                        }
                    }, settings),
                    ViewTypeViews.RoadmapItemsControl.default),
                headerRender: buildUrlViewTypeHeaderRender(ViewTypeViews.RoadmapItemsControl),
                datacontext: {
                    lanes: this.props.lanes,
                    labels: this.props.labels
                },
                actions: {
                    create: this.createRoadmapItem,
                    update: this.updateRoadmapItem,
                    updateBulk: this.updateRoadmapItems,
                    remove: this.removeRoadmapItems,
                    updateUIControl: updateUIControl,
                    partialUpdateUiControl: partialUpdateUIControl,
                    buildNew: this.buildNewRoadmapItem,
                    refreshEntity: this.refreshEntity,
                    createLane: this.createLane,
                    updateLane: this.updateLane,
                    removeLane: this.removeLane,
                    reorderLanes: this.reorderLanes,
                    createLabel: this.createLabel,
                    updateLabel: this.updateLabel,
                    removeLabel: this.removeLabel,
                    updateRoadmapItemsDependencies: this.updateRoadmapItemsDependencies,
                    renderImport: this.renderImport,
                    dragEntities: this._dragItems,
                    createFromRoadmapItem: this.createFromRoadmapItem,
                    exportToFile: this.exportSubEntitiesToFile,
                    importFromFile: this.importSubEntitiesFromFile
                }
            },
            ['KPIControl']: {
                datacontext: {
                    entityType: EntityType.Roadmap
                },
                actions: {
                    updateUIControl: updateUIControl
                }
            },
            ['FieldsArea']: {
                datacontext: {
                    entityId: this.props.entity!.id,
                    entityType: EntityType.Roadmap
                },
                elementCustomRender: {
                    ...commonRendersBuilder(),
                    ...rendersBuilder()
                },
                customFieldValidator: validators,
                actions: {
                    refreshEntity: this.refreshEntity,
                    updateUIControl: updateUIControl,
                    onEditComplete: this.onEditComplete
                }
            }
        }
    }

    private getLayoutId(props: Props) {
        let layoutId = props.entity && props.user.permissions.layoutIdByRoadmapIdMap?.[props.entity.id];
        if (layoutId === LayoutService.DefaultLayoutId) {
            return undefined;
        }
        if (!layoutId || !props.layouts.byId[layoutId]) {
            layoutId = props.user.permissions.roadmapProfileLayoutId;
        }
        return layoutId;
    }

    private cloneRoadmap = () => {
        this.props.roadmapsActions.cloneRoadmap(this.props.entity!.id);
        this.props.notificationsActions.pushNotification({ message: 'Roadmap is cloned', type: NotificationType.Info });
    }

    private removeRoadmap = () => {
        this.props.roadmapsActions.removeRoadmap([this.props.entity!.id], true);
    }

    private updateRoadmapStage = (stage: RoadmapStage) => {
        this.props.roadmapsActions.updateRoadmapAttributes(this.props.entity!.id, { [nameof<IRoadmapAttrs>("Stage")]: stage });
    }

    private refreshEntity = () => {
        const roadmapId = this.props.entity!.id;
        this.props.roadmapsActions.loadRoadmap(roadmapId);
    }

    private onEditComplete = (fieldName: string, fieldValue: any, extra: Dictionary<any>): void => {
        if (!fieldName) {
            return;
        }

        this.props.roadmapsActions.updateRoadmapAttributes(this.props.entity!.id, { [fieldName]: fieldValue, ...extra });
    }

    private applyLayout = (layout: Layout) => {
        this.props.roadmapsActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: NotificationType.Info });
    }

    private viewLayout = (layout?: Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.roadmapsActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.roadmapsActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.roadmapsActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Section[], update: IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: NotificationType.Info
        });
    }

    private buildNewRoadmapItem = (): IRoadmapItem => {
        const lane = this.props.lanes?.find(_ => _.isDefault) ?? this.props.lanes?.[0];
        const roadmapItemStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.RoadmapItem, this.props.roadmapItemFields)!;
        return {
            attributes: {
                Lane: lane && { id: lane.id, name: lane.name, color: lane.color },
                Status: roadmapItemStatusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA),
            },
            externalData: {}
        } as IRoadmapItem;
    }

    private createRoadmapItem = (roadmapItem: ICreationData) => {
        this.props.roadmapsActions.createRoadmapItem(this.props.entity!.id, roadmapItem);
    }

    private updateRoadmapItems = (roadmapItems: IPatch<IRoadmapItem>[], callback?: (roadmapItems: IRoadmapItem[]) => void) => {
        this.props.roadmapsActions.updateRoadmapItems(this.props.entity!.id, roadmapItems, callback);
    }

    private updateRoadmapItem = (roadmapItemId: string, changes: Dictionary<unknown>) => {
        this.props.roadmapsActions.updateRoadmapItems(this.props.entity!.id, [{ id: roadmapItemId, attributes: changes }]);
    }

    private removeRoadmapItems = (roadmapItemsIds: string[]) => {
        this.props.roadmapsActions.removeRoadmapItems(this.props.entity!.id, roadmapItemsIds);
    }

    private updateRoadmapItemsDependencies = (toCreate: IRoadmapItemDependency[], toDeleteIds: string[]) => {
        this.props.roadmapsActions.updateRoadmapItemsDependencies(this.props.entity!.id, toCreate, toDeleteIds);
    }

    private createLane = (lane: IBaseGroupInfo) => {
        this.props.lanesActions.create(this.props.entity!.id, lane);

        analytics.trackCreate(this.props.user, {
            itemTitle: lane.name,
            itemType: "Lane",
            parentType: EntityType.Roadmap
        })
    }

    private updateLane = (lane: Group) => {
        this.props.lanesActions.update(this.props.entity!.id, lane);
    }

    private removeLane = (laneId: string) => {
        this.props.lanesActions.remove(this.props.entity!.id, laneId);
    }

    private reorderLanes = (laneIds: string[]) => {
        this.props.lanesActions.reorder(this.props.entity!.id, laneIds);
    }

    private createLabel = (label: IBaseGroupInfo) => {
        this.props.labelsActions.create(this.props.entity!.id, label);

        analytics.trackCreate(this.props.user, {
            itemTitle: label.name,
            itemType: "Label",
            parentType: EntityType.Roadmap
        })
    }

    private updateLabel = (label: Group) => {
        this.props.labelsActions.update(this.props.entity!.id, label);
    }

    private removeLabel = (labelId: string) => {
        this.props.labelsActions.remove(this.props.entity!.id, labelId);
    }

    private renderImport = (props: { onDismiss: () => void, selectedEntityType?: ServerEntityType, viewType: string }): JSX.Element | JSX.Element[] => {
        return <RoadmapItemsImportPanel
            onDismiss={props.onDismiss}
            selectedEntityType={props.selectedEntityType}
            onImport={(descriptor, callback) => this._onImportRoadmapItem(descriptor, callback, props.viewType)}
            onUndoImport={this._onUndoImportRoadmapItem}
            isEntityImported={(entityExternalId: string) => !!this.state.roadmapItemsByExternalId[entityExternalId]}
        />;
    }

    private _onImportRoadmapItem = (descriptor: EntityDescriptor, callback: () => void, viewType: string) => {
        const lane = (descriptor.entityType === ServerEntityType.KeyDate ? this.props.lanes?.find(_ => _.id === MilestonesLaneId) : undefined)
            ?? this.props.lanes?.find(_ => _.isDefault)
            ?? this.props.lanes?.[0];
        if (lane) {
            analytics.trackEvent('Import Roadmap Item', this.props.user, {
                entityType: mapServerEntityType[descriptor.entityType],
                parentEntityType: descriptor.parentEntityType && mapServerEntityType[descriptor.parentEntityType],
            });

            this.props.roadmapsActions.importRoadmapItems(this.props.entity!.id, lane.id, !(viewType === 'Details'), [descriptor], callback);
        }
    }

    private _onUndoImportRoadmapItem = (type: ServerEntityType, id: string, callback: () => void) => {
        const roadmapItem = this.state.roadmapItemsByExternalId[id];
        if (roadmapItem && roadmapItem.externalData["ImportedFromType"] === type) {
            this.props.roadmapsActions.removeRoadmapItems(this.props.entity!.id, [roadmapItem.id], callback);
        }
    }

    private _buildRoadmapItemsByExternalId(roadmapItems?: IRoadmapItem[]): Dictionary<IRoadmapItem> {
        if (!roadmapItems) {
            return {};
        }

        return roadmapItems.reduce((acc, cur) => {
            if (cur.externalId) {
                acc[cur.externalId] = cur;
            }
            return acc;
        }, {});
    }

    private _dragItems = (ids: string[], planState?: string, insertBeforeId?: string) => {
        planState && this.props.roadmapsActions.changePlanState(this.props.entity!.id, ids, isPlanned(planState), insertBeforeId);
    }

    private createFromRoadmapItem = (descriptor: EntityLinkDescriptor, callback: (roadmapItem: IRoadmapItem) => void) => {
        this.props.roadmapsActions.createFromRoadmapItem(this.props.entity!.id, descriptor, callback);
    }

    private exportSubEntitiesToFile = (subentityType: EntityType, pluralSubentityTypeLabel: string, fields: string[], ids: string[]) => {
        const exportColumns = [...fields];
        if (!exportColumns.find(_ => _ === nameof<IRoadmapItemAttrs>("StartDate"))) {
            exportColumns.push(nameof<IRoadmapItemAttrs>("StartDate"));
        }
        if (!exportColumns.find(_ => _ === nameof<IRoadmapItemAttrs>("FinishDate"))) {
            exportColumns.push(nameof<IRoadmapItemAttrs>("FinishDate"));
        }
        if (!exportColumns.find(_ => _ === nameof<IRoadmapItemAttrs>("Lane"))) {
            exportColumns.push(nameof<IRoadmapItemAttrs>("Lane"));
        }
        if (!exportColumns.find(_ => _ === nameof<IRoadmapItemAttrs>("Label"))) {
            exportColumns.push(nameof<IRoadmapItemAttrs>("Label"));
        }

        this.props.roadmapsActions.exportSubEntitiesToFile(this.props.entity!.id, subentityType, pluralSubentityTypeLabel, exportColumns, ids);
    }

    private importSubEntitiesFromFile = (subentityType: EntityType, subentityCollectionName: string, file: File) => {
        this.props.roadmapsActions.importSubEntitiesFromFile(this.props.entity!.id, subentityType, subentityCollectionName, file);
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        roadmapsActions: bindActionCreators(RoadmapsActionCreators, dispatch),
        notificationsActions: bindActionCreators(NotificationsActionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        lanesActions: bindActionCreators(lanesCreators, dispatch),
        labelsActions: bindActionCreators(labelsCreators, dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch),
        roadmapItemsViewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.RoadmapItem), dispatch),
    };
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    const roadmapFields = state.fields[EntityType.Roadmap];
    const roadmapItemFields = state.fields[EntityType.RoadmapItem];
    return {
        tenant: state.tenant,
        user: state.user,
        entity: state.roadmaps.activeEntity && state.roadmaps.activeEntity.id === ownProps.match.params.id ? state.roadmaps.activeEntity : undefined,
        isLoading: state.roadmaps.isLoading,
        roadmapFields: roadmapFields.allIds.map(_ => roadmapFields.byId[_]),
        roadmapItemFields: roadmapItemFields.allIds.map(_ => roadmapItemFields.byId[_]),
        layouts: state.layouts[entityName],
        lanes: state.roadmaps.lanes.byId[ownProps.match.params.id]?.items,
        labels: state.roadmaps.labels.byId[ownProps.match.params.id]?.items,
        isUpdatingSections: state.roadmaps.isUpdatingSections,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(mapStateToProps, mergeActionCreators)(RoadmapDetails);
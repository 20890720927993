import { push, RouterAction } from "react-router-redux";
import * as analytics from '../analytics';
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { Dictionary, EntityType, IPatch, ServerEntityType } from "../entities/common";
import { get, post, remove } from "../fetch-interceptor";
import { ActionsBuilder, MetadataService, UpdateUIControlInfo } from "./services/metadataService";
import { addOrUpdateOrRemove, IDeletionResult, IEntityStore, partialUpdate, StoreHelper } from "./services/storeHelper";
import { defaultCatch } from "./utils";
import { Roadmap } from "./roadmap/common";
import { IRoadmapItem, sortByRank, IRoadmapItemDependency } from "../entities/Subentities";
import { Group, IGroupInfo, IUpdateSectionInfo, Section } from "../entities/Metadata";
import { GroupsOperationsFactory, GroupsState, KnownAction as GroupsKnownAction, ReceivedGroupsAction } from "./groups";
import { toDictionaryById } from "../components/utils/common";
import { ApplyLayout, LayoutApplied } from "./layouts";
import { ICreationData } from "./Subentity";
import { ImportExportFactory } from "./importExport";
import { LayoutService } from "../components/utils/LayoutService";

const namespace = 'ROADMAP';
export const ROADMAPITEM_LANE_NAMESPACE = "roadmapitem/lane";
export const ROADMAPITEM_LABEL_NAMESPACE = "roadmapitem/label";

// copy of serverside Consts.EntityIds.Roadmap.Lane.MilestonesLaneId
export const MilestonesLaneId = "ffd7b6f2-3677-4e3b-bda0-5aa81af28572";

export const PlanStateRankField = "PlannedRank";
export const PlanStateRankStep = 1000;

export const PlanStateField = "IsPlanned";
const PlannedPlanState: string = "Planned";
const BacklogPlanState: string = "Backlog";

const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, Roadmap, RoadmapsState>(namespace, EntityType.Roadmap);

export const DefaultListGroups: Group[] = [
    {
        id: PlannedPlanState,
        name: "Planned",
        color: "#128a10",
        isShown: true,
        isDefault: true
    },
    {
        id: BacklogPlanState,
        name: "Backlog",
        color: "#6a6b6d",
        isShown: true,
        isDefault: true
    }
];

export const isRoadmapItemDependency = (object: any): boolean => 'sourceId' in object && 'targetId' in object;
export const getRoadmapItemPlanState = (roadmapItem: IRoadmapItem): string => roadmapItem.externalData[PlanStateField] ? PlannedPlanState : BacklogPlanState;
export const getRoadmapItemIsPlanned = (roadmapItem: IRoadmapItem): boolean => !!roadmapItem.externalData[PlanStateField];
export const setRoadmapItemPlanState = (roadmapItem: IRoadmapItem, planState: string | boolean) => {
    roadmapItem.externalData[PlanStateField] = (typeof planState === 'string') ? planState === PlannedPlanState : planState;
}
export const isPlanned = (planState: string) => planState === PlannedPlanState;

const lanesStore = GroupsOperationsFactory(ROADMAPITEM_LANE_NAMESPACE, EntityType.Roadmap);
const labelsStore = GroupsOperationsFactory(ROADMAPITEM_LABEL_NAMESPACE, EntityType.Roadmap);

export interface RoadmapsState extends IEntityStore<Roadmap> {
    isLoading: boolean;
    isListLoading: boolean;
    deletionResult?: IDeletionResult[];

    lanes: GroupsState;
    labels: GroupsState;

    isUpdatingSections: boolean;
}

export type EntityDescriptor = {
    id: string,
    uniqueId?: string,
    entityType: ServerEntityType,
    parentEntityType?: ServerEntityType,
    parentId?: string
    name?: string;
    parentName?: string;
}

export type EntityLinkDescriptor = {
    roadmapItemId: string;
    entityType: ServerEntityType;
    entityId?: string;
    parentEntityType?: ServerEntityType;
    parentEntityId?: string;
    parentEntityName?: string;
    attributes?: Dictionary<any>;
}

interface RequestRoadmapsAction {
    type: 'REQUEST_ROADMAPS';
}

interface ReceiveRoadmapsAction {
    type: 'RECEIVED_ROADMAPS';
    roadmaps: Roadmap[];
}

interface LoadRoadmapAction {
    type: 'LOAD_ROADMAP';
    id: string;
}

export interface LoadedRoadmapAction {
    type: 'LOADED_ROADMAP';
    roadmap: Roadmap;
}

interface CreateRoadmapAction {
    type: 'CREATE_ROADMAP';
}

interface CreatedRoadmapAction {
    type: 'CREATED_ROADMAP';
    roadmap: Roadmap;
}

interface ReceivedDeleteRoadmapsResultAction {
    type: 'RECEIVED_REMOVE_ROADMAPS_RESULT';
    deletionResult?: IDeletionResult[];
}

interface BaseSubentitiesUpdateAction<T> {
    roadmapId: string;
    addOrUpdate?: T[] | T;
    remove?: string[];
}

interface UpdateRoadmapRoadmapItems extends BaseSubentitiesUpdateAction<IRoadmapItem> {
    type: "UPDATE_ROADMAP_ROADMAP_ITEMS";
    sortByPlanStateRank?: boolean;
}

interface UpdateRoadmapRoadmapItemsDependencies extends BaseSubentitiesUpdateAction<IRoadmapItemDependency> {
    type: "UPDATE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES";
}

interface RemoveRoadmapRoadmapItemsDependencies {
    type: 'REMOVE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES';
    roadmapId: string;
    removedRoadmapItemsIds: string[];
}

interface UpdatingSectionsAction {
    type: 'UPDATING_ROADMAP_SECTIONS';
    roadmapId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_ROADMAP_SECTION_SUCCESS';
    roadmapId: string;
    sections: Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_ROADMAP_PINNED_VIEWS_SUCCESS';
    roadmapId: string;
    pinnedViews: string[];
}

interface UpdateUIControlAction {
    type: 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

interface BulkUpdateRoadmapsSuccessAction {
    type: 'BULK_UPDATE_ROADMAPS_SUCCESS';
    roadmaps: Roadmap[];
}

export type KnownAction = RequestRoadmapsAction
    | ReceiveRoadmapsAction
    | CreateRoadmapAction
    | CreatedRoadmapAction
    | LoadRoadmapAction
    | LoadedRoadmapAction
    | UpdateRoadmapRoadmapItems
    | ReceivedDeleteRoadmapsResultAction
    | UpdateRoadmapRoadmapItemsDependencies
    | RemoveRoadmapRoadmapItemsDependencies
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | UpdateUIControlAction
    | BulkUpdateRoadmapsSuccessAction;

const unloadedState: RoadmapsState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
    lanes: {
        ...StoreHelper.create([]),
        isLoading: false
    },
    labels: {
        ...StoreHelper.create([]),
        isLoading: false
    },
    isUpdatingSections: false
};

const defaultActionCreators = {
    requestRoadmaps: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Roadmap[]>(`api/roadmap`)
            .then(data => { dispatch({ type: 'RECEIVED_ROADMAPS', roadmaps: data }); })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_ROADMAPS' });
    },
    loadRoadmap: (roadmapId: string, onCatch?: (error: any) => void ): AppThunkAction<KnownAction | ReceivedGroupsAction> => (dispatch, getState) => {
        get<Roadmap & { lanes: Group[]; labels: Group[]; }>(`api/roadmap/${roadmapId}`)
            .then(data => {
                dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                dispatch({ namespace: ROADMAPITEM_LANE_NAMESPACE, type: 'RECEIVED_GROUPS', entityId: roadmapId, groupsItems: data.lanes });
                dispatch({ namespace: ROADMAPITEM_LABEL_NAMESPACE, type: 'RECEIVED_GROUPS', entityId: roadmapId, groupsItems: data.labels });
                dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data.roadmapItems, sortByPlanStateRank: true })
            })
            .catch(onCatch ? onCatch : defaultCatch(dispatch));
        dispatch(({ type: 'LOAD_ROADMAP', id: roadmapId }));
    },
    createRoadmap: (roadmap: { attributes: Dictionary<any> }, openOnComplete?: boolean, showImportEntityType?: ServerEntityType):
        AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
            analytics.trackCreate(getState().user, { itemTitle: roadmap.attributes.Name, itemType: EntityType.Roadmap });

            post<Roadmap>(`api/roadmap`, roadmap)
                .then(data => {
                    dispatch({ type: "CREATED_ROADMAP", roadmap: data })
                    if (openOnComplete) {
                        dispatch(push(`/roadmap/${data.id}`, { showImportEntityType }));
                    }
                })
                .catch(defaultCatch(dispatch));

            dispatch({ type: "CREATE_ROADMAP" });
        },
    cloneRoadmap: (roadmapId: string): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<Roadmap>(`api/roadmap/${roadmapId}/clone`, {})
            .then(data => {
                dispatch({ type: 'CREATED_ROADMAP', roadmap: data });
                dispatch(push(`/roadmap/${data.id}`, {}));
            })
            .catch(defaultCatch(dispatch));
    },
    removeRoadmap: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/roadmap/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_ROADMAPS_RESULT", deletionResult: [data] });
                    if (redirectBack) {
                        dispatch(push('/roadmaps'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/roadmap/bulkDelete`, { ids })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_ROADMAPS_RESULT", deletionResult: data });
                    if (redirectBack) {
                        dispatch(push('/roadmaps'));
                    }
                })
                .catch(defaultCatch(dispatch));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_ROADMAPS_RESULT" });
    },
    updateRoadmapAttributes: (roadmapId: string, newAttributeValues: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Roadmap>(`api/roadmap/${roadmapId}/attributes`, newAttributeValues)
            .then(data => dispatch({ type: 'LOADED_ROADMAP', roadmap: data }))
            .catch(defaultCatch(dispatch));
    },
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/roadmap`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS',
            uiControlInfo: uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS',
        uiControlInfo
    })),
    partialUpdateUIControl: ActionsBuilder.buildEntityPartialUpdateUIControl(`api/roadmap`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS',
            uiControlInfo
        })),
    partialUpdateUIControlOnClient: ActionsBuilder.buildEntityPartialUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Roadmap),
    partialUpdateLayoutUIControl: ActionsBuilder.buildLayoutPartialUpdateUIControl(EntityType.Roadmap),
    removeRoadmapItems: (roadmapId: string, roadmapItemsIds: string[], callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Roadmap>(`api/roadmap/${roadmapId}/roadmapItem`, { ids: roadmapItemsIds })
            .then(data => {
                dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, remove: roadmapItemsIds });
                dispatch({ type: 'REMOVE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES', roadmapId, removedRoadmapItemsIds: roadmapItemsIds });
                callback?.();
            })
            .catch(defaultCatch(dispatch));
    },
    createRoadmapItem: (roadmapId: string, roadmapItem: ICreationData, callback?: (roadmapItems: IRoadmapItem[]) => void):
        AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
            post<Roadmap>(`api/roadmap/${roadmapId}/roadmapItem`, roadmapItem)
                .then(data => {
                    dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                    dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data.roadmapItems, sortByPlanStateRank: true })
                    callback?.(data.roadmapItems);
                })
                .catch(defaultCatch(dispatch));
        },
    updateRoadmapItems: (roadmapId: string, roadmapItems: IPatch<IRoadmapItem>[], callback?: (roadmapItems: IRoadmapItem[]) => void):
        AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
            post<Roadmap>(`api/roadmap/${roadmapId}/roadmapItem/bulk`, roadmapItems)
                .then(data => {
                    dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                    dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data.roadmapItems, sortByPlanStateRank: true })
                    callback?.(data.roadmapItems);
                })
                .catch(defaultCatch(dispatch));
        },
    updateRoadmapItemsDependencies: (roadmapId: string, toCreate: IRoadmapItemDependency[], toDeleteIds: string[]):
        AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
            post<IRoadmapItemDependency[]>(`api/roadmap/${roadmapId}/roadmapItemsDependencies`, { toCreate, toDeleteIds })
                .then(data => dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES', roadmapId, addOrUpdate: data, remove: toDeleteIds }))
                .catch(defaultCatch(dispatch));
        },
    importRoadmapItems: (roadmapId: string, groupId: string, planned: boolean, descriptors: EntityDescriptor[], callback: () => void):
        AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
            post<Roadmap>(`api/roadmap/${roadmapId}/roadmapItem/importToGroup/${groupId}?isplanned=${planned}`, descriptors)
                .then(data => {
                    dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                    dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data.roadmapItems, sortByPlanStateRank: true })
                    callback();
                })
                .catch(defaultCatch(dispatch));
        },
    createFromRoadmapItem: (roadmapId: string, descriptor: EntityLinkDescriptor, callback: (roadmapItem: IRoadmapItem) => void):
        AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
            post<IRoadmapItem>(`api/roadmap/${roadmapId}/roadmapItem/createentity`, descriptor)
                .then(data => {
                    dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data, sortByPlanStateRank: true });
                    callback(data);
                })
                .catch(defaultCatch(dispatch));
        },
    changePlanState: (roadmapId: string, ids: string[], planned: boolean, insertBeforeId?: string): AppThunkAction<KnownAction | RouterAction> => (dispatch) => {
        post<Roadmap>(`api/roadmap/${roadmapId}/roadmapItem/state/${planned ? "planned" : "backlog"}`, { ids, insertBeforeId })
            .then(data => {
                dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                dispatch({ type: 'UPDATE_ROADMAP_ROADMAP_ITEMS', roadmapId, addOrUpdate: data.roadmapItems, sortByPlanStateRank: true })
            })
            .catch(defaultCatch(dispatch));
    },
    applyLayout: (roadmapId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Roadmap>(`api/roadmap/${roadmapId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'LOADED_ROADMAP', roadmap: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Roadmap });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Program });
    },
    applyLayoutMany: (roadmapIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<Roadmap[]>(`api/roadmap/applyLayout/${layoutId}`, { ids: roadmapIds })
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_ROADMAPS_SUCCESS', roadmaps: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Program });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Program });
    },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/roadmap`,
        (roadmapId, sections, dispatch) => dispatch({
            type: 'UPDATE_ROADMAP_SECTION_SUCCESS',
            roadmapId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((roadmapId, sections, dispatch) => dispatch({
        type: 'UPDATE_ROADMAP_SECTION_SUCCESS',
        roadmapId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/roadmap`,
        (roadmapId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_ROADMAP_PINNED_VIEWS_SUCCESS',
            roadmapId,
            pinnedViews
        }))
}

function updateRoadmapItemsDependencies(dependencies: IRoadmapItemDependency[] | undefined, items: IRoadmapItem[]): IRoadmapItemDependency[] | undefined {
    const itemsMap = toDictionaryById(items)
    return dependencies?.filter(_ => !!itemsMap[_.sourceId] && !!itemsMap[_.targetId]);
}

function updateRoadmapItemsLanes(items: IRoadmapItem[] | undefined, lanes: Group[] | null): IRoadmapItem[] | undefined {
    const lanesMap: Dictionary<IGroupInfo> = {};
    lanes?.forEach(_ => lanesMap[_.id] = { id: _.id, name: _.name, color: _.color });
    return items
        ?.filter(_ => !!lanesMap[_.attributes.Lane.id])
        .map<IRoadmapItem>(_ => ({ ..._, attributes: { ..._.attributes, Lane: lanesMap[_.attributes.Lane.id] } }));
}

function updateRoadmapItemsLabels(items: IRoadmapItem[] | undefined, labels: Group[] | null): IRoadmapItem[] | undefined {
    const labelsMap: Dictionary<IGroupInfo> = {};
    labels?.forEach(_ => labelsMap[_.id] = { id: _.id, name: _.name, color: _.color });
    return items
        ?.filter(_ => !_.attributes.Label || !!labelsMap[_.attributes.Label.id])
        .map<IRoadmapItem>(_ => ({ ..._, attributes: { ..._.attributes, Label: _.attributes.Label ? labelsMap[_.attributes.Label.id] : undefined } }));
}

const defaultReducer: Reducer<RoadmapsState> = (state: RoadmapsState, incomingAction: Action) => {
    const maybeGroupAction = incomingAction as GroupsKnownAction;

    if (maybeGroupAction.type === "RECEIVED_GROUPS"
        && (maybeGroupAction.namespace === ROADMAPITEM_LANE_NAMESPACE || maybeGroupAction.namespace === ROADMAPITEM_LABEL_NAMESPACE)
        && maybeGroupAction.entityId === state.activeEntity?.id) {
        return {
            ...state,
            ...StoreHelper.applyHandler(state, maybeGroupAction.entityId,
                (entity: Roadmap) => partialUpdate(entity, {
                    roadmapItemsDependencies: maybeGroupAction.items
                        ? updateRoadmapItemsDependencies(entity.roadmapItemsDependencies, maybeGroupAction.items)
                        : entity.roadmapItemsDependencies,
                    roadmapItems: maybeGroupAction.items
                        ? maybeGroupAction.items
                        : maybeGroupAction.namespace === ROADMAPITEM_LANE_NAMESPACE
                            ? updateRoadmapItemsLanes(entity.roadmapItems, maybeGroupAction.groupsItems)
                            : updateRoadmapItemsLabels(entity.roadmapItems, maybeGroupAction.groupsItems)
                } as Partial<Roadmap>))
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ROADMAPS':
            return {
                ...state,
                isLoading: true,
                isListLoading: true
            };
        case 'RECEIVED_ROADMAPS':
            return {
                ...state,
                ...StoreHelper.create(action.roadmaps),
                isLoading: false,
                isListLoading: false
            };
        case 'CREATE_ROADMAP':
            return {
                ...state,
                isLoading: true
            };
        case 'CREATED_ROADMAP':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.roadmap),
                isLoading: false
            };
        case 'LOAD_ROADMAP':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'LOADED_ROADMAP':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.roadmap),
                activeEntity: state.activeEntityId === action.roadmap.id ? action.roadmap : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_REMOVE_ROADMAPS_RESULT':
            let newState = { ...state };
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted && newState.byId[result.id]) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'UPDATE_ROADMAP_ROADMAP_ITEMS':
            return StoreHelper.applyHandler(state, action.roadmapId, (roadmap: Roadmap) => {
                const roadmapItems = addOrUpdateOrRemove(roadmap.roadmapItems, action.addOrUpdate, action.remove);

                if (action.sortByPlanStateRank) {
                    roadmapItems.sort((a: IRoadmapItem, b: IRoadmapItem) => sortByRank(a, b, PlanStateRankField));
                }

                return partialUpdate(roadmap, { roadmapItems });
            });
        case 'UPDATE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES':
            return StoreHelper.applyHandler(state, action.roadmapId, (roadmap: Roadmap) => partialUpdate(roadmap, {
                roadmapItemsDependencies: addOrUpdateOrRemove(roadmap.roadmapItemsDependencies, action.addOrUpdate, action.remove)
            }));
        case 'REMOVE_ROADMAP_ROADMAP_ITEMS_DEPENDENCIES':
            return StoreHelper.applyHandler(state, action.roadmapId, (roadmap: Roadmap) => partialUpdate(roadmap, {
                roadmapItemsDependencies: roadmap.roadmapItemsDependencies
                    .filter(_ => action.removedRoadmapItemsIds.indexOf(_.sourceId) === -1 && action.removedRoadmapItemsIds.indexOf(_.targetId) === -1)
            }));
        case 'UPDATING_ROADMAP_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_ROADMAP_SECTION_SUCCESS':
            return {
                ...StoreHelper.applyHandler(state,
                    action.roadmapId,
                    (roadmap: Roadmap) => Object.assign({}, roadmap, { sections: action.sections })),
                isUpdatingSections: false
            };
        case 'UPDATE_ROADMAP_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.roadmapId,
                        (roadmap: Roadmap) => Object.assign({}, roadmap, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_UICONTROL_IN_ROADMAP_SUCCESS':
            return StoreHelper.applyHandler(state, action.uiControlInfo.entityId, (roadmap: Roadmap) =>
                MetadataService.UpdateUIControlSettings(roadmap, action.uiControlInfo));
        case 'BULK_UPDATE_ROADMAPS_SUCCESS':
            return {
                ...state,
                ...StoreHelper.union(state, action.roadmaps),
                isLoading: false
            };
        default:
            return state;
    }
}

export const reducer: Reducer<RoadmapsState> = (state: RoadmapsState = unloadedState, incomingAction: Action) => {
    return defaultReducer(
        importExportReducer(
            {
                ...state,
                lanes: lanesStore.reducer(state.lanes, incomingAction),
                labels: labelsStore.reducer(state.labels, incomingAction),
            }, incomingAction),
        incomingAction);
}

export const lanesCreators = lanesStore.actionCreators;
export const labelsCreators = labelsStore.actionCreators;

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators
};